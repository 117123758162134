import { useEffect, useState } from "react";
import FavoritesController from "../../../assets/Controllers/FavoritesController";
import { useTranslation } from "react-i18next";
import iFavoriteList from "../../../assets/models/iFavoriteList";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import Skeleton from "react-loading-skeleton";

interface Props {
  userId: string;
  onItemClick: Function;
}

export default function FavoritesLists({ userId, onItemClick }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [favorites, setFavorites] = useState<iFavoriteList[]>([]);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  // const [editMode, setEditMode] = useState(false);

  const loadCustomerFavorites = async () => {
    try {
      setIsLoading(true);

      const data = await FavoritesController.getLists(userId);
      //console.log("FavoritesModal - favorites list", data.data);
      setFavorites(data.data);

      // if no favorites list enter in edit mode (to add new list)
      // if (data.data.length === 0) {
      //   setEditMode(true);
      // }

      setIsLoading(false);
    } catch (error) {
      console.log("FavoritesModal - loadCustomerFavorites error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCustomerFavorites();
  }, []);

  return (
    <div
      className="favorites-list d-flex gap-2 flex-wrap"
      // style={{
      //   minHeight: !isSmallScreen ? "584px" : undefined,
      //   minWidth: !isSmallScreen ? "547px" : undefined,
      // }}
    >
      {!isLoading ? (
        favorites.length > 0 ? (
          favorites.map((favList: iFavoriteList, key: number) => (
            <div
              className="cursor-pointer"
              role="button"
              key={favList._id}
              onClick={() => onItemClick(favList._id)}
            >
              <div
                style={{
                  width: isSmallScreen ? "181px" : "200px",
                  height: isSmallScreen ? "181px" : "200px",
                  backgroundImage: `url(${favList.image})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  boxSizing: "border-box",
                  borderRadius: "20px",
                }}
              >
                {/* L'immagine come sfondo qui */}
              </div>
              <div
                style={{
                  padding: "10px",
                }}
              >
                {/* Testo sotto l'immagine */}
                <p className="bodytext medium">{favList.name}</p>
                <p className="bodytext-sm light">
                  {t("favorites.list_size")}: {favList.likes.length}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div>{t("favorites.no_favorites")}</div>
        )
      ) : (
        <>
          <Skeleton width={200} height={200}></Skeleton>
          <Skeleton width={200} height={200}></Skeleton>
        </>
      )}
    </div>
  );
}
