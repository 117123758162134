import { useTranslation } from "react-i18next";
import { BID_STATUS, iBid } from "../../../models/iBid";
import Countdown from "../Countdown/Countdown";
import { useEffect, useState } from "react";
import { iBuyOrder } from "../../../models/iOrder";
import { useSelector } from "react-redux";
import { selectPendingOrders } from "../../../../redux/slices/orderSlice";

function getOfferStatus(offer: iBid): string | null {
  // buyer
  if (offer._side === "buyer") {
    if (offer.status === BID_STATUS.PENDING) {
      return "wallet.offers.buyerpending";
    }
    if (offer.status === BID_STATUS.ACCEPTED) {
      return "wallet.offers.buyeraccepted";
    }
    if (offer.status === BID_STATUS.DECLINED) {
      return "wallet.offers.buyerdeclined";
    }
    if (offer.status === BID_STATUS.DELETED) {
      return "wallet.offers.buyerdeleted";
    }
    // if (offer.status === BID_STATUS.BUYER_COUNTEROFFER) {
    //   return "wallet.offers.buyercounteroffer"; // TOFIX
    // }
    // if (offer.status === BID_STATUS.SELLER_COUNTEROFFER) {
    //   return "wallet.offers.buyercounteroffer"; // TOFIX
    // }
  }

  // seller
  if (offer._side === "seller") {
    if (offer.status === BID_STATUS.PENDING) {
      return "wallet.offers.sellerpending";
    }
    if (offer.status === BID_STATUS.ACCEPTED) {
      return "wallet.offers.selleraccepted";
    }
    if (offer.status === BID_STATUS.DECLINED) {
      return "wallet.offers.sellerdeclined";
    }
    if (offer.status === BID_STATUS.DELETED) {
      return "wallet.offers.sellerdeleted";
    }
    // if (offer.status === BID_STATUS.BUYER_COUNTEROFFER) {
    //   return "wallet.offers.sellercounteroffer"; // TOFIX
    // }
    // if (offer.status === BID_STATUS.SELLER_COUNTEROFFER) {
    //   return "wallet.offers.sellercounteroffer"; // TOFIX
    // }
  }

  return null;
}

function getOfferStatusText(offer: iBid): string | null {
  // buyer
  if (offer._side === "buyer") {
  }

  // seller
  if (offer._side === "seller") {
    if (offer.status === BID_STATUS.ACCEPTED) {
      return "wallet.offers.selleracceptedtext";
    }
  }

  return null;
}

function TakBidStatus({ offer }: { offer: iBid }) {
  const { t } = useTranslation();

  const [offerStatus, setofferStatus] = useState("");
  const [offerStatusText, setofferStatusText] = useState("");

  const [order, setOrder] = useState<iBuyOrder>();
  const orders = useSelector(selectPendingOrders);

  useEffect(() => {
    const order = orders.find((o) => o.nftId === offer.nftId);
    if (order) setOrder(order);
  }, [orders]);

  useEffect(() => {
    setofferStatus(getOfferStatus(offer) ?? "");
    setofferStatusText(getOfferStatusText(offer) ?? "");
  }, [offer, orders]);

  return (
    <>
      <p className="bodytext">
        {offerStatus ? t(offerStatus) : ""}{" "}
        {order?.expiresAt &&
        offer._side === "buyer" &&
        offer.status === BID_STATUS.ACCEPTED ? (
          <Countdown startTime={new Date(String(order?.expiresAt))} />
        ) : null}
      </p>
      {offerStatusText && (
        <p className="bodytext-sm light">{t(offerStatusText)}</p>
      )}
    </>
  );
}

export default TakBidStatus;
