import { Col } from "react-bootstrap";

interface Props {
  title: string;
  text_1: string;
  text_2?: string;
  dark_bg?: boolean;
}

export default function InfoText(props: Props) {
  return (
    <div className="d-flex flex-column flex-md-row flex-nowrap gap-3 px-3">
      <Col xs={12} md={4}>
        <div className={`${props.dark_bg ? "white-color" : ""}`}>
          <span className="h3 medium">{props.title}</span>
        </div>
      </Col>

      <div className="d-flex flex-column">
        <span className="dark-grey-color">{props.text_1}</span>
        {props.text_2 ? (
          <div>
            <div className="height-16"></div>
            <span className="dark-grey-color">{props.text_2}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
