import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { useTranslation } from "react-i18next";

export const COOKIES_ACCEPTED_KEY =
  "WV5B7436BV34RCQ" + process.env.REACT_APP_FRONTEND_URI;
export const COOKIES_REFUSED_KEY =
  "CV2365B4768N574" + process.env.REACT_APP_FRONTEND_URI;

const isProd = process.env.REACT_APP_ROBOTS_POLICY === "all";

// const onlyEssentialCookies = () => {
//   if (isProd) fireTagManagerEvent("cookies", { cookies: "denied" });
// };
const allCookies = () => {
  if (isProd) fireTagManagerEvent("cookies", { cookies: "granted" });
};

export const initCookies = () => {
  const isAccepted = window.localStorage.getItem(COOKIES_ACCEPTED_KEY);
  // const isRefused = window.localStorage.getItem(COOKIES_REFUSED_KEY);

  if (isAccepted) allCookies();
  // else onlyEssentialCookies();
};

export default function CookiesBanner() {
  const {t} = useTranslation();
  const [cookiesAccepted, setCookiesAccepted] = useState(true);

  useEffect(() => {
    const isAccepted = window.localStorage.getItem(COOKIES_ACCEPTED_KEY);
    const isRefused = window.localStorage.getItem(COOKIES_REFUSED_KEY);

    setCookiesAccepted(isAccepted || isRefused ? true : false);
  }, []);

  if (cookiesAccepted) return <></>;

  return (
    <div style={{ zIndex: 1000 }} className="position-fixed bottom-0 w100 p-3">
      <Container>
        <div className="d-flex flex-column flex-md-row gap-4 p-4 white-background rounded shadow">
          <div
            onClick={() => {
              setCookiesAccepted(true);
              // onlyEssentialCookies();
            }}
            className="cursor-pointer primary-background d-flex justify-content-center align-items-center width-25 height-25 rounded-circle"
          >
            x
          </div>

          <div className="text">
            <p>{t("cookie.text")}</p>
            <u>
              <Link to={getLink(LINK_TYPE.COOCKIES)}>
                {t("cookie.policy")}
              </Link>
            </u>
          </div>

          <div className="d-flex flex-column gap-3">
            <Button
              text={t("cookie.accept")}
              className="w100"
              size="sm"
              onClick={() => {
                window.localStorage.setItem(COOKIES_ACCEPTED_KEY, "true");
                setCookiesAccepted(true);
                allCookies();
              }}
            />
            <Button
              text={t("cookie.refuse")}
              className="w100"
              size="sm"
              variant="secondary"
              onClick={() => {
                window.localStorage.setItem(COOKIES_REFUSED_KEY, "true");
                setCookiesAccepted(true);
                // onlyEssentialCookies();
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
