import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  contentMaxWidthNumber,
  SMALL_SCREEN,
} from "../../../assets/Utils/generic";
import landingImg from "../../../assets/img/forclients/landing.png";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import BecomePartner from "../../components/Body/BecomePartner/BecomePartner";

import Animate from "../../components/Animate/Animate";
import InfoText from "../../components/Body/InfoText/InfoText";

import logo1 from "../../../assets/img/forhotels/logo1.svg";
import logo2 from "../../../assets/img/forhotels/logo2.svg";
import logo3 from "../../../assets/img/forhotels/logo3.svg";
import logo4 from "../../../assets/img/forhotels/logo4.svg";
import logo5 from "../../../assets/img/forhotels/logo5.svg";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../config/Links";

import hotelImg from "../../../assets/img/forpartners/Hotel.png";
import experienceImg from "../../../assets/img/forpartners/Unknown.png";
import otherImg from "../../../assets/img/forpartners/Other.png";
import { scrollTo } from "../../../assets/Utils/generic";

export default function ForPartners() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const navigate = useNavigate();

  const information1 = [
    {
      title: t("forpartners.section_3.2"),
      text_1: t("forpartners.section_3.3"),
    },
    {
      title: t("forpartners.section_3.4"),
      text_1: t("forpartners.section_3.5"),
    },
    {
      title: t("forpartners.section_3.6"),
      text_1: t("forpartners.section_3.7"),
    },
    {
      title: t("forpartners.section_3.8"),
      text_1: t("forpartners.section_3.9"),
    },
    {
      title: t("forpartners.section_3.10"),
      text_1: t("forpartners.section_3.11"),
    },
  ];

  const reviews = [
    {
      name: "Stefano Lombardi",
      position: "Director of Dev & Digital Ecosystem",
      text: t("forpartners.section_5.2"),
      hotel: "BWH Hotels Italy",
      image: logo1,
    },
    {
      name: "Anna de Simone",
      position: "Business Development",
      text: t("forpartners.section_5.3"),
      hotel: "Italian Hotel Group",
      image: logo2,
    },
    {
      name: "Diego Novarino",
      position: "General Manager",
      text: t("forpartners.section_5.4"),
      hotel: "Hotel VIU Milan",
      image: logo3,
    },
    {
      name: "Michele Costantini",
      position: "Hotel Manager",
      text: t("forpartners.section_5.5"),
      hotel: "Palazzo Vitturi",
      image: logo4,
    },
    {
      name: "Massimo Semerano",
      position: "Revenue Manager",
      text: t("forpartners.section_5.6"),
      hotel: "Garibaldi Hotels",
      image: logo5,
    },
  ];

  return (
    <main className="dark-background">
      <div
        style={{
          height: isSmallScreen ? "" : "100dvh",
          background: `url(${landingImg})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: isSmallScreen ? "150px 0" : "",
        }}
      >
        <div className="container h100">
          <div className="content h100">
            <div
              style={{ maxWidth: "800px" }}
              className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-5 gap-md-1 h100"
            >
              <div>
                <p className="h1-large regular white-color">
                  {t("forpartners.section_1.title")}
                </p>
                <div className="height-24"></div>
                <p className="h4 light white-color">
                  {t("forpartners.section_1.subtitle")}
                </p>
                <div className="height-40"></div>
                <Button
                  text={t("forpartners.section_1.button")}
                  iconFill
                  onClick={() => {
                    scrollTo("becomePartnerForm");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="height-90"></div>

      <div className="container">
        <div className="content">
          <div className="w100">
            <div className="h1-large white-color regular">
              {t("forpartners.section_3.1")}{" "}
            </div>
          </div>

          <div className="height-60"></div>

          <div className="d-flex justify-content-center w100">
            <div>
              {information1.map((info, index) => (
                <Animate
                  key={index}
                  element={
                    <>
                      <InfoText
                        dark_bg
                        title={info.title}
                        text_1={info.text_1}
                      />
                      {index !== information1.length - 1 ? (
                        <hr
                          style={{ margin: "60px 0" }}
                          className="dark-grey-color"
                        />
                      ) : null}
                    </>
                  }
                ></Animate>
              ))}
            </div>
          </div>

          <div className="height-90"></div>

          <div className="d-flex justify-content-center">
            <Button
              text={t("forpartners.section_1.button")}
              onClick={() => {
                scrollTo("becomePartnerForm");
              }}
            />
          </div>

          <div className="height-90"></div>
        </div>
      </div>

      <div className="container">
        <div className="content">
          <div className="h1-large white-color regular">
            {t("forpartners.section_7.1")}
          </div>

          <div className="height-60"></div>

          <div className="w100 d-flex flex-column flex-lg-row gap-4">
            <div
              style={{
                aspectRatio: "1",
                background: `url(${hotelImg})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="border d-flex flex-column primary-background w100 position-relative p-4 gap-3 rounded"
            >
              <div>
                <Button
                  size="sm"
                  iconFill
                  icon="bed"
                  variant="light"
                  text={t("forpartners.hotel_tag")}
                />
              </div>
              <div className="h100"></div>
              <p className="h3 white-color medium">
                {t("forpartners.hotel_title")}
              </p>
              <p className="h4 white-color regular">
                {t("forpartners.hotel_text")}
              </p>

              <div>
                <Button
                  size="sm"
                  text={t("forpartners.hotel_button")}
                  icon="arrow_outward"
                  iconFill
                  onClick={() => {
                    navigate(getLink(LINK_TYPE.FORHOTELS));
                  }}
                />
              </div>
            </div>

            <div
              style={{
                aspectRatio: "1",
                background: `url(${experienceImg})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="border d-flex flex-column primary-background w100 position-relative p-4 gap-3 rounded"
            >
              <div>
                <Button
                  size="sm"
                  iconFill
                  icon="bed"
                  variant="light"
                  text={t("forpartners.experience_tag")}
                />
              </div>
              <div className="h100"></div>
              <p className="h3 white-color medium">
                {t("forpartners.experience_title")}
              </p>
              <p className="h4 white-color regular">
                {t("forpartners.experience_text")}
              </p>

              <div>
                <Button
                  size="sm"
                  text={t("forpartners.experience_button")}
                  icon="arrow_outward"
                  iconFill
                  onClick={() => {
                    navigate(getLink(LINK_TYPE.FOREXPERIENCE));
                  }}
                />
              </div>
            </div>

            <div
              style={{
                aspectRatio: "1",
                background: `url(${otherImg})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="border d-flex flex-column primary-background w100 position-relative p-4 gap-3 rounded"
            >
              <div>
                <Button
                  size="sm"
                  iconFill
                  icon="bed"
                  variant="light"
                  text={t("forpartners.generic_tag")}
                />
              </div>
              <div className="h100"></div>
              <p className="h3 white-color medium">
                {t("forpartners.generic_title")}
              </p>
              <p className="h4 white-color regular">
                {t("forpartners.generic_text")}
              </p>

              <div>
                <Button
                  size="sm"
                  text={t("forpartners.generic_button")}
                  icon="arrow_outward"
                  iconFill
                  onClick={() => {
                    navigate(getLink(LINK_TYPE.FORGENERIC));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="black-background">
        <div className="height-100"></div>
        <div className="container">
          <div className="content">
            <div className="h1-large white-color regular">
              {t("forpartners.section_5.1")}
            </div>
          </div>

          <div className="height-50"></div>
        </div>
        <div
          id="partner-reviews-scroll"
          style={{
            maxWidth: "100vw",
            overflowX: "scroll",
            overflowY: "hidden",
          }}
          className=" d-flex align-items-center"
        >
          <div className="width-25 only-mobile"></div>

          <div
            style={{
              minWidth: `${(window.innerWidth - contentMaxWidthNumber) / 2}px`,
            }}
          ></div>

          {reviews.map((review) => {
            return (
              <div className="light-grey-background rounded p-4 width-300 height-400 m-2">
                <img src={review.image} style={{ height: "40px" }} alt="" />
                <div className="height-25"></div>

                <p className="h4 medium">{review.name}</p>
                <p className="bodytext-sm medium dark-grey-color">
                  {review.position}
                </p>
                <div className="height-50"></div>
                <p className="bodytext regular">{review.text}</p>
                <div className="height-50"></div>
              </div>
            );
          })}

          <div
            style={{
              minWidth: `${(window.innerWidth - contentMaxWidthNumber) / 2}px`,
            }}
          ></div>

          <div className="width-25 only-mobile"></div>
        </div>

        <div className="height-25"></div>

        <div className="d-flex justify-content-center gap-4">
          <Button
            variant="light"
            icon="chevron_left"
            circle
            style={{ height: "40px", width: "40px" }}
            onClick={() => {
              const el = document.getElementById("partner-reviews-scroll");
              el?.scrollBy({ left: -300, behavior: "smooth" });
            }}
          />
          <Button
            variant="light"
            icon="chevron_right"
            circle
            style={{ height: "40px", width: "40px" }}
            onClick={() => {
              const el = document.getElementById("partner-reviews-scroll");
              el?.scrollBy({ left: 300, behavior: "smooth" });
            }}
          />
        </div>
        <div id="becomePartnerForm" className="mt-5 white-background">
          <BecomePartner />
        </div>
        <div className="height-100"></div>
      </section>
    </main>
  );
}
