import nothingHereImg from "../../../images/nothing-here.svg";
import Button from "../../Button/Button";

interface Props {
  title: string;
  subtitle: string;
  actionText?: string;
  actionText2?: string;
  action?(): any;
  action2?(): any;
  light?: boolean;
}

export default function NothingHere({
  title,
  subtitle,
  action,
  action2,
  actionText,
  actionText2,
  light,
}: Props) {
  return (
    <section className="d-flex flex-column align-items-center justify-content-center w100 h100 p-3">
      <img
        src={nothingHereImg}
        alt=""
        className="w100"
        style={{ maxWidth: "200px" }}
      />

      <div className="height-32"></div>
      <p className={`m-0 h4 medium text-center ${light ? "white-color" : ""}`}>
        {title}
      </p>
      <div className="height-12"></div>
      <p className="m-0 bodytext-sm regular dark-grey-color text-center">
        {subtitle}
      </p>
      <div className="height-32"></div>
      <div className="d-flex gap-2">
        {action2 ? <Button text={actionText2} onClick={action2} variant="light"/> : null}
        {action && actionText ? (
          <Button text={actionText} onClick={action} />
        ) : null}
      </div>
    </section>
  );
}
