import React, { useEffect, useRef } from "react";
import { useAnimation, motion } from "framer-motion";
import Button from "../Button/Button";

export interface iTabItem {
  name: string;
  value: string;
}

const transition = {
  type: "spring",
  duration: 0.5,
};

export default function Tab({
  size,
  items,
  onChange,
}: {
  size?: string;
  items: iTabItem[];
  onChange: (index: string) => void;
}) {
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const tabSelectorRef = useRef<HTMLDivElement>(null);
  const animation = useAnimation();

  const moveSelector = async (element: HTMLDivElement) => {
    const parentElement = element.parentElement;
    if (parentElement && tabSelectorRef.current) {
      const buttonRect = element.getBoundingClientRect();
      const parentRect = parentElement.getBoundingClientRect();
      const left = buttonRect.left - parentRect.left + parentElement.scrollLeft;
      const width = buttonRect.width;
      const height = buttonRect.height;
      await animation.start({
        left,
        width,
        height,
        transition,
      });
    }
  };

  const handleClick = async (value: string, element: HTMLDivElement) => {
    onChange(value);
    await moveSelector(element);
  };

  useEffect(() => {
    if (tabContainerRef.current) {
      const firstButton = tabContainerRef.current.querySelector(".tab-item");
      if (firstButton) {
        moveSelector(firstButton as HTMLDivElement);
      }
    }
  }, []);

  return (
    <div className="tab-container" ref={tabContainerRef}>
      <motion.div
        ref={tabSelectorRef}
        animate={animation}
        transition={transition}
        className="tab-selector"
        style={{
          width: size ? size : "initial",
        }}
      ></motion.div>
      {items.map((item, index) => (
        <Button
          className="tab-item"
          key={index}
          text={item.name}
          variant="blank"
          onClick={(e) => handleClick(item.value, e.currentTarget as any)}
          style={{
            width: size ? size : "initial",
          }}
        />
      ))}
    </div>
  );
}
