import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../redux";
import { iBuyOrder } from "../../assets/models/iOrder";

interface UserState {
  pendingOrders: iBuyOrder[];
}

const initialState: UserState = {
  pendingOrders: [],
};

export const pendingOrderSlice = createSlice({
  name: "pendingOrders",
  initialState: initialState,
  reducers: {
    setPendingOrders: (state, action: PayloadAction<iBuyOrder[]>) => {
      state.pendingOrders = action.payload;
    },
  },
});

export const { setPendingOrders } = pendingOrderSlice.actions;
export const selectPendingOrders = (state: RootState) =>
  state.pendingOrders.pendingOrders;

export default pendingOrderSlice.reducer;
