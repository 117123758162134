import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import iUser from "../../assets/models/iUser";
import { RootState } from "../redux";
import { set } from "lodash";

interface UserState {
  user: iUser | undefined;
  sellerPendingOffersCount: number;
  favoriteIds: string[];
}

const initialState: UserState = {
  user: undefined,
  sellerPendingOffersCount: 0,
  favoriteIds: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<iUser | undefined>) => {
      state.user = action.payload;
    },
    setSellerPendingOffersCount: (state, action: PayloadAction<number>) => {
      state.sellerPendingOffersCount = action.payload;
    },
    setUserFavoriteIds: (state, action: PayloadAction<string[]>) => {
      state.favoriteIds = [...action.payload];
    },
    addUserFavorite: (state, action: PayloadAction<string>) => {
      const newFavoriteId = action.payload;
      if (!state.favoriteIds.includes(newFavoriteId)) {
        state.favoriteIds.push(newFavoriteId);
      }
    },
    removeUserFavorite: (state, action: PayloadAction<string>) => {
      state.favoriteIds = state.favoriteIds.filter(
        (id) => id !== action.payload
      );
    },
  },
});

export const {
  setUser,
  setSellerPendingOffersCount,
  setUserFavoriteIds,
  addUserFavorite,
  removeUserFavorite,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectSellerPendingOffersCount = (state: RootState) =>
  state.user.sellerPendingOffersCount;
export const isFavorite = (state: RootState, nftId: string) => {
  const isFavorite = state.user.favoriteIds.includes(nftId);
  return isFavorite;
};

export default userSlice.reducer;
