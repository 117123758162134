import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { iNft } from "../../../../../assets/models/iNft";
import { closeModal, openModal } from "../../../../../assets/Utils/modal";
import NftController from "../../../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
// import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import _ProfileSectionLayout from "../../_ProfileSectionLayout";
import helpers from "../../../../helpers/helpers";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../../../assets/Utils/generic";

export default function TakCreditRefund({ nft }: { nft?: iNft }) {
  // { nft }: { nft: iNft }
  let location = useLocation();
  const navigate = useNavigate();

  nft = nft ?? location.state;
  // const [price, setPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const originalPrice = useMemo(() => {
    return nft!.originalPrice!;
  }, [nft]);

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const obtainRefund = async () => {
    setIsLoading(true);
    try {
      // const prevAmount = nft!.currentPrice;

      await NftController.getGaranteed({
        nftId: nft!._id,
      });

      // fireTagManagerEvent("change_price", {
      //   item_id: nft!._id,
      //   amount: Number(price),
      //   originalPrice: nft!.originalPrice,
      //   previousPrice: prevAmount,
      // });

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={t("wallet.credits.obtainsuccess")}
              continueClickCallback={() => navigate("/profile/credits")}
              continueBtnText={t("wallet.credits.viewcredits")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <_ProfileSectionLayout
        nft={nft!}
        title={t("wallet.credits.obtainbutton")}
        hideBackButton={true}
        nomargin
      >
        <div
          className="my-3 d-flex flex-column"
          style={{
            maxHeight: isSmallScreen ? "calc(100dvh - 88px)" : "unset",
          }}
        >
          <div className="d-flex bodytext">
            <div className="flex-grow-1 light">
              {t("wallet.generic.originallypurchased")}:
            </div>
            <div>{helpers.price(originalPrice)}</div>
          </div>
          <div className="height-12"></div>
          <div className="d-flex bodytext">
            <div className="flex-grow-1 light">{t("wallet.credits.fee")}:</div>
            <div>- {helpers.price(originalPrice * 0.5)}</div>
          </div>
          <hr className="my-3" />
          <div className="d-flex h4">
            <div className="flex-grow-1">{t("wallet.bookings.totalget")}:</div>
            <div>
              {helpers.price(originalPrice * 0.5)}{" "}
              {t("wallet.credits.incredits")}
            </div>
          </div>
        </div>
        <div className="modal-bottom-space"></div>
      </_ProfileSectionLayout>
      <div className="d-flex justify-content-between px-4 py-2 border-top">
        <Button
          variant="blank"
          onlyText={true}
          text={t("general.cancel")}
          onClick={() => {
            closeModal();
          }}
        ></Button>
        <Button
          onClick={obtainRefund}
          loading={isLoading}
          text={t("wallet.credits.obtainbutton")}
        />
      </div>
    </>
  );
}
