import { useTranslation } from "react-i18next";
import Favorites from "../../../../_Web/components/Modals/Favorites";
import { openModal } from "../../../Utils/modal";
import Button from "./Button";
import { useSelector } from "react-redux";
import { isFavorite, selectUser } from "../../../../redux/slices/userSlice";
import FavoritesController from "../../../Controllers/FavoritesController";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../../config/Links";
import openAuthModal from "../../../../_Web/components/Auth/Auth";
import { RootState } from "../../../../redux/redux";

interface Props {
  nftId: string;
  // isLiked?: boolean;
  likeCount?: number;
}

export default function LikeButton({
  nftId,
  // isLiked = false,
  likeCount = 0,
}: Props) {
  const user = useSelector(selectUser);
  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(likeCount);
  const { t } = useTranslation();

  const isLiked = useSelector((state: RootState) => isFavorite(state, nftId));

  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const [isLoading, setIsLoading] = useState(false);

  const removeLike = async (userId: string, nftId: string) => {
    await FavoritesController.removeLike(userId, nftId);
  };

  // on click show modal
  const handleClick = async (e: any) => {
    if (!user) return openAuthModal();

    if (!liked) {
      // add like
      openModal({
        content: (
          <Favorites
            nftId={nftId}
            userId={user._id}
            onFinish={() => {
              //console.log("onFinish, likes before set", likesCount);
              setLikesCount(likesCount + 1);
            }}
          />
        ),
      });
    } else {
      setLiked(false);
      setIsLoading(true);
      try {
        await removeLike(user._id, nftId);
        setLikesCount(likesCount - 1);
      } catch (error) {
        // rollback
        setTimeout(() => setLiked(true), 500);
      }
      setIsLoading(false);
    }
  };

  if (isLoading) return <></>;

  return (
    <Button
      className="btn btn-likes btn-light-simple"
      text={likesCount.toString()}
      textLeft={true}
      size="sm"
      iconSize={20}
      iconFill={liked}
      icon="favorite"
      iconStyle={{
        color: liked ? "red" : "black",
        fill: liked ? "red" : "black",
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(e);
      }}
    />
  );
}
