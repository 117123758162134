import axios from "axios";
import { iBid } from "../models/iBid";
import { iPagination, iPaginationProps } from "../models/iPagination";
import store from "../../redux/redux";
import { setSellerPendingOffersCount } from "../../redux/slices/userSlice";

export default class OfferController {
  static async makeOffer(payload: {
    nftId: string;
    amount: number;
    expireDate: string;
    mode?: string;
  }): Promise<iBid> {
    const response = await axios.post("/nft/bid", payload);
    return response.data;
  }

  static async cancelOffer(payload: { bidId: string }): Promise<iBid> {
    const response = await axios.delete("/nft/bid", { data: payload });
    return response.data;
  }

  static async acceptOffer(payload: { bidId: string }): Promise<iBid> {
    const response = await axios.post("/nft/bid/accept", payload);
    return response.data;
  }

  static async declineOffer(payload: { bidId: string }): Promise<iBid> {
    const response = await axios.post("/nft/bid/decline", payload);
    return response.data;
  }

  static async createCounterOffer(payload: { bidId: string; amount: number }) {
    const response = await axios.post("/nft/bid/counter-offer", payload);
    return response.data;
  }

  static async acceptCounterOffer(payload: { bidId: string }) {
    const response = await axios.post("/nft/bid/counter-offer/accept", payload);
    return response.data;
  }

  static async declineCounterOffer(payload: { bidId: string }) {
    const response = await axios.post(
      "/nft/bid/counter-offer/decline",
      payload
    );
    return response.data;
  }

  static async getPaginated(
    params: iPaginationProps,
    mode: "seller" | "buyer" | "all" = "all"
  ): Promise<iPagination<iBid>> {
    const response = await axios.get("/nft/bid/paginate", {
      params: {
        ...params,
        mode,
      },
    });
    return response.data;
  }

  static async requestOffer(nftId: string) {
    const response = await axios.post("/nft/bid/request", { nftId });
    return response.data;
  }

  static async sellerPendingOffers() {
    const response = await axios.get("/nft/bid/pending");
    return response.data;
  }

  static async initPendingOffers() {
    const resp = await this.sellerPendingOffers();
    store.dispatch(setSellerPendingOffersCount(resp.data.count));
  }
}
