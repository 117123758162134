import { ChangeEvent } from "react";
import Icon from "../Icon/Icon";
import { Form } from "react-bootstrap";

interface Props {
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  onBlur?(): void;
  onClick?(): void;
  type?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  status?: "normal" | "success" | "error";
  value?: string | number;
  name?: string;
  id?: string;
  icon?: string;
  disabled?: boolean;
  onIconClick?(): void;
  min?: number;
  readOnly?: boolean;
  minLength?: number;
  maxLength?: number;
  autoFocus?: boolean;
}

export default function Input(props: Props) {
  const isTextArea = props.type === "textarea";

  return (
    <div className="w100 d-flex align-items-center" style={{ gap: "8px" }}>
      <div className="d-flex w100 align-items-center position-relative">
        {isTextArea ? (
          <Form.Control
            as="textarea"
            rows={3} // You can adjust the number of rows as needed
            {...props}
            className={`
              ${props.status === "error" ? "error" : null}
              ${props.className} 
            `}
          />
        ) : null}

        {!isTextArea ? (
          <Form.Control
            autoFocus={props.autoFocus}
            minLength={props.minLength}
            maxLength={props.maxLength}
            onClick={props.onClick}
            readOnly={props.readOnly}
            min={props.min}
            disabled={props.disabled}
            onBlur={() => props.onBlur?.()}
            id={props.id}
            name={props.name}
            value={props.value}
            className={`
        ${props.status === "error" ? "error" : null}
        ${props.className} 
        `}
            onChange={(e) => props.onChange?.(e as any)}
            type={props.type}
            placeholder={props.placeholder}
            required={props.required}
          />
        ) : null}

        {props.icon ? (
          <Icon
            className={`
            grey-color position-absolute white-background
            ${props.onIconClick ? "cursor-pointer" : ""}
            `}
            onClick={props.onIconClick}
            style={{ right: "8px", zIndex: 2 }}
            icon={props.icon}
            type="symbol"
          />
        ) : null}
      </div>

      {props.status === "success" ? (
        <Icon
          fill
          className="success-color"
          icon="check_circle"
          type="symbol"
        />
      ) : null}
    </div>
  );
}
