import { useTranslation } from "react-i18next";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { closeModal } from "../../../assets/Utils/modal";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

type ActionFeedbackModalProps = {
  icon: string;
  title: string;
  message?: string;
  continueBtnText: string;
  continueBtnIcon?: string;
  closeBtn?: boolean;
  continueClickCallback: () => void;
};

export default function ActionFeedbackModal({
  icon,
  title,
  message,
  continueBtnText,
  continueBtnIcon,
  closeBtn = true,
  continueClickCallback,
}: ActionFeedbackModalProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
          <span style={{ height: "22px" }}></span>
        </div>
        <div className="p-4 pt-5 d-flex flex-column align-items-center justify-content-center h100 w100">
          <Icon icon={icon} type="symbol" size={150} />
        </div>
        <div className="height-10"></div>
        <div className="p-4 pt-0 pt-md-0 p-md-5">
          <div className="d-flex flex-column gap-3 align-items-center">
            <div
              className={`h2 text-center align-items-center ${
                !message ? "regular" : ""
              }`}
            >
              {title}
            </div>
            {message && <p className="bodytext light text-center">{message}</p>}
          </div>

          <div className="d-flex justify-content-center gap-2">
            <Button
              style={{ marginTop: "40px", marginBottom: "69px" }}
              onClick={() => {
                closeModal();
                continueClickCallback();
              }}
              icon={continueBtnIcon}
              text={continueBtnText}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
