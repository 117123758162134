import { iNft, NFT_SELL_STATUS } from "../../assets/models/iNft";
import iUser from "../../assets/models/iUser";

const helpers = {
  price: function (
    number: string | number,
    currency: string = "€",
    toFixed: number = 0
  ): string {
    let numberFloat = typeof number === "string" ? parseFloat(number) : number;
    if (!numberFloat) return "€ 0";

    return `${currency} ${numberFloat.toFixed(toFixed)}`;
  },
  shortName: function (user: iUser) {
    let shortName = "";
    if (user.firstName) {
      shortName = user.firstName;
    }
    if (user.secondName) {
      shortName += ` ${user.secondName[0]}.`;
    }

    return shortName;
  },
  takPeriodDates: function (startDate: string, endDate: string) {
    const from = new Date(startDate ?? "")
      .toLocaleString("it-IT", {
        weekday: "short",
        day: "2-digit",
      })
      .replace(" a.m.", "")
      .replace(" p.m.", "");

    const to = new Date(endDate ?? "")
      .toLocaleString("it-IT", {
        weekday: "short",
        day: "2-digit",
        month: "short",
      })
      .replace(" a.m.", "")
      .replace(" p.m.", "");

    return `${from} - ${to}`;
  },
  feeToString: function (fee: number) {
    return `${Math.round(fee * 100)}%`;
  },
  getXDaysAgo: function (days: number) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  },
  getOldPrice: function (nft: iNft) {
    return getPrices(nft, true);
  },
  getCurrentPrice: function (nft: iNft) {
    return getPrices(nft, false);
  },
};

function getPrices(nft: iNft, isOldPrice: boolean) {
  let oldPrice =
    nft.sellStatus === NFT_SELL_STATUS.NOT_FOR_SALE
      ? nft.marketPrice !== undefined
        ? Number(nft.marketPrice).toFixed(2)
        : undefined
      : nft.marketPrice !== undefined
      ? Number(nft.marketPrice).toFixed(2)
      : Number(nft.originalPrice)?.toFixed(2);

  let currentPrice = nft.originalPrice
    ? Number(nft.originalPrice).toFixed(2)
    : 0;

  if (nft.sellStatus === NFT_SELL_STATUS.FOR_SALE) {
    currentPrice = nft.currentPrice ? Number(nft.currentPrice).toFixed(2) : 0;
    if (nft.currentPrice !== undefined && oldPrice !== undefined) {
      if (nft.currentPrice.toFixed(2) >= oldPrice) {
        oldPrice = undefined;
      }
    }
  }

  let respond = isOldPrice ? oldPrice : currentPrice;

  return respond;
}

export default helpers;
