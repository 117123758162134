import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";

import { iNft, payloadHotelType } from "../../../../models/iNft";
import GoogleController from "../../../../Controllers/GoogleController";
import { mapStyle } from "../../../../../_App/components/Partners/Partners";
import { COLLECTION_TYPE } from "../../../../models/iCollection";

export interface nftProps {
  nft: iNft;
}

export default function NftMap({ nft }: nftProps) {
  const type = nft.payload.type;

  let placeId = "";
  if (type === COLLECTION_TYPE.hotel || !type)
    placeId = nft._collectionCensus?.location?.place_id;
  if (type === COLLECTION_TYPE.experience)
    placeId = nft.payload?.location?.place_id;

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const initCordinates = async () => {
    if (type === COLLECTION_TYPE.hotel || !type) {
      if (nft._collectionCensus?.location?.coordinates)
        setCenter(nft._collectionCensus?.location?.coordinates as any);
      else {
        const placeData = await GoogleController.getPlaceCoordinates(placeId); // TODO remove eventually
        if (placeData) setCenter(placeData);
      }
    }

    if (type === COLLECTION_TYPE.experience) {
      if (nft.payload?.location?.coordinates)
        setCenter(nft.payload?.location?.coordinates as any);
      else if (nft._collectionCensus?.location?.coordinates)
        setCenter(nft._collectionCensus?.location?.coordinates as any);
    }
  };

  useEffect(() => {
    initCordinates();
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_API_KEY),
    libraries: ["places"],
  });

  let markers: any[] = [];

  if (type === COLLECTION_TYPE.hotel || !type) {
    markers =
      (nft.payload as payloadHotelType)?.extraInfo?.mapDistance?.map(
        (item) => ({
          lat: item.marker.latLong.latitude,
          lng: item.marker.latLong.longitude,
          icon: item.marker.icon,
        })
      ) || [];
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="h100 w100"
      center={center}
      zoom={10}
      options={{
        styles: mapStyle as any,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true,
        gestureHandling: "cooperative",
        mapTypeControl: true,
      }}
    >
      {nft.payload?.extraInfo?.mapDistance ? (
        markers.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            // icon={mapIcons[marker.icon]}
          />
        ))
      ) : (
        <Marker
          position={center}
          //   icon={{
          //     url: markerImg,
          //     scaledSize: new window.google.maps.Size(45, 45),
          //   }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}
