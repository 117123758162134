import { Form } from "react-bootstrap";
import Input from "../../../../assets/TakyonDesignSystem/components/Input/Input";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import UserController from "../../../../assets/Controllers/UserController";
import { handleInputChange } from "../../../../assets/Utils/input";
import * as yup from "yup";
import { Formik } from "formik";
import { openModal } from "../../../../assets/Utils/modal";

const PASSWORD_MIN_LENGHT = 6;

// Schema di validazione yup per la validazione sincrona
const validationSchema = yup.object({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .min(PASSWORD_MIN_LENGHT)
    .required("New password is required"),
  newPasswordConfirm: yup
    .string()
    .min(PASSWORD_MIN_LENGHT)
    .oneOf([yup.ref("newPassword")], "Passwords must match")
    .required("Password confirmation is required"),
});

// Funzione di validazione asincrona (esempio: verifica della vecchia password)
const validateAsync = async (values: any) => {
  const errors = { oldPassword: "" };
  // Simula una chiamata API per verificare la vecchia password
  if (values.oldPassword !== "expectedOldPassword") {
    // Sostituisci con la logica reale
    errors.oldPassword = "Old password is not correct";
  }
  return errors;
};

export default function Privacy() {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [error, setError] = useState("");

  const submit = async (formValues: any) => {
    setIsLoading(true);
    try {
      await UserController.changePassword(formValues);
      await UserController.refreshUserInfo();

      // closeWalletPages("PrivacySettingsPage");
      openModal({ content: "Password changed successfully" });
    } catch (error: any) {
      // apiErrorToast(error);

      if (
        String(error?.response?.data?.message)
          .toLowerCase()
          .includes("not correct")
      ) {
        setError("Old password is not correct");
      }
    }
    setIsLoading(false);
  };

  return (
    <div id="privacy_section" className="d-flex flex-column gap-2 mx-md-2">
      <Formik
        validationSchema={validationSchema}
        onSubmit={submit}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t("wallet.settings.currentpass")}</Form.Label>
              <Form.Control
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}
                type="password"
                placeholder={t("wallet.settings.currentpassph")}
                isInvalid={
                  errors.oldPassword !== undefined &&
                  touched.oldPassword !== undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.oldPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("wallet.settings.newpassword")}</Form.Label>
              <Form.Control
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                type="password"
                placeholder={t("wallet.settings.newpasswordph")}
                isInvalid={
                  errors.newPassword !== undefined &&
                  touched.newPassword !== undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("wallet.settings.confirmnewpass")}</Form.Label>
              <Form.Control
                name="newPasswordConfirm"
                value={values.newPasswordConfirm}
                onChange={handleChange}
                type="password"
                placeholder={t("wallet.settings.confirmnewpassph")}
                isInvalid={
                  errors.newPasswordConfirm !== undefined &&
                  touched.newPasswordConfirm !== undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPasswordConfirm}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button
                loading={isLoading}
                size="sm"
                type="submit"
                text={t("wallet.settings.savepassword")}
              ></Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
