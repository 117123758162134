import { useNavigate } from "react-router-dom";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { LINK_TYPE } from "../../../config/Links";

import bg from "../../../assets/img/ubox/ubox-bg.jpg";
import logo from "../../../assets/img/ubox/ubox-logo.png";
import takyon from "../../../assets/img/icons/TakyonLight.svg";
import Animate from "../../components/Animate/Animate";
import takImg from "../../../assets/img/forclients/tak.svg";
import openAuthModal, { AUTH_SECTION } from "../../components/Auth/Auth";

export default function UbPromoLanding() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fireTagManagerEvent("ubox_landing", {
      ubox_landing: "true",
    });
  }, []);

  return (
    <main id="ub-landing">
      <section
        style={{
          padding: "200px 0",
          width: "100vw",
          background: `url(${bg})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container h100">
          <div className="content h100">
            <div className="h100 d-flex justify-content-center">
              <div className="w100">
                <div className="d-flex justify-content-center justify-content-md-start">
                  <img className="height-50" src={takyon} alt="" />
                  <div className="vertical-divider height-50"></div>
                  <img className="height-50" src={logo} alt="" />
                </div>

                <br />
                <br />
                <br />
                <br />

                <p className="h1-large regular white-color text-center text-md-left">
                  {t("ubox.1")}{" "}
                  <span className="primary-color">{t("ubox.2")}</span>!
                </p>

                <p className="h3 regular white-color text-center text-md-left">
                  {t("ubox.3")}
                </p>

                <br />

                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button
                    text={t("ubox.4")}
                    onClick={() =>
                      openAuthModal(
                        AUTH_SECTION.register,
                        () => {
                          navigate(LINK_TYPE.WALL);
                        },
                        "UB50"
                      )
                    }
                  />
                </div>
              </div>
              <div style={{ maxWidth: "500px" }} className="w100 only-desktop">
                <Animate
                  automatic
                  className="w100 d-flex justify-content-center"
                  element={
                    <div className="w100 d-flex justify-content-center position-relative">
                      <div
                        id="text-1"
                        style={{
                          borderRadius: "100px 100px 100px 0px",
                          padding: "16px 36px 11px 28px",
                          zIndex: 2,
                          top: "25px",
                          left: "-20px",
                        }}
                        className="position-absolute white-background shadow border medium bodytext-lg"
                      >
                        {t("forclients.section_2.9")}
                      </div>
                      <div
                        id="text-2"
                        style={{
                          borderRadius: "100px 100px 0px 100px",
                          padding: "16px 36px 11px 28px",
                          zIndex: 2,
                          bottom: "25px",
                          right: "-20px",
                        }}
                        className="position-absolute primary-background shadow border medium bodytext-lg"
                      >
                        {t("forclients.section_2.10")}
                      </div>

                      <div
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          aspectRatio: "12/16",
                          borderRadius: "25px",
                          opacity: 0.8,
                        }}
                        className="white-background d-flex align-items-center justify-content-center"
                      >
                        <img style={{ width: "65%" }} src={takImg} alt="" />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w100 white-background" style={{ padding: "100px 0" }}>
        <div className="container">
          <div className="content">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="h1-large reguar text-center">{t("ubox.5")}</p>
              <p className="h3 regular text-center">{t("ubox.6")}</p>
              <br />
              <br />
              <Button
                text={t("ubox.7")}
                onClick={() =>
                  openAuthModal(
                    AUTH_SECTION.register,
                    () => {
                      navigate(LINK_TYPE.WALL);
                    },
                    "UB50"
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
