import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { datetimeToString } from "../../../Utils/generic";
import { apiErrorToast } from "../../../Utils/errors";
import Button from "../../components/Button/Button";

import NftController from "../../../Controllers/NftController";

import { WallSlotOffer, iWallSlot } from "../../../../config/wall";
import Skeleton from "react-loading-skeleton";

import { iBid } from "../../../models/iBid";

import helpers from "../../../../_Web/helpers/helpers";

import openOfferModal from "../../../../_Web/components/Checkout/Offer";
import TakCard from "../TakCard/TakCard";
import { iNft } from "../../../models/iNft";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/userSlice";
import openAuthModal from "../../../../_Web/components/Auth/Auth";

interface Props {
  slot?: iWallSlot;
}

function Bid({ data }: { data: iBid }) {
  const { t } = useTranslation();

  return (
    <div className="panel partner mb-4 p-4">
      <p className="bodytext-sm light">
        {(() => {
          const diffTime =
            (Date.now() - new Date(data._createdAt).getTime()) / 1000;
          if (diffTime < 3600) {
            return `${Math.round(diffTime / 60)} ${t("wall.offer.minutes")}`;
          } else if (diffTime < 86400) {
            return `${Math.round(diffTime / 3600)} ${t("wall.offer.hours")}`;
          } else if (diffTime < 172800) {
            return t("wall.offer.yesterday");
          } else {
            return datetimeToString(data._createdAt);
          }
        })()}
      </p>
      <h4 className="bodytext-lg medium">
        {data._buyerName} {t("wall.offer.hasoffered")}{" "}
        {helpers.price(data.amount)}
      </h4>
    </div>
  );
}

export default function Offer(props: Props) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [isNftLoading, setIsNftLoading] = useState(true);
  const [isOfferLoading, setIsOfferLoading] = useState(true);
  const [offerData, setOfferData] = useState<iNft[]>([]);
  const [bids, setBids] = useState<iBid[]>([]);

  const getBids = async (nftId: string) => {
    try {
      setIsOfferLoading(true);
      const data = await NftController.getBids(nftId);
      setBids(data.data);
    } catch (error) {
      console.log(error);
    }

    setIsOfferLoading(false);
  };

  const loadPayload = async () => {
    setIsNftLoading(true);
    try {
      const nft = await NftController.getPaginated({
        page: 1,
        size: (props.slot?.config.payload as any).limit,
        sort: JSON.stringify((props.slot?.config.payload as any).sort),
        query: JSON.stringify((props.slot?.config.payload as any).query),
      });

      setOfferData(nft.data);
      // we always will refer to the first nft offerData[0]
      getBids(nft.data[0]?._id);
    } catch (error) {
      console.log(error);
    }

    setIsNftLoading(false);
  };

  useEffect(() => {
    loadPayload();
  }, []);

  const isLoading = isNftLoading || isOfferLoading;

  if (!isLoading && !offerData.length) return <></>;

  return (
    <>
      <div className="text-white mt-5 mb-5">
        <h2 className="h1 regular m-0">{t(props.slot?.title ?? "")}</h2>
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "32px" }}
        >
          <div className="d-inline-flex flex-wrap"></div>
        </div>

        {isLoading ? (
          <>
            <Skeleton style={{ width: "100%", opacity: "0.5" }} height={550} />
          </>
        ) : (
          <>
            <div className="d-flex flex-column flex-md-row gap-3">
              <div className="col-12 col-md-6 flex-column flex-md-row gap-4">
                <TakCard
                  style={{ height: "100%", minHeight: "400px" }}
                  preventAspectRatio
                  nft={offerData[0]}
                />
              </div>
              <div className="col-12 col-md-6 p-md-4 d-flex flex-column justify-content-center">
                <div>
                  {bids &&
                    bids.length > 0 &&
                    bids
                      .slice(0, 3)
                      .map((bid, key) => bid && <Bid key={key} data={bid} />)}
                  <Button
                    text={t("wall.offer.makeanoffer")}
                    onClick={() => {
                      if (!user) return openAuthModal();
                      openOfferModal(offerData[0]._id);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
