import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import defaultLangRes from "../translations/en.json";
import axios from "axios";
import { DEFAULT_LANGUAGE, LANG } from "../../config/Lang";

export const LOCALE_LANGUAGE_KEY =
  process.env.REACT_APP_FRONTEND_URI + "1V26357B4685N795432";

// Default lang init
i18next.use(initReactI18next).init({
  resources: {
    en: { translation: defaultLangRes },
  },
  lng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});
i18next.changeLanguage(DEFAULT_LANGUAGE);

export function setLocaleLanguage(lang: LANG) {
  localStorage.setItem(LOCALE_LANGUAGE_KEY, lang);
  i18next.changeLanguage(lang);
}

export async function getLocaleLanguage(): Promise<string> {
  const langFromStorage = localStorage.getItem(LOCALE_LANGUAGE_KEY);
  if (langFromStorage) return String(langFromStorage);

  const langFromIp = await getLangFromIp();
  if (langFromIp) {
    setLocaleLanguage(langFromIp as any);
    return langFromIp;
  }

  setLocaleLanguage(DEFAULT_LANGUAGE as any);

  return DEFAULT_LANGUAGE;
}

export async function initi18next() {
  const resources: any = {};

  await Promise.all(
    Object.keys(LANG).map(async (lang) => {
      resources[lang] = {
        translation: JSON.parse(
          JSON.stringify(await import(`../translations/${lang}.json`))
        ),
      };
    })
  );

  i18next.use(initReactI18next).init({
    resources,
    lng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  });

  i18next.changeLanguage(await getLocaleLanguage());
}

async function getLangFromIp(): Promise<string | undefined> {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const lang = response.data.languages.split(",")[0].split("-")[0];

    if (!Object.values(LANG).includes(lang))
      throw new Error("This lang is not defined in Lang Enum:", lang);

    return lang;
  } catch (error) {
    console.log("Error getting the lang from IP", error);
  }

  return undefined;
}
