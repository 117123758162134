import { useNavigate, useLocation } from "react-router-dom";

export function useOfferNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const offer = location.state;

  const goBack = () => navigate(-1);

  return { offer, goBack };
}

export function useProfileNavigation() {
  const navigate = useNavigate();
  // const location = useLocation();
  // const offer = location.state;

  const goBack = () => navigate(-1);

  return { goBack };
}
