export const PHONE_CODES = [
  { country: "ITA", code: "+39" },
  { country: "ESP", code: "+34" },
  { country: "USA", code: "+1" },
  { country: "AFG", code: "+93" },
  { country: "ALB", code: "+355" },
  { country: "ALD", code: "+358" },
  { country: "ALG", code: "+213" },
  { country: "AND", code: "+376" },
  { country: "ANG", code: "+244" },
  { country: "ANT", code: "+599" },
  { country: "ARG", code: "+54" },
  { country: "ARM", code: "+374" },
  { country: "ARU", code: "+297" },
  { country: "ASA", code: "+1-684" },
  { country: "ATG", code: "+1-268" },
  { country: "AUS", code: "+61" },
  { country: "AUT", code: "+43" },
  { country: "AZE", code: "+994" },
  { country: "BAH", code: "+1-242" },
  { country: "BAN", code: "+880" },
  { country: "BDI", code: "+257" },
  { country: "BEL", code: "+32" },
  { country: "BEN", code: "+229" },
  { country: "BER", code: "+1-441" },
  { country: "BFA", code: "+226" },
  { country: "BGR", code: "+359" },
  { country: "BHR", code: "+973" },
  { country: "BHS", code: "+1-242" },
  { country: "BIH", code: "+387" },
  { country: "BLR", code: "+375" },
  { country: "BLZ", code: "+501" },
  { country: "BOL", code: "+591" },
  { country: "BOT", code: "+267" },
  { country: "BRA", code: "+55" },
  { country: "BRB", code: "+1-246" },
  { country: "BRU", code: "+673" },
  { country: "BUL", code: "+359" },
  { country: "BWA", code: "+267" },
  { country: "CAF", code: "+236" },
  { country: "CAM", code: "+855" },
  { country: "CAN", code: "+1" },
  { country: "CAY", code: "+1-345" },
  { country: "CCK", code: "+61" },
  { country: "CIV", code: "+225" },
  { country: "CMR", code: "+237" },
  { country: "COD", code: "+243" },
  { country: "COK", code: "+682" },
  { country: "COL", code: "+57" },
  { country: "COM", code: "+269" },
  { country: "CPV", code: "+238" },
  { country: "CRC", code: "+506" },
  { country: "CRO", code: "+385" },
  { country: "CUB", code: "+53" },
  { country: "CXR", code: "+61" },
  { country: "CTA", code: "+236" },
  { country: "CYP", code: "+357" },
  { country: "CZE", code: "+420" },
  { country: "DEN", code: "+45" },
  { country: "DJI", code: "+253" },
  { country: "DMA", code: "+1-767" },
  { country: "DOM", code: "+1-809" },
  { country: "DOM", code: "+1-829" },
  { country: "DOM", code: "+1-849" },
  { country: "ECU", code: "+593" },
  { country: "EGY", code: "+20" },
  { country: "ENG", code: "+44" },
  { country: "ERI", code: "+291" },
  { country: "EST", code: "+372" },
  { country: "ETH", code: "+251" },
  { country: "FRO", code: "+298" },
  { country: "FIJ", code: "+679" },
  { country: "FIN", code: "+358" },
  { country: "FRA", code: "+33" },
  { country: "FSM", code: "+691" },
  { country: "GAB", code: "+241" },
  { country: "GAM", code: "+220" },
  { country: "GBG", code: "+44" },
  { country: "GBM", code: "+44" },
  { country: "GBZ", code: "+350" },
  { country: "GEO", code: "+995" },
  { country: "GER", code: "+49" },
  { country: "GGY", code: "+44" },
  { country: "GHA", code: "+233" },
  { country: "GIB", code: "+350" },
  { country: "GIN", code: "+224" },
  { country: "GMB", code: "+220" },
  { country: "GNB", code: "+245" },
  { country: "GRE", code: "+30" },
  { country: "GRL", code: "+299" },
  { country: "GRN", code: "+1-473" },
  { country: "GUA", code: "+502" },
  { country: "GUF", code: "+594" },
  { country: "GUI", code: "+224" },
  { country: "GUM", code: "+1-671" },
  { country: "GUY", code: "+592" },
  { country: "HAI", code: "+509" },
  { country: "HKG", code: "+852" },
  { country: "HON", code: "+504" },
  { country: "HUN", code: "+36" },
  { country: "IDN", code: "+62" },
  { country: "IND", code: "+91" },
  { country: "IRL", code: "+353" },
  { country: "IRN", code: "+98" },
  { country: "IRQ", code: "+964" },
  { country: "ISL", code: "+354" },
  { country: "ISR", code: "+972" },
  { country: "JAM", code: "+1-876" },
  { country: "JOR", code: "+962" },
  { country: "JPN", code: "+81" },
  { country: "KAZ", code: "+7" },
  { country: "KEN", code: "+254" },
  { country: "KGZ", code: "+996" },
  { country: "KIR", code: "+686" },
  { country: "KOR", code: "+82" },
  { country: "KSA", code: "+966" },
  { country: "KUW", code: "+965" },
  { country: "LAO", code: "+856" },
  { country: "LAT", code: "+371" },
  { country: "LBR", code: "+231" },
  { country: "LBY", code: "+218" },
  { country: "LCA", code: "+1-758" },
  { country: "LIE", code: "+423" },
  { country: "LTU", code: "+370" },
  { country: "LUX", code: "+352" },
  { country: "LVA", code: "+371" },
  { country: "MAC", code: "+853" },
  { country: "MAD", code: "+261" },
  { country: "MAR", code: "+212" },
  { country: "MDA", code: "+373" },
  { country: "MDV", code: "+960" },
  { country: "MEX", code: "+52" },
  { country: "MKD", code: "+389" },
  { country: "MLI", code: "+223" },
  { country: "MLT", code: "+356" },
  { country: "MNE", code: "+382" },
  { country: "MNG", code: "+976" },
  { country: "MOZ", code: "+258" },
  { country: "MSR", code: "+1-664" },
  { country: "MTN", code: "+222" },
  { country: "MTQ", code: "+596" },
  { country: "MWI", code: "+265" },
  { country: "MYA", code: "+95" },
  { country: "MYT", code: "+262" },
  { country: "NAM", code: "+264" },
  { country: "NCA", code: "+505" },
  { country: "NCL", code: "+687" },
  { country: "NED", code: "+31" },
  { country: "NEP", code: "+977" },
  { country: "NFK", code: "+672" },
  { country: "NGA", code: "+234" },
  { country: "NIG", code: "+227" },
  { country: "NIR", code: "+44" },
  { country: "NIU", code: "+683" },
  { country: "NMI", code: "+1-670" },
  { country: "NRU", code: "+674" },
  { country: "NZL", code: "+64" },
  { country: "OMA", code: "+968" },
  { country: "PAK", code: "+92" },
  { country: "PAN", code: "+507" },
  { country: "PCN", code: "+870" },
  { country: "PER", code: "+51" },
  { country: "PHI", code: "+63" },
  { country: "PNG", code: "+675" },
  { country: "POL", code: "+48" },
  { country: "POR", code: "+351" },
  { country: "PRK", code: "+850" },
  { country: "PRT", code: "+351" },
  { country: "PRY", code: "+595" },
  { country: "PSE", code: "+970" },
  { country: "PYF", code: "+689" },
  { country: "QAT", code: "+974" },
  { country: "ROU", code: "+40" },
  { country: "RUS", code: "+7" },
  { country: "RWA", code: "+250" },
  { country: "SAH", code: "+212" },
  { country: "SAM", code: "+685" },
  { country: "SEN", code: "+221" },
  { country: "SEY", code: "+248" },
  { country: "SGP", code: "+65" },
  { country: "SHN", code: "+290" },
  { country: "SLE", code: "+232" },
  { country: "SLV", code: "+503" },
  { country: "SMR", code: "+378" },
  { country: "SOL", code: "+677" },
  { country: "SOM", code: "+252" },
  { country: "SRB", code: "+381" },
  { country: "STP", code: "+239" },
  { country: "SUD", code: "+249" },
  { country: "SUI", code: "+41" },
  { country: "SUR", code: "+597" },
  { country: "SVK", code: "+421" },
  { country: "SVN", code: "+386" },
  { country: "SWE", code: "+46" },
  { country: "SWZ", code: "+268" },
  { country: "SYR", code: "+963" },
  { country: "TAN", code: "+255" },
  { country: "TCA", code: "+1-649" },
  { country: "TGO", code: "+228" },
  { country: "THA", code: "+66" },
  { country: "TLS", code: "+670" },
  { country: "TKL", code: "+690" },
  { country: "TUN", code: "+216" },
  { country: "TUR", code: "+90" },
  { country: "TKM", code: "+993" },
  { country: "TUV", code: "+688" },
  { country: "UGA", code: "+256" },
  { country: "UKR", code: "+380" },
  { country: "URU", code: "+598" },
  { country: "UZB", code: "+998" },
  { country: "VEN", code: "+58" },
  { country: "VIE", code: "+84" },
  { country: "VIN", code: "+1-784" },
  { country: "VIR", code: "+1-340" },
  { country: "WAL", code: "+44" },
  { country: "WLF", code: "+681" },
  { country: "YEM", code: "+967" },
  { country: "ZAM", code: "+260" },
  { country: "ZIM", code: "+263" },
];
