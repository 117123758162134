import { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import Icon from "../Icon/Icon";

interface Props {
  onChange?(e: ChangeEvent<HTMLSelectElement>): any;
  defaultValue?: string | number | undefined;
  options: Array<{
    value: string | number | undefined;
    text: string | number;
    disabled?: boolean;
  }>;
  className?: string;
  name?: string;
  id?: string;
  small?: boolean;
  disabled?: boolean;
  value?: string | number;
  required?: boolean;
  status?: "normal" | "success" | "error";
}

export default function Select(props: Props) {
  return (
    <div className="w100 d-flex align-items-center" style={{ gap: "8px" }}>
      <div className="w100 d-flex align-items-center position-relative">
        <Form.Control
          style={{ paddingRight: "30px" }}
          className={`
          w100 m-0
          ${props.status === "error" ? "error" : null}
          ${props.className}`}
          defaultValue={props.defaultValue}
          value={props.value}
          required={props.required}
          name={props.name}
          id={props.id}
          disabled={props.disabled}
          as="select"
          onChange={(e) => props.onChange?.(e as any)}
        >
          {props.options.map((opt, key) => {
            return (
              <option
                key={"option:" + key}
                value={opt.value}
                disabled={opt.disabled}
              >
                {opt.text}
              </option>
            );
          })}
        </Form.Control>
        <Icon
          className="position-absolute"
          icon="keyboard_arrow_down"
          type="symbol"
          style={{ right: "8px", userSelect: "none", pointerEvents: "none" }}
        />
      </div>
      {props.status === "success" ? (
        <Icon
          fill
          className="success-color"
          icon="check_circle"
          type="symbol"
        />
      ) : null}
    </div>
  );
}
