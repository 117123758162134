import { FormEvent, useEffect, useState } from "react";
import {
  BuilderComponent,
  builder,
  useIsPreviewing,
  Builder,
} from "@builder.io/react";
import NotFound from "./_Shared/pages/NotFound/NotFound";
import PartnerController from "./assets/Controllers/PartnerController";
import { fireTagManagerEvent } from "./assets/Services/tagmanager";
import { Form } from "react-bootstrap";
import Input from "./assets/TakyonDesignSystem/components/Input/Input";
import { handleInputChange } from "./assets/Utils/input";
import Button from "./assets/TakyonDesignSystem/components/Button/Button";

// Put your API key here
builder.init(String(process.env.REACT_APP_BUILDER_IO_APIKEY));

export function BecomePartnerSmart(params: {
  emailPlaceholder: string;
  websitePlaceholder: string;
  phonePlaceholder: string;
  infotext: string;
  button: string;
  success: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [partnerInformation, setPartnerInformation] = useState<{
    phoneNumber: string;
    website: string;
    email: string;
  }>({
    phoneNumber: "",
    website: "",
    email: "",
  });

  const SendFormClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!partnerInformation.website || !partnerInformation.phoneNumber) return;

    setIsLoading(true);
    try {
      await PartnerController.requestPartnership({
        hotelName: partnerInformation.website,
        phoneNumber: partnerInformation.phoneNumber,
        email: partnerInformation.email,
      });

      fireTagManagerEvent("partner_submit_success", {
        hotel: partnerInformation.website,
      });

      setIsFormSent(true);
    } catch (error) {
      fireTagManagerEvent("partner_submit_error", {
        hotel: partnerInformation.website,
        error: String(error),
      });
    }
    setIsLoading(false);
  };

  return (
    <section className="web-section-container">
      {!isFormSent ? (
        <div className="web-section-content align-items-center">
          <form
            onSubmit={SendFormClick}
            style={{ width: "100%", gap: "0" }}
            id="become-partner-form-builder-io"
          >
            <Form.Group className="w100">
              <Input
                type="email"
                name="email"
                placeholder={params.emailPlaceholder}
                value={partnerInformation.email}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>

            <div className="height-12"></div>

            <Form.Group className="w100">
              <Input
                type="text"
                name="website"
                placeholder={params.websitePlaceholder}
                value={partnerInformation.website}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>

            <div className="height-12"></div>

            <Form.Group className="w100">
              {/* <Form.Label>{params.phoneLabel}</Form.Label> */}
              <Input
                type="number"
                name="phoneNumber"
                placeholder={params.phonePlaceholder}
                value={partnerInformation.phoneNumber}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>

            <div className="height-12"></div>

            <span className="bodytext-sm dark-grey-color regular">
              {params.infotext}
            </span>

            <div className="height-20"></div>

            <div className="">
              <Button
                text={params.button}
                type="submit"
                size="lg"
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="web-section-content align-items-center">
          <div>{params.success}</div>
        </div>
      )}
    </section>
  );
}

Builder.registerComponent(BecomePartnerSmart, {
  name: "BecomePartnerFormSmart3",
  inputs: [
    { name: "emailPlaceholder", type: "text" },
    { name: "websitePlaceholder", type: "text" },
    { name: "phonePlaceholder", type: "text" },
    { name: "infotext", type: "text" },
    { name: "button", type: "text" },
    { name: "success", type: "text" },
  ],
});

function CustomLink(props: any) {
  return <a {...props} />;
}

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function BuilderIo() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname,
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      // if the page title is found,
      // set the document title
      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [window.location.pathname]);

  if (notFound && !isPreviewingInBuilder) {
    console.log("Builder.io : not Found");
    return (
      <div className="black-background">
        <NotFound />
      </div>
    );
  }

  if (!content) return <></>;

  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent
        model="page"
        content={content}
        renderLink={(props) => <CustomLink {...props} />}
      />
    </>
  );
}
