import { t } from "i18next";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NftController from "../../../../assets/Controllers/NftController";
import TakCard from "../../../../assets/TakyonDesignSystem/components/TakCard/TakCard";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import { iNft, NFT_ACCESS_STATUS } from "../../../../assets/models/iNft";
import { selectUser } from "../../../../redux/slices/userSlice";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";
import TakSmallList from "../../TakSmallList/TakSmallList";

export function TakListOld() {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const [nfts, setNfts] = useState<iNft[]>([]);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const loadNfts = async (status: string) => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated(
        {
          page: 1,
          size: 500,
          query: JSON.stringify({
            owner: user?.email,
            accessStatus: status,
          }),
          sort: JSON.stringify({
            _lockDate: 1,
          }),
        },
        false
      );
      setNfts([...data.data]);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user && user.email) {
      loadNfts(NFT_ACCESS_STATUS.REDEEMED);
    }
  }, [user]);

  if (isLoading) return <div>Loading...</div>;

  if (nfts.length === 0)
    return (
      <span className="bodytext regular dark-grey-color">
        {t("wallet.bookings.noresultstextpast")}
      </span>
    );

  return (
    <>
      {/* initially a deal has only one bid/offer */}
      <TakSmallList nfts={nfts} />
    </>
  );
}
