import TakLogo from "../../images/TakyonShortLight.svg";

export default function TakShortId(props: { shortId: string }) {
  return (
    <div className="d-flex align-items-center">
      <div>
        <img
          style={{
            width: "36px",
            height: "13px",
            transform: "translateY(-2.6px)",
          }}
          src={TakLogo}
          alt="Takyon"
        />
      </div>
      <span className="bodytext-sm-xs light">
        {props?.shortId ? "#" + props?.shortId : null}
      </span>
    </div>
  );
}
