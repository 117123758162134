import { Form } from "react-bootstrap";
import Switch from "../../../../assets/TakyonDesignSystem/components/Switch/Switch";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/userSlice";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import UserController from "../../../../assets/Controllers/UserController";
import { apiErrorToast } from "../../../../assets/Utils/errors";

export default function Privacy() {
  const user = useSelector(selectUser)!;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [userState, setUserState] = useState<{
    marketingEmails: "on" | "off";
  }>({
    marketingEmails: user.marketingEmails ? "on" : "off",
  });

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.patchUser(userState);
      await UserController.refreshUserInfo();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div
      id="preferences_section"
      className="d-flex flex-column gap-2 my-2 mx-md-2"
    >
      <Form onSubmit={submit}>
        <Form.Group className="mb-3 d-flex gap-3">
          <Switch
            checked={userState.marketingEmails === "on"}
            onChange={(e) => {
              e.target.checked
                ? setUserState({ marketingEmails: "on" })
                : setUserState({ marketingEmails: "off" });
            }}
          />
          <Form.Label className="light">
            {t("wallet.settings.notifications")}
          </Form.Label>
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            loading={isLoading}
            size="sm"
            type="submit"
            text={t("wallet.settings.save")}
          ></Button>
        </div>
      </Form>
    </div>
  );
}
