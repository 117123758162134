import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { Button, Container } from "react-bootstrap";
import UserController from "../../../assets/Controllers/UserController";

export default function UnsubscribeMarketing() {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const email = params.get("email");

    if (email) UserController.unsubscribeMarketing(email);
  }, []);

  return (
    <main>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container>
        <div className="content">
          <div className="">
            <h1 className="text-center">Successfully unsubscribed</h1>
            <br />
            <br />
            <div className="d-flex align-items-center justify-content-center">
              <Button onClick={() => navigate("/")}>Visit website</Button>
            </div>
          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </main>
  );
}
