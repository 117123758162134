import React, { useState } from "react";

// Definizione del tipo per le props delle schede
type Tab = {
  key: string;
  label: string;
  component: React.ReactNode;
};

// Props per il componente TabLayout
type TabLayoutProps = {
  title: string;
  tabs: Tab[];
  defaultTabKey: string;
};

// Componente TabLayout
const TabLayout: React.FC<TabLayoutProps> = ({
  title,
  tabs,
  defaultTabKey,
}) => {
  const [activeTab, setActiveTab] = useState(defaultTabKey);

  return (
    <div
      style={{ paddingLeft: "10px" }}
      className="tab-layout d-flex flex-column h100 my-4 mx-md-4"
    >
      <h3 className="medium h3">{title}</h3>
      <ul className="nav nav-underline border-bottom">
        {tabs.map((tab) => (
          <li key={tab.key} className="nav-item">
            <a
              className={`nav-link bodytext ${
                activeTab === tab.key
                  ? "active medium text-black"
                  : "light text-dark-grey"
              }`}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="height-40"></div>
      <div
        className="h100 w100"
        style={{
          overflowY: "scroll",
          paddingRight: "10px",
          paddingBottom: "200px",
        }}
      >
        {tabs.map(
          (tab) =>
            activeTab === tab.key && <div key={tab.key}>{tab.component}</div>
        )}
      </div>
    </div>
  );
};

export default TabLayout;
