import { useEffect, useState } from "react";
import NftCard2 from "../../assets/TakyonDesignSystem/components/NftCard/NftCard2";
import { iNft } from "../../assets/models/iNft";
import NftController from "../../assets/Controllers/NftController";

export default function Test() {
  const [nft, setNft] = useState<iNft>();

  useEffect(() => {
    (async () => {
      const nft = await NftController.getNft(
        "575758f0-31f4-4317-8a3a-db6323ffd5f5"
      );

      setNft(nft);
    })();
  }, []);

  return (
    <div
      style={{ width: "100vw", height: "100vh" }}
      className="d-flex align-items-center justify-content-center black-background"
    >
      {nft ? (
        <NftCard2
          partnerMode
          liked
          showOwner
          maxWidth="400px"
          maxHeight="600px"
          nft={nft}
        />
      ) : (
        "loading"
      )}
    </div>
  );
}
