import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import openAuthModal from "../components/Auth/Auth";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import UserController from "../../assets/Controllers/UserController";

interface RequireAuthProps {
  children: ReactNode;
}

const RequiresAuth = ({ children }: RequireAuthProps) => {
  const user = useSelector(selectUser);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = () => {
      // Logica per verificare se l'utente è autenticato
      // token check
      const isAuthenticated = UserController.hasBearerToken();
      return isAuthenticated;
    };

    if (!isAuthenticated()) {
      // Se l'utente non è autenticato, apri il modal di autenticazione
      openAuthModal();
    } else {
      setIsAuthenticated(true);
    }

    setIsCheckingAuth(false);
  }, [user]); // Dipendenze useEffect, rieffettua il controllo quando `user` cambia

  if (isCheckingAuth) {
    // Renderizza un loader o un componente di attesa qui
    return <div>Caricamento...</div>;
  }

  if (!isAuthenticated) {
    console.log("Navigate to /", isCheckingAuth, user);
    // Se l'utente non è autenticato dopo il controllo, reindirizza
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default RequiresAuth;
