import axios from "axios";
import { iPagination, iPaginationProps } from "../models/iPagination";

export default class SupportController {
  static async sendSupportForm(payload: any) {
    const response = await axios.post("support-form", { payload });
    return response.data;
  }

  static async getCategoriesPaginated(
    params: iPaginationProps
  ): Promise<iPagination<any>> {
    const url = "/supportfaqs/categories";

    const response = await axios.get(url, {
      params,
    });
    return response.data;
  }
}
