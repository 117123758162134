import { useEffect, useState } from "react";

interface Props {
  element: any;
  delay?: number;
  direction?: "top" | "bottom" | "left" | "right";
  automatic?: boolean;
  className?: string;
  style?: any;
}

export default function Animate({
  element,
  delay = 0,
  automatic,
  className,
  style,
}: // direction = "bottom",
Props) {
  const [id] = useState(String(Math.random()));
  const [init, setInit] = useState(false);

  // const dict = {
  //   top: "translateY(200px)",
  //   bottom: "translateY(-200px)",
  //   left: "translateX(-200px)",
  //   right: "translateX(200px)",
  // };

  const [txStyle, setTxStyle] = useState<any>({
    // transform: dict[direction],
    opacity: 0,
  });

  useEffect(() => {
    if (init) return;

    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    window.addEventListener("scroll", () => {
      const domElement = document.getElementById(id);
      if (!domElement) return;

      const elementPosition = domElement.getBoundingClientRect().top;

      if (height > elementPosition) {
        setTxStyle({});
      }
    });

    if (automatic) {
      setTxStyle({});
    }

    setInit(true);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        ...txStyle,
        transitionDuration: "1s",
        transitionDelay: `${delay + 0.2}s`,
        ...style,
      }}
      id={id}
      className={className}
    >
      {element}
    </div>
  );
}
