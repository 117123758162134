import Icon from "../Icon/Icon";

interface Props {
  steps: number;
  step: number;
}

export default function Stepper({ step, steps }: Props) {
  return (
    <div className="custom-stepper">
      {Array(steps)
        .fill("")
        .map((_, key) => {
          const prev = key < step - 1;
          const curr = key === step - 1;
          return (
            <>
              <div
                key={"step_" + key}
                className={`
                stepper-dot 
                ${curr ? "current" : ""}
                ${prev ? "previous" : ""}
              `}
              >
                {prev ? <Icon size={15} icon="check" className="white-color" /> : null}
              </div>
              {key < steps - 1 ? (
                <div className={`stepper-line ${prev ? "previous" : ""}`}></div>
              ) : null}
            </>
          );
        })}
    </div>
  );
}
