import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";

import { mapStyle } from "../../../../_App/components/Partners/Partners";
import iCollection from "../../../models/iCollection";

export interface collectionMapProps {
  collection: iCollection;
}

export default function CollectionMap({ collection }: collectionMapProps) {
  let placeId = collection.census?.location?.place_id;

  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const coordExist = collection.census?.location?.coordinates;

  const initCordinates = async () => {
    setCenter(collection.census?.location?.coordinates as any);
  };

  useEffect(() => {
    if (coordExist) initCordinates();
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_API_KEY),
    libraries: ["places"],
  });

  if (!coordExist) {
    return <div />;
  }

  let marker = {
    lat: collection.census?.location?.coordinates.lat,
    lng: collection.census?.location?.coordinates.lng,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="h100 w100"
      center={center}
      zoom={10}
      options={{
        styles: mapStyle as any,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true,
        gestureHandling: "cooperative",
        mapTypeControl: true,
      }}
    >
      <Marker position={{ lat: marker.lat, lng: marker.lng }} />
    </GoogleMap>
  ) : (
    <></>
  );
}
