import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserController from "../../../../assets/Controllers/UserController";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import Skeleton from "react-loading-skeleton";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import iCredits from "../../../../assets/models/iCredit";
import { datetimeToString } from "../../../../assets/Utils/generic";
import { useNavigate } from "react-router-dom";
import helpers from "../../../helpers/helpers";
import { openModal } from "../../../../assets/Utils/modal";
import PromoCode from "./PromoCode";

function CreditsBox({ credits }: { credits: iCredits }) {
  const expired = new Date(credits.expiresAt) <= new Date();
  const { t } = useTranslation();

  return (
    <div
      className="d-flex gap-3 border rounded align-items-center"
      style={{ padding: "20px 32px", opacity: expired ? 0.7 : 1 }}
    >
      <div>
        <p className="m-0 bodytext-sm regular dark-grey-color">
          {t("wallet.credits.quantity")}
        </p>
        <p className="m-0 bodytext medium">€{credits.amount.toFixed(2)}</p>
      </div>

      <div
        style={{
          margin: "0 32px",
          alignSelf: "stretch",
          justifySelf: "stretch",
        }}
        className="d-flex border only-desktop"
      ></div>

      <div
        style={{
          margin: "0 16px",
          alignSelf: "stretch",
          justifySelf: "stretch",
        }}
        className="d-flex border only-mobile"
      ></div>

      <div className="w100 d-flex flex-column">
        <div>
          <p className="m-0 bodytext-sm regular dark-grey-color">
            {t("wallet.credits.usedamount")}
          </p>
          <p className="m-0 bodytext medium text-nowrap">
            €{credits.usedAmount.toFixed(2)} {t("wallet.credits.of")} €
            {credits.amount.toFixed(2)}
          </p>
          <progress
            className="inverted"
            value={credits.usedAmount}
            max={credits.amount}
          ></progress>
        </div>
        <div className="w100 d-flex flex-column">
          <p className="m-0 bodytext-sm regular dark-grey-color">
            {t("wallet.credits.expiredate")}
          </p>
          <p className="m-0 bodytext medium text-nowrap">
            {datetimeToString(credits.expiresAt)}
          </p>
        </div>
      </div>
    </div>
  );
}
export default function Credits() {
  let navigate = useNavigate();

  const [creditsActive, setCreditsActive] = useState<iCredits[]>([]);
  const [creditsNotActive, setCreditsNotActive] = useState<iCredits[]>([]);

  const [creditsBalance, setCreditsBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [viewAll, setViewAll] = useState(false);

  const { t } = useTranslation();

  const loadCredits = async () => {
    setIsLoading(true);
    try {
      const data = await UserController.getCreditsPaginated({
        page: 1,
        size: 500,
      });

      setCreditsActive(
        data.data.filter(
          (c) => new Date(c.expiresAt) > new Date() && c.usedAmount < c.amount
        )
      );
      setCreditsNotActive(
        data.data.filter(
          (c) => new Date(c.expiresAt) <= new Date() || c.usedAmount >= c.amount
        )
      );

      const balance = await UserController.getCreditsBalance();
      setCreditsBalance(balance);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCredits();
  }, []);

  const openInviteFriendPage = () => {
    // openWalletPage({ id: "InviteFriendPage", content: <InviteFriendPage /> });
  };
  const addPromo = () => {
    openModal({ content: <PromoCode /> });
  };

  if (isLoading)
    return (
      <section className="p-3">
        <Skeleton />
        <div className="height-12"></div>
        <Skeleton count={2} height={100} />
        <div className="height-12"></div>
        <Skeleton width={150} height={25} />
      </section>
    );

  return (
    <section className="p-3">
      <>
        <div
          key={"bankAccount_" + Math.random()}
          style={{ padding: "24px", margin: "6px 0" }}
          className="d-flex justify-content-between align-items-end border rounded"
        >
          <div>{t("wallet.credits.total")}:</div>
          <div className="m-0 h3 medium">{helpers.price(creditsBalance)}</div>
        </div>
        <div className="height-16"></div>

        <p className="m-0 bodytext regular dark-grey-color">
          {t("wallet.credits.creditstext")}
        </p>

        <div className="height-8"></div>

        <div className="d-flex flex-column flex-md-row flex-md-wrap gap-3">
          <Button
            className=""
            onClick={addPromo}
            text={t("wallet.credits.addpromo")}
            variant="secondary"
          />
        </div>

        {creditsActive.length ? (
          <>
            <div
              className="horizontal-divider"
              style={{ margin: "24px 0" }}
            ></div>

            <div className="d-flex justify-content-end">
              <Button
                text={`${
                  viewAll
                    ? t("wallet.credits.hidedetails")
                    : t("wallet.credits.viewdetails")
                }`}
                onClick={() => setViewAll(!viewAll)}
                size="sm"
                variant="secondary"
              />
            </div>
          </>
        ) : null}

        {viewAll ? (
          <>
            {creditsActive.length ? (
              <>
                <p className="m-0 bodytext medium">
                  {t("wallet.credits.activecredits")}
                </p>
                <div className="height-12"></div>

                <div className="d-flex flex-column" style={{ gap: "12px" }}>
                  {creditsActive.map((c, key) => {
                    return (
                      <CreditsBox key={"credits_active_" + key} credits={c} />
                    );
                  })}
                </div>
              </>
            ) : null}

            {creditsNotActive.length ? (
              <>
                <div
                  className="horizontal-divider"
                  style={{ margin: "24px 0" }}
                ></div>

                <p className="m-0 bodytext medium">
                  {t("wallet.credits.pastcredits")}
                </p>
                <div className="height-12"></div>

                <div className="d-flex flex-column" style={{ gap: "12px" }}>
                  {creditsNotActive.map((c, key) => {
                    return (
                      <CreditsBox
                        key={"credits_not_active_" + key}
                        credits={c}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </>
    </section>
  );
}
