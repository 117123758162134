import { useRef } from "react";
import BecomePartner from "../../components/Body/BecomePartner/BecomePartner";
import NexiHead from "./Sub/NexiHead";

import { useTranslation } from "react-i18next";
import WebStepper from "../../components/WebStepper/WebStepper";

export default function NexiCs() {
  const { t } = useTranslation();

  const buttonRef = useRef<HTMLElement>(null);

  return (
    <main id="web-page">
      <section>
        <NexiHead
          text={t("nexi.cs.text")}
          btntext={t("nexi.btntext")}
          buttonRef={buttonRef}
        />
      </section>

      <section className="web-section-container">
        <div className="web-section-content align-items-center text-center text-md-start">
          <div className="height-100"></div>

          <span className="h3 regular">{t("nexi.cs.title")}</span>

          <div className="height-48"></div>

          <div className="w100 text-justify">
            <div>
              <span className="dark-grey-color">{t("nexi.cs.1")}</span>
            </div>

            <div className="">
              <span className="medium">{t("nexi.cs.2")}</span>
              <span className="dark-grey-color">{t("nexi.cs.3")}</span>
              <span className="medium">{t("nexi.cs.4")}</span>
              <span className="dark-grey-color">{t("nexi.cs.5")}</span>
            </div>

            <div className="height-24"></div>

            <div>
              <span className="dark-grey-color">{t("nexi.cs.6")}</span>
            </div>

            <div className="height-24"></div>

            <div>
              <span className="dark-grey-color">{t("nexi.cs.7")}</span>
            </div>
          </div>

          <div className="height-48"></div>

          <div>
            <span className="medium">{t("nexi.cs.8")}</span>
            <span className="dark-grey-color">{t("nexi.cs.9")}</span>
          </div>

          <div className="height-100"></div>
        </div>
      </section>

      <hr />

      <WebStepper
        text={[
          t("nexi.cs.rate_1"),
          t("nexi.cs.rate_2"),
          t("nexi.cs.rate_3"),
          t("nexi.cs.rate_4"),
          t("nexi.cs.rate_5"),
          t("nexi.cs.rate_6"),
          t("nexi.cs.rate_7"),
          t("nexi.cs.rate_8"),
        ]}
      />

      <section ref={buttonRef} className="light-grey-background w100">
        <BecomePartner title={t("forhotels.section_1.4")} />
      </section>
    </main>
  );
}
