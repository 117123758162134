import { useEffect, useState } from "react";
import store from "../../redux/redux";
import {
  setRefreshSwipePage,
  setRefreshSwipes,
} from "../../redux/slices/appSlice";
import { useNavigate } from "react-router-dom";

export function refreshSwipes() {
  store.dispatch(setRefreshSwipes(Math.random()));
}

export function refreshSwipePage() {
  store.dispatch(setRefreshSwipePage(Math.random()));
}

interface RedirectProps {
  to: string;
}
export function Redirect({ to }: RedirectProps) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);
  return <></>;
}

//Force update hook
export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}
