import { useEffect, useState } from "react";
import { selectSearch, setSearch } from "../../../redux/slices/appSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/redux";
import Chip from "../../../assets/TakyonDesignSystem/components/Chip/Chip";
import NftController from "../../../assets/Controllers/NftController";
import { iNft } from "../../../assets/models/iNft";
import Partner from "../../../assets/TakyonDesignSystem/components/Partner/Partner";
import Skeleton from "react-loading-skeleton";
import ScrollContainer from "../../../assets/TakyonDesignSystem/components/ScrollContainer/ScrollContainer";
import { useTranslation } from "react-i18next";
import { TakCarousel } from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import { useNavigate } from "react-router-dom";
import {
  datetimeToString,
  LARGE_SCREEN,
  LARGE_SHORT_SCREEN,
  SMALL_SCREEN,
} from "../../../assets/Utils/generic";
import { openModal } from "../../../assets/Utils/modal";
import StayRequest from "../../../assets/TakyonDesignSystem/components/StayRequest/StayRequest";
import { useMediaQuery } from "react-responsive";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";

export const OursHotelPartnerModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
        <span className="bodytext medium">{t("stay.modal.modaltitle")}</span>
      </div>
      <div className="modal-body">
        <div className="modal-padding py-32 border-bottom">
          <p className="h4 medium">{t("stay.modal.title")}</p>
          <div className="py-3">
            <div className="d-flex align-items-center gap-2">
              <Icon icon="check"></Icon>
              <p className="bodytext medium">{t("stay.modal.1")}</p>
            </div>
            <p className="bodytext light text-dark-grey mt-1">
              <span
                dangerouslySetInnerHTML={{ __html: t("stay.modal.11") }}
              ></span>
            </p>
            <div className="d-flex align-items-center gap-2 mt-4">
              <Icon icon="check"></Icon>
              <p className="bodytext medium">{t("stay.modal.2")}</p>
            </div>
            <p className="bodytext light text-dark-grey mt-1">
              <span
                dangerouslySetInnerHTML={{ __html: t("stay.modal.21") }}
              ></span>
            </p>
          </div>
        </div>
        <div className="px-4 py-32">
          <p className="h4 medium">{t("stay.modal.3")}</p>
          <p className="bodytext light text-dark-grey mt-1">
            <span
              dangerouslySetInnerHTML={{ __html: t("stay.modal.31") }}
            ></span>
          </p>
        </div>
      </div>
    </>
  );
};

export default function Stays() {
  const searchCriteria = useSelector(selectSearch);

  const dispatch = useAppDispatch();
  const [nfts, setNfts] = useState<iNft[]>();
  const [partners, setPartners] = useState<any[]>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isMediumScreen = useMediaQuery({ query: LARGE_SHORT_SCREEN });
  const isLargeScreen = useMediaQuery({ query: LARGE_SCREEN });

  const getPaddingValue = () => {
    if (isSmallScreen) return "24px";
    if (isMediumScreen) return "24px";
    if (isLargeScreen) return "45px";
  };

  const paddingValue = getPaddingValue();

  let filterDate: string;

  const removeItem = (objectPropertyName: string) => {
    const { [objectPropertyName]: extractedProperty, ...rest } =
      searchCriteria as any;
    dispatch(setSearch({ ...rest }));
  };

  const removeItemFromArray = (id: number, objectPropertyName: string) => {
    const newObj = (searchCriteria as any)[objectPropertyName]?.filter(
      (_: any, i: any) => i !== id
    );
    if (newObj.length === 0) {
      const { [objectPropertyName]: extractedProperty, ...rest } =
        searchCriteria as any;
      dispatch(setSearch({ ...rest }));
      return;
    }

    dispatch(setSearch({ ...searchCriteria, [objectPropertyName]: newObj }));
  };

  function filters() {
    const chips = [];
    if (searchCriteria.locationType) {
      chips.push(
        searchCriteria.locationType?.map((locType: string, index) => (
          <Chip
            key={index}
            label={t(`search.${locType}`)}
            onDelete={() => removeItemFromArray(index, "locationType")}
          ></Chip>
        ))
      );
    }

    if (searchCriteria.location) {
      chips.push(
        searchCriteria.location?.name && (
          <Chip
            label={searchCriteria.location.name}
            onDelete={() => removeItem("location")}
          ></Chip>
        )
      );
    }

    if (searchCriteria.guests && searchCriteria.guests.length > 0) {
      chips.push(
        searchCriteria.guests?.map((guest: any, index) => {
          const label =
            guest.min === guest.max
              ? `${guest.min} ${t("search.guest")}`
              : `${guest.min} - ${guest.max} ${t("search.guests")}`;

          return (
            <Chip
              key={index}
              label={label}
              onDelete={() => removeItemFromArray(index, "guests")}
            ></Chip>
          );
        })
      );
    }

    if (searchCriteria.dates && searchCriteria.dates.length > 0) {
      chips.push(
        searchCriteria.dates?.map((data: any, index) => {
          const fromDate = new Date(data.from);
          const label = `${fromDate.getDate()} - ${datetimeToString(data.to)}`;
          filterDate = label;

          return (
            <Chip
              key={index}
              label={label}
              onDelete={() => removeItemFromArray(index, "dates")}
            ></Chip>
          );
        })
      );
    }

    if (searchCriteria.daysType) {
      chips.push(
        searchCriteria?.daysType && (
          <Chip
            label={t(`search.${searchCriteria?.daysType}`)}
            onDelete={() => removeItem("daysType")}
          ></Chip>
        )
      );
    }

    if (searchCriteria.prices && searchCriteria.prices.length > 0) {
      chips.push(
        searchCriteria?.prices?.map((price: any, index) => {
          const label =
            price.min === price.max
              ? `€ ${price.min}`
              : `€ ${price.min} - € ${price.max}`;

          return (
            <Chip
              key={index}
              label={label}
              onDelete={() => removeItemFromArray(index, "prices")}
            ></Chip>
          );
        })
      );
    }

    return chips;
  }

  useEffect(() => {
    setIsLoading(true);
    updateFilter();

    if (Object.keys(searchCriteria).length === 0) {
      navigate("/");
    }
  }, [searchCriteria]);

  async function updateFilter() {
    //console.log("searchCriteria", searchCriteria);
    try {
      const _data = await NftController.searchNft(searchCriteria);
      setNfts(_data.nfts);
      setPartners(_data.collections);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <main style={{ marginTop: "60px" }}>
      <section
        className="w-100 black-background position-relative"
        style={{ minHeight: "100vh" }}
      >
        <div className="container w100 d-flex flex-column pb-2 pt-3 margin-menu text-white">
          <div className="content">
            <ScrollContainer
              isLightTheme={true}
              content={
                <div
                  className="d-flex gap-3 margin-menu"
                  style={{ fill: "#fff" }}
                >
                  {filters()}
                  <a
                    style={{ lineHeight: "32px" }}
                    className="mb-0 linktext text-nowrap text-white"
                    target="_blank"
                    onClick={() => dispatch(setSearch({}))}
                  >
                    {t("stay.delete_all")}
                  </a>
                </div>
              }
            ></ScrollContainer>
            <div className="w-100 text-white mt-5 mb-5">
              <h2 className="h1 regular mb-4">{t("search.tak_results")}</h2>

              {isLoading ? (
                <>
                  <TakCarousel isLoading={true} nfts={[]} />
                </>
              ) : null}

              {!isLoading && nfts?.length ? (
                <>
                  <TakCarousel nfts={nfts} />
                </>
              ) : null}

              {!isLoading && !nfts?.length ? (
                <>
                  <div
                    className="p-2"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}
                  >
                    <p>{t("search.no_tak")}</p>
                  </div>
                </>
              ) : null}
            </div>
            <div className="w-100 text-white mt-5 mb-5">
              <h2 className="h1 regular mb-4">
                {t("search.partners")}{" "}
                <span
                  className="text-underline cursor-pointer"
                  onClick={() =>
                    openModal({
                      content: <OursHotelPartnerModal />,
                    })
                  }
                >
                  Hotel partner
                </span>
              </h2>
              {!isLoading ? (
                <>
                  {partners && partners.length > 0 ? (
                    partners.map((item, key) => (
                      <Partner
                        key={key}
                        partnerDatas={item}
                        month={filterDate}
                      />
                    ))
                  ) : (
                    <div
                      className=" p-2"
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}
                    >
                      <p>{t("search.no_partner")}</p>
                    </div>
                  )}
                  <div style={{ marginTop: "100px" }}>
                    <StayRequest></StayRequest>
                  </div>
                </>
              ) : (
                <Skeleton
                  style={{ width: "100%", margin: "10px 0", opacity: "0.5" }}
                  count={10}
                  height={100}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
