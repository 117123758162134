import { useEffect, useState } from "react";
import ReferralController from "../../../../assets/Controllers/ReferralController";
import { fireTagManagerEvent } from "../../../../assets/Services/tagmanager";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Form } from "react-bootstrap";
import Input from "../../../../assets/TakyonDesignSystem/components/Input/Input";
import { openModal } from "../../../../assets/Utils/modal";
import Icon from "../../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Share from "../../../../assets/TakyonDesignSystem/components/Share/Share";
import getLink, { LINK_TYPE } from "../../../../config/Links";
import iReferralCode from "../../../../assets/models/iReferral";
import { selectUser } from "../../../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";
import { useTranslation } from "react-i18next";

interface iStepProps {
  title: string;
  text: string | JSX.Element;
}

export default function InviteFriend() {
  const [referral, setReferral] = useState<iReferralCode>();
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [link, setLink] = useState("");
  const user = useSelector(selectUser);
  const [accordionOpen, setAccordionOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const { t } = useTranslation();

  const steps: iStepProps[] = [
    {
      title: t("wallet.credits.step1title"),
      text: (
        <div className="text-wrap">
          <span>{t("wallet.credits.step1text1")}</span>{" "}
          <span className="black-color">{t("wallet.credits.step1text2")}</span>{" "}
          <span>{t("wallet.credits.step1text3")}</span>{" "}
          <span className="black-color">{t("wallet.credits.step1text4")}</span>{" "}
          <span>{t("wallet.credits.step1text5")}</span>
        </div>
      ),
    },
    {
      title: t("wallet.credits.step2title"),
      text: t("wallet.credits.step2text"),
    },
    {
      title: t("wallet.credits.step3title"),
      text: t("wallet.credits.step3text"),
    },
  ];

  const loadReferral = async () => {
    setIsLoading(true);
    try {
      const data = await ReferralController.getReferralCode();
      setReferral(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const createCode = async () => {
    setIsLoading(true);
    try {
      await ReferralController.createReferralCode();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    loadReferral();
  };

  const copy = () => {
    try {
      const copyText = document.getElementById(
        "referral-link-input"
      ) as HTMLInputElement;
      copyText?.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);
      setCopied(true);

      fireTagManagerEvent("invite_friend", { mode: "copy" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadReferral();
  }, []);

  useEffect(() => {
    if (referral)
      setLink(
        getLink(LINK_TYPE.REFERRAL, "EXCHANGE_FULL", {
          __CODE__: referral.code,
        })
      );
  }, [referral]);

  const toggleAccordion = (index: number) => {
    setAccordionOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <section className="p-3">
      <div className="height-16"></div>
      {!referral ? (
        <Button
          loading={isLoading}
          onClick={createCode}
          text="Create referral code"
        />
      ) : null}

      {referral ? (
        <>
          <div className="d-flex flex-column flex-md-row align-items-center gap-4">
            <div style={{ width: 155, height: 155, minWidth: "155px" }}>
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  trailColor: "#fff",
                  pathColor: "#b6fca0",
                  strokeLinecap: "square",
                })}
                value={referral.timesUsed + 0.01}
                maxValue={referral.maxUses}
                strokeWidth={10}
              >
                <p className="m-0 h4 medium text-nowrap">{`${referral.timesUsed}/${referral.maxUses}`}</p>
                <div className="height-4"></div>
                <p className="m-0 bodytext-xs regular text-nowrap">
                  {t("wallet.credits.invitedfriends")}
                </p>
                <div className="height-8"></div>

                {/* {referral.timesUsed ? (
                  <p className="m-0 bodytext-sm regular text-nowrap">
                    €{referral.timesUsed * referral.amountForOwner}/€
                    {referral.maxUses * referral.amountForOwner}
                  </p>
                ) : null} */}
              </CircularProgressbarWithChildren>
            </div>
            <div className="d-flex flex-column w100">
              <p className="bodytext-lg m-0 medium">
                {t("wallet.credits.invitefriendsubtitle")}
              </p>
              <p className="bodytext m-0 light dark-grey-color">
                {t("wallet.credits.invitefriendtext")}
              </p>
              <div className="height-16"></div>
            </div>
          </div>
          <div className="height-16 only-desktop"></div>
          <div className="d-flex align-items-start gap-3">
            <Form.Group className="mb-3 w100" controlId="formBasicEmail">
              <Input
                id="referral-link-input"
                readOnly
                status={copied ? "success" : "normal"}
                value={link}
                className="cursor-pointer"
                onClick={copy}
              />
              <Form.Text className="text-muted bodytext-xs">
                {copied
                  ? t("wallet.credits.copied")
                  : t("wallet.credits.clickcopy")}
              </Form.Text>
            </Form.Group>

            <Button
              size="sm"
              onClick={() => {
                fireTagManagerEvent("click_share", {
                  item_id: referral.code,
                  side: "front",
                  auth: user ? true : false,
                });

                openModal({
                  content: (
                    <Share
                      id={referral.code}
                      link={getLink(LINK_TYPE.REFERRAL, "EXCHANGE_FULL", {
                        __CODE__: referral.code,
                      })}
                    />
                  ),
                });
              }}
              icon="ios_share"
              text={isSmallScreen ? undefined : t("app.nft.share")}
            />
          </div>
          <hr />
          <div>
            <div
              onClick={() => toggleAccordion(1)}
              className="cursor-pointer medium bodytext d-flex align-items-start justify-content-between"
            >
              <span
                className={
                  isSmallScreen ? `bodytext-lg medium` : "bodytext medium"
                }
              >
                {t("wallet.credits.howitworks")}
              </span>
              <span>
                <Icon
                  icon={`${accordionOpen[1] ? "expand_less" : "expand_more"}`}
                />
              </span>
            </div>
            <div className="height-12"></div>
            <div
              className={`${
                accordionOpen[1] ? "" : "d-none"
              } white-background p-3`}
            >
              <div className="d-flex flex-column gap-4">
                {}
                {steps.map((step, index) => (
                  <div key={index} className="d-flex flex-column gap-1">
                    <div className="bodytext-sm d-flex align-items-center justify-content-center primary-background width-25 height-25 rounded">
                      {index + 1}
                    </div>
                    <span className="bodytext medium">{step.title}</span>
                    <div className="bodytext light dark-grey-color d-flex flex-wrap">
                      {step.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
}
