import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import Input from "../../../../../assets/TakyonDesignSystem/components/Input/Input";
import { iNft } from "../../../../../assets/models/iNft";
import { Form } from "react-bootstrap";
import { openModal } from "../../../../../assets/Utils/modal";
import NftController from "../../../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import { validate } from "email-validator";
import _ProfileSectionLayout from "../../_ProfileSectionLayout";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";

export default function TakTransferGift() {
  // { nft }: { nft: iNft }
  let location = useLocation();
  const nft: iNft = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const transfer = async () => {
    setIsLoading(true);
    try {
      await NftController.transferNft({
        nftId: nft._id,
        email: email,
      });

      fireTagManagerEvent("transfer", { item_id: nft._id });

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={t("wallet.bookings.transfersuccesstext")}
              continueClickCallback={() => navigate("/profile/wallet")}
              continueBtnText={t("wallet.bookings.viewtak")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_ProfileSectionLayout nft={nft} title={t("wallet.bookings.transfertitle")}>
      <div>
        <Form.Group className="w100">
          <Form.Label>{t("wallet.bookings.email")}</Form.Label>
          <Input
            status={validate(email) ? "success" : "normal"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="name@email.com"
          />
        </Form.Group>
      </div>

      <div className="height-32"></div>
      <div className="d-flex justify-content-end w100">
        <Button
          loading={isLoading}
          text={t("wallet.bookings.transfer")}
          disabled={!validate(email)}
          onClick={() => {
            if (validate(email)) transfer();
          }}
        />
      </div>
    </_ProfileSectionLayout>
  );
}
