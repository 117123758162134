import { useTranslation } from "react-i18next";
import timg from "../../../assets/img/hub/T.svg";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";

import logoLight from "../../../assets/TakyonDesignSystem/images/TakyonLight.svg";

import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../config/Links";
// import { useMediaQuery } from "react-responsive";
// import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";

import video from "../../../assets/video/video.mp4";

export function HubLoader() {
  return (
    <div className="hub-loader-container">
      <div className="hub-loader">
        <div className="loader-takyon">
          <img className="loader-takyon-img" src={logoLight} alt="" />
          <div className="loader-t">
            <img className="loader-t-img" src={timg} alt="" />
          </div>
          <div className="green-dot"></div>
        </div>
      </div>
    </div>
  );
}

export default function Hub() {
  const { t } = useTranslation();
  // const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const navigate = useNavigate();

  return (
    <main
      id="hub"
      style={{ width: "100vw", height: "100dvh" }}
      className="black-background p-3 position-relative"
    >
      {/* <HubLoader /> */}

      <video
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          objectFit: "cover",
          zIndex: 1,
        }}
        src={video}
        className="position-absolute h100 w100"
        muted
        autoPlay
        playsInline
        loop
      ></video>

      <div
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 2,
          opacity: 0.6,
        }}
        className="position-absolute h100 w100 black-background"
      ></div>

      <hgroup style={{ zIndex: 10 }} className="text-center">
        <h1 className="white-color h1-xlarge px-0 px-md-5">
          {/* <span className="medium">
            <i>{t("hub.11")}</i>{" "}
            <i className="position-relative">
              {/* <div
                style={{
                  top: isSmallScreen ? "10px" : "20px",
                  bottom: isSmallScreen ? "10px" : "20px",
                  left: "-10px",
                  right: "-10px",
                  zIndex: 1,
                  borderRadius: isSmallScreen ? "55px" : "85px",
                }}
                className="position-absolute primary-background"
              ></div>
              <span className="position-relative" style={{ zIndex: 12 }}>
                {t("hub.12")}
              </span>
            </i>
          </span>
          <br /> */}
          {/* <i className="light">{t("hub.6")}</i>
          <br /> */}
          <span className="light">{t("hub.7")}</span>
        </h1>
        {/* <div className="height-30"></div>
        <p className="m-0 bodytext-lg regular light-grey-color">{t("hub.3")}</p>
        <p className="m-0 bodytext-lg regular light-grey-color">{t("hub.4")}</p> */}
      </hgroup>

      <div className="height-60"></div>

      <Button
        onClick={() => {
          window.open(
            getLink(LINK_TYPE.APP_EXPLORE, "EXCHANGE_FULL"),
            "_blank"
          );

          fireTagManagerEvent("navigate_app", { index: 0 });
        }}
        text={t("hub.5")}
        size="lg"
        style={{
          zIndex: 10,
        }}
        icon="north_east"
      />

      <div className="height-60"></div>

      <p style={{ zIndex: 10 }} className="m-0 bodytext light light-grey-color">
        <i>
          {t("hub.3")}{" "}
          <u
            className="cursor-pointer primary-color"
            onClick={() => navigate(getLink(LINK_TYPE.FORHOTELS))}
          >
            {t("hub.4")}
          </u>
        </i>
      </p>
    </main>
  );
}
