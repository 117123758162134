import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import { useEffect, useRef, useState } from "react";
import { iPartner } from "../../../assets/models/iPartner";
import { apiErrorToast } from "../../../assets/Utils/errors";
import PartnerController from "../../../assets/Controllers/PartnerController";
import PartnerCard from "../../../assets/TakyonDesignSystem/components/PartnerCard/PartnerCard";
import { debounce } from "lodash";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";

import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";

const defaultCenter = { lat: 42, lng: 10 };
const defaultZoom = 5.5;
const LIBRARIES = ["places"];

export const mapStyle = [
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: -35,
      },
    ],
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        lightness: 15,
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        color: "#fcefc8",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        color: "#ccd6e9",
      },
      {
        saturation: 35,
      },
      {
        lightness: 15,
      },
    ],
  },
];

export default function Partners() {
  const navigate = useNavigate();

  const [partners, setPartners] = useState<iPartner[]>([]);
  const [, setIsLoading] = useState(false);

  const [filter, setFilter] = useState("");

  const [mode, setMode] = useState<"map" | "list">("list");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_API_KEY),
    libraries: LIBRARIES as any,
  });

  const loadPartners = async (filter: string) => {
    setFilter(filter);
    setIsLoading(true);
    try {
      const data = await PartnerController.getPaginated(
        {
          page: 1,
          size: 500,
          query: JSON.stringify({
            $or: [
              { name: { $regex: filter, $options: "i" } },
              { "location.label": { $regex: filter, $options: "i" } },
            ],
          }),
        },
        true
      );

      setPartners(data.data);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPartners(filter);
  }, []);

  const searchCallbackDebounced = useRef(debounce(loadPartners, 500));

  return (
    <section className="position-relative h100 d-flex flex-column">
      <div className="p-4">
        <a onClick={() => navigate(-1)}>
          <u>Go back</u>
        </a>
        <div className="height-16"></div>
        <p className="m-0 h3 medium">Hotel partner</p>
      </div>

      {mode === "list" ? (
        <>
          <Form.Group style={{ padding: "0px 26px" }}>
            <Input
              onChange={(e) => {
                setIsLoading(true);
                searchCallbackDebounced.current(e.target.value);
              }}
              icon="search"
              type="text"
              placeholder="Search hotel..."
            />
          </Form.Group>

          <div className="height-40"></div>

          <div
            className="d-flex flex-wrap gap-3"
            style={{
              overflow: "scroll",
              padding: "0px 26px",
              paddingBottom: "75px",
            }}
          >
            {partners.length === 0 ? (
              <p>There are no results for "{filter}"</p>
            ) : null}

            {partners.map((partner, key) => {
              return <PartnerCard key={key} partner={partner} partnerPage />;
            })}
          </div>
        </>
      ) : null}

      {mode === "map" && isLoaded ? (
        <div className="d-flex h100 w100 position-relative">
          <Form.Group
            className="position-absolute"
            style={{
              maxWidth: "400px",
              width: "100%",
              padding: "0px 52px",
              zIndex: 100,
              top: "24px",
            }}
          >
            <Input
              onChange={(e) => {
                setIsLoading(true);
                searchCallbackDebounced.current(e.target.value);
              }}
              icon="search"
              type="text"
              placeholder="Search hotel..."
            />
          </Form.Group>

          <GoogleMap
            mapContainerClassName="w100 h100"
            center={defaultCenter}
            zoom={defaultZoom}
            options={{
              styles: mapStyle as any,
              zoomControl: true,
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
            }}
          >
            <MarkerClusterer
              options={{
                maxZoom: 9,
                styles: [
                  //   {
                  //     url: clusterImg, // Use custom cluster icon
                  //     height: 35,
                  //     width: 35,
                  //     textColor: "#726eff",
                  //     textSize: 15,
                  //   },
                ],
              }}
            >
              {(clusterer) =>
                partners?.map((partner, key) => (
                  <Marker
                    // onClick={() => {
                    //   openInfoWindow(partner);
                    //   centerOnPartner(partner, false);
                    // }}
                    key={"partner_" + key}
                    position={partner?.location?.coordinates as any}
                    // icon={{
                    //   url: props.icon ? props.icon : markerImg,
                    //   scaledSize: new window.google.maps.Size(45, 45),
                    // }}
                    clusterer={clusterer}
                  />
                )) as any
              }
            </MarkerClusterer>
          </GoogleMap>
        </div>
      ) : null}

      <div
        className="position-absolute w100 d-flex justify-content-center"
        style={{
          bottom: "24px",
          left: 0,
        }}
      >
        {mode === "list" ? (
          <Button icon="map" onClick={() => setMode("map")} text="View map" />
        ) : null}

        {mode === "map" ? (
          <Button
            icon="list"
            onClick={() => setMode("list")}
            text="View list"
          />
        ) : null}
      </div>
    </section>
  );
}
