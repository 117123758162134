import { Form } from "react-bootstrap";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import FavoritesController from "../../../assets/Controllers/FavoritesController";

interface Props {
  nftId: string;
  userId: string;
  onCreated: Function;
}

export default function NewFavoriteList({ nftId, userId, onCreated }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [listName, setListName] = useState("");

  const submit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // call api to create favourite list
      const favList = await FavoritesController.createList(
        listName,
        userId,
        nftId
      );
      console.log("NewFavoriteList - onCreated", favList);
      onCreated(favList);
    } catch (error) {
      // TODO
    }
    setIsLoading(false);
  };

  return (
    <div className="add-favourite h-100">
      <div>
        <div className="height-24"></div>

        <h3 className="h3 medium px-4">{t("favorites.new_list.add")}</h3>
        <div className="p-4 mt-3">
          <div className="horizontal-divider" style={{ margin: "0 0" }}></div>
        </div>
      </div>
      <Form
        onSubmit={submit}
        className="d-flex flex-column justify-content-between"
        style={{ height: "calc(100% - 122px)" }}
      >
        <Form.Group className="px-4">
          <Form.Label>{t("favorites.modal.form.list_label")}</Form.Label>

          <Input
            required
            type="text"
            name="email"
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            placeholder={t("favorites.modal.form.list_placeholder")}
          />
        </Form.Group>

        <div className="d-flex align-items-center justify-content-end py-2 px-3 border-top">
          <Button
            loading={isLoading}
            disabled={!listName}
            size="sm"
            type="submit"
            text={t("favorites.modal.create_btn")}
          ></Button>
        </div>
      </Form>
    </div>
  );
}
