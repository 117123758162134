import { useTranslation } from "react-i18next";
import Credits from "./Credits/Credits";
import InviteFriend from "./Credits/InviteFriend";
import TabLayout from "../Layouts/TabLayout";

export default function ProfileCredits() {
  const { t } = useTranslation();

  const tabs = [
    {
      key: "my_credits",
      label: t("profile_credits.my_credits.tab_title"),
      component: <Credits />,
    },
    {
      key: "invite_friends",
      label: t("profile_credits.invite_friends.tab_title"),
      component: <InviteFriend />,
    },
  ];

  return (
    <>
      <TabLayout
        title={t("profile_credits.title")}
        tabs={tabs}
        defaultTabKey="my_credits" // Imposta la scheda di default
      />
    </>
  );
}
