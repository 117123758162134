import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiErrorToast } from "../../../Utils/errors";
import Partner from "./Partner";

import { iWallSlot } from "../../../../config/wall";
import Skeleton from "react-loading-skeleton";
import iCollection from "../../../models/iCollection";
import CollectionController from "../../../Controllers/CollectionController";
import { openModal } from "../../../Utils/modal";
import { OursHotelPartnerModal } from "../../../../_Web/pages/Stays/Stays";

interface Props {
  slot?: iWallSlot;
}

export default function PartnerContainer(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [partnerData, setPartnerData] = useState<iCollection[]>([]);

  const loadPayload = async () => {
    setIsLoading(true);
    try {
      const data = await CollectionController.getPaginated({
        page: 1,
        size: (props.slot?.config.payload as any).limit,
        sort: JSON.stringify((props.slot?.config.payload as any).sort),
        query: JSON.stringify((props.slot?.config.payload as any).query),
      });
      setPartnerData(data.data);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPayload();
  }, []);

  return (
    <>
      <div className="text-white mt-5 mb-5">
        <h2 className="h1 regular mb-4">
          {t(props.slot?.title ?? "")}{" "}
          <span
            className="text-underline cursor-pointer"
            onClick={() =>
              openModal({
                content: <OursHotelPartnerModal />,
              })
            }
          >
            {!(props.slot?.config.payload as any).hidePartnerModal ? (
              <>Hotel partner</>
            ) : null}
          </span>
        </h2>

        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
        <div style={{ marginTop: "32px" }}></div>

        {isLoading ? (
          <>
            <Skeleton
              style={{ width: "100%", margin: "10px 0", opacity: "0.5" }}
              count={4}
              height={100}
            />
          </>
        ) : (
          <>
            {partnerData.map((item, key) => (
              <Partner key={key} partnerDatas={item} />
            ))}
          </>
        )}
      </div>
    </>
  );
}
