// To add a new icon, go to https://fonts.google.com/icons?selected=Material+Symbols+Outlined:pets:FILL@0;wght@400;GRAD@0;opsz@24&icon.size=24&icon.color=%23e8eaed
// Set the weight on 200 on the option panel on left
// If you need the filled version switch the fill option so you will have 2 version of the icon, normal and filled
// find the icon you want to add, then click the copy icon on the bottom right of the preview
// create a new item in the array with the code name : "icon-name" ''
// paste the copied svg code then remove the SVG tag

export const iconMap: { [key: string]: string } = {
  radio_button_unchecked:
    '<path d="M480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>',
  radio_button_unchecked_fill:
    '<path d="M480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0 0q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Z"/>',
  explore:
    '<path d="m273.08-273.08 276.15-137.69 137.69-276.15-276.15 137.69-137.69 276.15ZM480-440q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm.07 340q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>',
  explore_fill:
    '<path d="m286.15-286.15 252.31-135.39 135.39-252.31-252.31 135.39-135.39 252.31ZM480-440q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm.13 320q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Z"/>',
  sailing:
    '<path d="M158.46-440 420-816.15V-440H158.46Zm76.08-40H380v-208.85L234.54-480Zm293.15 40q10.46-29.54 21.39-93Q560-596.46 560-660q0-69.69-10.81-132.23-10.81-62.54-20.73-99.31 57.16 27.23 108.04 73.16 50.88 45.92 89.77 105.07 38.88 59.16 63.61 128.73Q814.62-515 819.23-440H527.69Zm52.46-40h194.16q-14.69-98.54-64.73-183.69-50.04-85.16-122.27-143.23 5.84 35.61 9.27 73.88Q600-694.77 600-660q0 45.46-5.65 91.62-5.66 46.15-14.2 88.38ZM360-195.38q-31.38 0-62.38-11.62-31-11.62-57.62-37.62-9.38 9.62-20.88 19.16-11.5 9.54-23.04 14.46-31.93-24.46-54.5-57.58Q119-301.69 106.15-340h747.7q-12.85 38.31-35.43 71.42-22.57 33.12-54.5 57.58-11.54-4.92-23.04-14.46-11.5-9.54-20.88-19.16-26.85 26-57.73 37.62-30.89 11.62-62.27 11.62-31.38 0-62.77-11.62-31.38-11.62-57.23-38.38-25.85 26.76-57.23 38.38-31.39 11.62-62.77 11.62ZM100-60v-40h20q32 0 62.12-10 30.11-10 57.88-27.69 28.54 17.69 58.27 27.57 29.73 9.89 61.73 9.89 32 0 61.62-9.89Q451.23-120 480-137.69q28.54 17.69 58.27 27.57 29.73 9.89 61.73 9.89 32 0 62-9.89 30-9.88 58-27.57Q748.77-120 778.38-110 808-100 840-100h20v40h-20q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q628.69-60 600-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q388.69-60 360-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q148.69-60 120-60h-20Zm280-420Zm200.15 0Z"/>',
  sailing_fill:
    '<path d="M158.46-440 420-816.15V-440H158.46Zm369.23 0q10.46-29.54 21.39-93Q560-596.46 560-660q0-69.69-10.81-132.23-10.81-62.54-20.73-99.31 57.16 27.23 108.04 73.16 50.88 45.92 89.77 105.07 38.88 59.16 63.61 128.73Q814.62-515 819.23-440H527.69ZM360-195.38q-31.38 0-62.38-11.62-31-11.62-57.62-37.62-9.38 9.62-20.88 19.16-11.5 9.54-23.04 14.46-31.93-24.46-54.5-57.58Q119-301.69 106.15-340h747.7q-12.85 38.31-35.43 71.42-22.57 33.12-54.5 57.58-11.54-4.92-23.04-14.46-11.5-9.54-20.88-19.16-26.85 26-57.73 37.62-30.89 11.62-62.27 11.62-31.38 0-62.77-11.62-31.38-11.62-57.23-38.38-25.85 26.76-57.23 38.38-31.39 11.62-62.77 11.62ZM100-60v-40h20q32 0 62.12-10 30.11-10 57.88-27.69 28.54 17.69 58.27 27.57 29.73 9.89 61.73 9.89 32 0 61.62-9.89Q451.23-120 480-137.69q28.54 17.69 58.27 27.57 29.73 9.89 61.73 9.89 32 0 62-9.89 30-9.88 58-27.57Q748.77-120 778.38-110 808-100 840-100h20v40h-20q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q628.69-60 600-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q388.69-60 360-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q148.69-60 120-60h-20Z"/>',
  landscape:
    '<path d="m116.15-280 167.7-224.62L422.31-320h341.54l-200-266-120 159.69L418.46-460l145.39-193.85 280 373.85h-727.7Zm407.93-40Zm-327.93 0h176.16l-88.46-118.54L196.15-320Zm0 0h176.16-176.16Z"/>',
  landscape_fill:
    '<path d="m116.15-280 167.7-224.62L422.31-320h101.77L418.46-460l145.39-193.85 280 373.85h-727.7Z"/>',
  kayaking:
    '<path d="M100-60v-40h20q32 0 61.62-10 29.61-10 58.38-27.69Q268.77-120 298.38-110 328-100 360-100q32 0 62.12-10 30.11-10 57.88-27.69Q508.77-120 538.38-110 568-100 600-100q32 0 62.12-10 30.11-10 57.88-27.69Q747.77-120 777.88-110 808-100 840-100h20v40h-20q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q628.69-60 600-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q388.69-60 360-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q148.69-60 120-60h-20Zm260-135.38q-31.38 0-63.15-11.62T240-244.62q-8.54 8.77-17.12 15.97-8.57 7.19-20.5 12.88-37.92-7.92-76.46-19.46-38.54-11.54-71.3-24.77 49.38-18.38 114.69-36.62 65.31-18.23 131.07-28.69l61.7-186.23Q370-534 391.27-543.08q21.27-9.07 41.35 1.08l111.23 56.62L663-553.08l71.38-159.54-20-53L756.62-860l105.69 47-42.23 94.38-53 20-162.62 365.54q86.08 9.46 164.58 30.7 78.5 21.23 136.34 42.38-37.46 13.77-76.19 25.42-38.73 11.66-72.34 18.81-11.16-5.46-20.12-12.65-8.96-7.2-16.73-16.2-25.85 26-57.23 37.62-31.39 11.62-62.77 11.62-31.38 0-62.77-12-31.38-12-57.23-38-25.85 26-57.23 38-31.39 12-62.77 12Zm206.85-140.85 70.3-158.08-93.3 54.31-81.54-42.15-49.54 146q14.38-1.54 31.96-2.7Q462.31-340 480-340q19.46 0 42 .88 22.54.89 44.85 2.89ZM480-592.31q-27.62 0-47.65-20.04-20.04-20.03-20.04-47.65 0-27.62 20.04-47.65 20.03-20.04 47.65-20.04 27.62 0 47.65 20.04 20.04 20.03 20.04 47.65 0 27.62-20.04 47.65-20.03 20.04-47.65 20.04Z"/>',
  kayaking_fill:
    '<path d="M100-60v-40h20q32 0 61.62-10 29.61-10 58.38-27.69Q268.77-120 298.38-110 328-100 360-100q32 0 62.12-10 30.11-10 57.88-27.69Q508.77-120 538.38-110 568-100 600-100q32 0 62.12-10 30.11-10 57.88-27.69Q747.77-120 777.88-110 808-100 840-100h20v40h-20q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q628.69-60 600-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q388.69-60 360-60q-28.69 0-58.69-7.88-30-7.89-61.31-24.43-31.31 16.54-61.31 24.43Q148.69-60 120-60h-20Zm260-135.38q-31.38 0-63.15-11.62T240-244.62q-8.54 8.77-17.12 15.97-8.57 7.19-20.5 12.88-37.92-7.92-76.46-19.46-38.54-11.54-71.3-24.77 49.38-18.38 114.69-36.62 65.31-18.23 131.07-28.69l61.7-186.23Q370-534 391.27-543.08q21.27-9.07 41.35 1.08l111.23 56.62L663-553.08l71.38-159.54-20-53L756.62-860l105.69 47-42.23 94.38-53 20-162.62 365.54q86.08 9.46 164.58 30.7 78.5 21.23 136.34 42.38-37.46 13.77-76.19 25.42-38.73 11.66-72.34 18.81-11.16-5.46-20.12-12.65-8.96-7.2-16.73-16.2-25.85 26-57.23 37.62-31.39 11.62-62.77 11.62-31.38 0-62.77-12-31.38-12-57.23-38-25.85 26-57.23 38-31.39 12-62.77 12Zm206.85-140.85 70.3-158.08-93.3 54.31-81.54-42.15-49.54 146q14.38-1.54 31.96-2.7Q462.31-340 480-340q19.46 0 42 .88 22.54.89 44.85 2.89ZM480-592.31q-27.62 0-47.65-20.04-20.04-20.03-20.04-47.65 0-27.62 20.04-47.65 20.03-20.04 47.65-20.04 27.62 0 47.65 20.04 20.04 20.03 20.04 47.65 0 27.62-20.04 47.65-20.03 20.04-47.65 20.04Z"/>',
  apartment:
    '<path d="M140-153.85v-520h160v-160h360v320h160v360H540v-160H420v160H140Zm40-40h120v-120H180v120Zm0-160h120v-120H180v120Zm0-160h120v-120H180v120Zm160 160h120v-120H340v120Zm0-160h120v-120H340v120Zm0-160h120v-120H340v120Zm160 320h120v-120H500v120Zm0-160h120v-120H500v120Zm0-160h120v-120H500v120Zm160 480h120v-120H660v120Zm0-160h120v-120H660v120Z"/>',
  apartment_fill:
    '<path d="M140-153.85v-520h160v-160h360v320h160v360H540v-160H420v160H140Zm40-40h120v-120H180v120Zm0-160h120v-120H180v120Zm0-160h120v-120H180v120Zm160 160h120v-120H340v120Zm0-160h120v-120H340v120Zm0-160h120v-120H340v120Zm160 320h120v-120H500v120Zm0-160h120v-120H500v120Zm0-160h120v-120H500v120Zm160 480h120v-120H660v120Zm0-160h120v-120H660v120Z"/>',
  nordic_walking:
    '<path d="m177.69-60 71.54-327.69h35.62L213.31-60h-35.62Zm128.46 0 112-577.85L300-589.08V-460h-40v-155.69l182.77-76q27.46-11.23 50.54-5.58 23.07 5.65 37.77 28.04L570.31-606q26 42 70.11 70.54 44.12 28.54 99.58 33.92v40q-62.15-3.84-116.96-37.88-54.81-34.04-93.04-95.2l-35.54 180.77L580-331.54V-60h-40v-226.92L423.69-398.46 347.08-60h-40.93ZM540-752.31q-27.62 0-47.65-20.04-20.04-20.03-20.04-47.65 0-27.62 20.04-47.65 20.03-20.04 47.65-20.04 27.62 0 47.65 20.04 20.04 20.03 20.04 47.65 0 27.62-20.04 47.65-20.03 20.04-47.65 20.04ZM712.31-60v-327.69h35.38V-60h-35.38Z"/>',
  nordic_walking_fill:
    '<path d="m177.69-60 71.54-327.69h35.62L213.31-60h-35.62Zm128.46 0 112-577.85L300-589.08V-460h-40v-155.69l182.77-76q27.46-11.23 50.54-5.58 23.07 5.65 37.77 28.04L570.31-606q26 42 70.11 70.54 44.12 28.54 99.58 33.92v40q-62.15-3.84-116.96-37.88-54.81-34.04-93.04-95.2l-35.54 180.77L580-331.54V-60h-40v-226.92L423.69-398.46 347.08-60h-40.93ZM540-752.31q-27.62 0-47.65-20.04-20.04-20.03-20.04-47.65 0-27.62 20.04-47.65 20.03-20.04 47.65-20.04 27.62 0 47.65 20.04 20.04 20.03 20.04 47.65 0 27.62-20.04 47.65-20.03 20.04-47.65 20.04ZM712.31-60v-327.69h35.38V-60h-35.38Z"/>',
  account_box:
    '<path d="M480-438.46q54.15 0 92.08-37.92Q610-514.31 610-568.46t-37.92-92.08q-37.93-37.92-92.08-37.92t-92.08 37.92Q350-622.61 350-568.46t37.92 92.08q37.93 37.92 92.08 37.92ZM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31Zm-3.85-60h543.08q4.23-4.15 5.77-18.58 1.54-14.42 2.69-19.73-54-53-125.5-83.5T480-352.31q-83 0-154.5 30.5T200-238.31q1.15 5.31 2.69 19.73 1.54 14.43 5.77 18.58Z"/>',
  wallet:
    '<path d="M240-180q-57.92 0-98.96-41.04Q100-262.08 100-320v-320q0-57.92 41.04-98.96Q182.08-780 240-780h480q57.92 0 98.96 41.04Q860-697.92 860-640v320q0 57.92-41.04 98.96Q777.92-180 720-180H240Zm0-450h480q22.77 0 42.96 6.54T800-603.61V-640q0-33-23.5-56.5T720-720H240q-33 0-56.5 23.5T160-640v36.39q16.85-13.31 37.04-19.85Q217.23-630 240-630Zm-76.31 119.62 449.23 109.15q8.23 2 16.66.19 8.42-1.81 15.27-7.42l144-121q-9.85-18.08-28.2-29.31Q742.31-570 720-570H240q-28.31 0-49.15 16.58-20.85 16.57-27.16 43.04Z"/>',
  directions_car:
    '<path d="M224.61-220v50q0 12.75-8.62 21.37-8.63 8.63-21.38 8.63H170q-12.75 0-21.37-8.63Q140-157.25 140-170v-306.92L221.69-710q4.47-13.77 16.39-21.88Q250-740 264.62-740h432.3q14.04 0 25.5 8.25 11.45 8.25 15.89 21.75L820-476.92V-170q0 12.75-8.63 21.37Q802.75-140 790-140h-24.61q-12.75 0-21.38-8.63-8.62-8.62-8.62-21.37v-50H224.61Zm-.3-316.92h511.38L685.23-680H274.77l-50.46 143.08Zm74.24 210.77q21.83 0 37.03-15.29 15.19-15.28 15.19-37.11t-15.28-37.03q-15.29-15.19-37.12-15.19t-37.02 15.28q-15.2 15.29-15.2 37.12t15.29 37.02q15.28 15.2 37.11 15.2Zm363.08 0q21.83 0 37.02-15.29 15.2-15.28 15.2-37.11t-15.29-37.03q-15.28-15.19-37.11-15.19t-37.03 15.28q-15.19 15.29-15.19 37.12t15.28 37.02q15.29 15.2 37.12 15.2Z"/>',
  restaurant:
    '<path d="M300-100v-361.38q-46.38-7.08-78.58-42.54-32.19-35.46-32.19-86.85V-860h40v269.23H300V-860h40v269.23h70.77V-860h40v269.23q0 51.39-32.19 86.85-32.2 35.46-78.58 42.54V-100h-40Zm369.23 0v-320h-98.46v-260q0-66.85 37.73-118.42 37.73-51.58 100.73-60.04V-100h-40Z"/>',
  restaurant_fill:
    '<path d="M300-100v-361.38q-46.38-7.08-78.58-42.54-32.19-35.46-32.19-86.85V-860h40v269.23H300V-860h40v269.23h70.77V-860h40v269.23q0 51.39-32.19 86.85-32.2 35.46-78.58 42.54V-100h-40Zm369.23 0v-320h-98.46v-260q0-66.85 37.73-118.42 37.73-51.58 100.73-60.04V-100h-40Z"/>',
  wifi_fill:
    '<path d="M480-131.54q-33.92 0-57.35-23.42-23.42-23.42-23.42-57.35 0-33.92 23.42-57.34 23.43-23.42 57.35-23.42t57.35 23.42q23.42 23.42 23.42 57.34 0 33.93-23.42 57.35-23.43 23.42-57.35 23.42ZM254-346l-62.46-63.69q55.54-55.54 129.33-87.54 73.8-32 159.19-32 85.4 0 159.13 32.31 73.73 32.31 129.27 88.46L706-346q-44-44-102-69t-124-25q-66 0-124 25t-102 69ZM84-516l-62.46-62.46q87.77-89 205-139.88Q343.77-769.23 480-769.23q136.23 0 253.46 50.89 117.23 50.88 205 139.88L876-516q-77-77-178.5-120.5T480-680q-116 0-217.5 43.5T84-516Z"/>',
  pets_fill:
    '<path d="M180.04-495q-33.58 0-56.81-23.19Q100-541.37 100-574.96q0-33.58 23.19-56.81Q146.37-655 179.96-655q33.58 0 56.81 23.19Q260-608.63 260-575.04q0 33.58-23.19 56.81Q213.63-495 180.04-495Zm180-160q-33.58 0-56.81-23.19Q280-701.37 280-734.96q0-33.58 23.19-56.81Q326.37-815 359.96-815q33.58 0 56.81 23.19Q440-768.63 440-735.04q0 33.58-23.19 56.81Q393.63-655 360.04-655Zm240 0q-33.58 0-56.81-23.19Q520-701.37 520-734.96q0-33.58 23.19-56.81Q566.37-815 599.96-815q33.58 0 56.81 23.19Q680-768.63 680-735.04q0 33.58-23.19 56.81Q633.63-655 600.04-655Zm180 160q-33.58 0-56.81-23.19Q700-541.37 700-574.96q0-33.58 23.19-56.81Q746.37-655 779.96-655q33.58 0 56.81 23.19Q860-608.63 860-575.04q0 33.58-23.19 56.81Q813.63-495 780.04-495ZM266-95q-37.31 0-61.65-28.55Q180-152.1 180-191q0-48.92 34.35-83.69 34.34-34.77 67.03-70.46 29-31.77 50.39-67.89 21.38-36.11 49.61-68.11 19.7-22.16 44.57-38Q450.83-535 480-535q30.02 0 55.63 15.23 25.6 15.23 44.52 38.15 28 32 49.12 68.24 21.11 36.23 49.35 68.23 32.69 35.69 67.03 70.46Q780-239.92 780-191q0 38.9-24.35 67.45Q731.31-95 694-95q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z"/>',
  fitness_center:
    '<path d="m543.69-104-36-36 142-142L282-649.69l-142 142-36-36 57.54-58-36-36 84-84-56-56.46 28.31-28.31 56.46 56 84-84 36 36 58-57.54 36 36-142 142L678-310.31l142-142 36 36-57.54 58 36 36-84 84 56 56.46-28.31 28.31-56.46-56-84 84-36-36-58 57.54Z"/>',
  flightsmode: "none",
  sort: '<path d="M140-260v-60h215v60H140Zm0-190v-60h447.31v60H140Zm0-190v-60h680v60H140Z"/>',
  local_bar_fill:
    '<path d="M260-130v-60h190v-229.23L146.93-760v-60h666.14v60L510-419.23V-190h190v60H260Zm38-550h364l72-80H226l72 80Z"/>',
  concierge:
    '<path d="M440-120v-40h440v40H440Zm40-95.38q0-69.47 46-120.35 46-50.89 114-57.96v-16.54q0-7.77 5.73-13.89 5.73-6.11 14.27-6.11 7.77 0 13.88 6.11Q680-418 680-410.23v16.54q67.77 7.07 113.88 57.96Q840-284.85 840-215.38H480Zm48.08-40.24h262.61q-12.07-43.92-47.73-72.34-35.65-28.42-82.96-28.42-47.54 0-83.69 28.42-36.16 28.42-48.23 72.34Zm131.69 0ZM81.54-483.08v-326.15h160v51.84l304.61-88.76 280 87.69V-740q0 34.62-27.69 59.62t-66.15 25H630.77V-636q0 20.38-14.12 37.42-14.11 17.04-33.27 24.5l-246.92 91H81.54Zm40-40h80v-246.15h-80v246.15Zm120 0h88.61l239.7-88.07q9.46-3.23 15.19-11.2 5.73-7.96 5.73-18.42v-14.61h-77.15l-117 38.76-13.24-38.3 124.24-40.46h224.69q13.61 0 30.96-8.43 17.35-8.42 20.42-25.88l-239.54-74-302.61 88.31v192.3Z"/>',
  local_parking_fill:
    '<path d="M280-160v-640h240q82.31 0 141.15 58.85Q720-682.31 720-600t-58.85 141.15Q602.31-400 520-400H360v240h-80Zm80-320h161.85q49.5 0 84.75-35.25T641.85-600q0-49.5-35.25-84.75T521.85-720H360v240Z"/>',
  ac_unit_fill:
    '<path d="M460-100v-193.69l-150 148L281.69-174 460-352.31V-460H352.31L174-281.69 145.69-310l148-150H100v-40h193.69l-148-150L174-678.31 352.31-500H460v-107.69L281.69-786 310-814.31l150 148V-860h40v193.69l150-148L678.31-786 500-607.69V-500h107.69L786-678.31 814.31-650l-148 150H860v40H666.31l148 150L786-281.69 607.69-460H500v107.69L678.31-174 650-145.69l-150-148V-100h-40Z"/>',
  bed: '<path d="M120-240v-200q0-17.77 10.23-37.85Q140.46-497.92 160-508v-92q0-33.85 23.08-56.92Q206.15-680 240-680h180q20.69 0 35.31 8.5Q469.92-663 480-648q10.08-15 24.69-23.5Q519.31-680 540-680h180q33.85 0 56.92 23.08Q800-633.85 800-600v92q19.54 10.08 29.77 30.15Q840-457.77 840-440v200h-40v-80H160v80h-40Zm380-280h260v-80q0-17-11.5-28.5T720-640H540q-17 0-28.5 11.5T500-600v80Zm-300 0h260v-80q0-17-11.5-28.5T420-640H240q-17 0-28.5 11.5T200-600v80Zm-40 160h640v-80q0-17-11.5-28.5T760-480H200q-17 0-28.5 11.5T160-440v80Zm640 0H160h640Z"/>',
  bed_fill:
    '<path d="M120-240v-200q0-17.77 10.23-37.85Q140.46-497.92 160-508v-92q0-33.85 23.08-56.92Q206.15-680 240-680h180q20.69 0 35.31 8.5Q469.92-663 480-648q10.08-15 24.69-23.5Q519.31-680 540-680h180q33.85 0 56.92 23.08Q800-633.85 800-600v92q19.54 10.08 29.77 30.15Q840-457.77 840-440v200h-40v-80H160v80h-40Zm380-280h260v-80q0-17-11.5-28.5T720-640H540q-17 0-28.5 11.5T500-600v80Zm-300 0h260v-80q0-17-11.5-28.5T420-640H240q-17 0-28.5 11.5T200-600v80Z"/>',
  wc: '<path d="M246.15-100v-280h-60v-220q0-25.31 17.35-42.65Q220.85-660 246.15-660h107.7q25.3 0 42.65 17.35 17.35 17.34 17.35 42.65v220h-60v280h-107.7ZM300-701.54q-28.38 0-48.04-19.65-19.65-19.66-19.65-48.04 0-28.39 19.65-48.04 19.66-19.65 48.04-19.65t48.04 19.65q19.65 19.65 19.65 48.04 0 28.38-19.65 48.04-19.66 19.65-48.04 19.65ZM620-100v-220h-96.92l80.46-299.85q5.69-19.07 21.04-29.61Q639.92-660 660-660q20.08 0 35.42 10.54 15.35 10.54 21.04 29.61L796.92-320H700v220h-80Zm40-601.54q-28.38 0-48.04-19.65-19.65-19.66-19.65-48.04 0-28.39 19.65-48.04 19.66-19.65 48.04-19.65t48.04 19.65q19.65 19.65 19.65 48.04 0 28.38-19.65 48.04-19.66 19.65-48.04 19.65Z"/>',
  accessibility:
    '<path d="M480-729.23q-27.92 0-47.81-19.89-19.88-19.88-19.88-47.8 0-27.93 19.88-47.81 19.89-19.89 47.81-19.89t47.81 19.89q19.88 19.88 19.88 47.81 0 27.92-19.88 47.8-19.89 19.89-47.81 19.89ZM392.31-100v-523.08H158.46v-40h643.08v40H567.69V-100h-40v-220h-95.38v220h-40Z"/>',
  done: '<path d="M382-267.69 183.23-466.46 211.77-495 382-324.77 748.23-691l28.54 28.54L382-267.69Z"/>',
  done_fill:
    '<path d="M382-267.69 183.23-466.46 211.77-495 382-324.77 748.23-691l28.54 28.54L382-267.69Z"/>',
  location_on:
    '<path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Z"/>',
  locations_on:
    '<path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Z"/>',
  smoke_free_fill:
    '<path d="M807.38-83.69 546.46-344.62H124.62v-70.76h351.07L99.08-792l28.3-28.31L835.69-112l-28.31 28.31Zm-78-260.93-7.84-7.84v-62.92h35.38v70.76h-27.54Zm67.54 0v-70.76h35.39v70.76h-35.39Zm-115.38-35.53-35.23-35.23h35.23v35.23Zm40-90.62v-52q0-35.92-23-58.73-23-22.81-55-22.81h-62q-45.23 0-77.31-32.73-32.08-32.73-32.08-79.73 0-44.43 32.08-75.37 32.08-30.94 77.31-30.94v35.39q-30 0-52 20.08-22 20.09-22 50.7 0 30.6 22 53.91 22 23.31 52 23.31h62q46.77 0 80.08 33.31 33.3 33.3 33.3 80.38v55.23h-35.38Zm75.38 0v-90q0-66-46-107.85-46-41.84-114-41.84v-35.39q30 0 51.23-21.23 21.23-21.23 21.23-51.23h35.39q0 29-11.77 49.81-11.77 20.81-29.77 33.78 52.15 16.87 90.62 61.64 38.46 44.77 38.46 112.31v90h-35.39Z"/>',
  local_laundry_service_fill:
    '<path d="M252.31-100q-29.83 0-51.07-21.24Q180-142.48 180-172.31v-615.38q0-29.83 21.24-51.07Q222.48-860 252.31-860h455.38q29.83 0 51.07 21.24Q780-817.52 780-787.69v615.38q0 29.83-21.24 51.07Q737.52-100 707.69-100H252.31ZM480-215.39q76.61 0 130.61-54 54-54 54-130.61 0-76.61-54-130.61-54-54-130.61-54-76.61 0-130.61 54-54 54-54 130.61 0 76.61 54 130.61 54 54 130.61 54Zm0-52.61q-26 0-50.31-9.69-24.31-9.69-42.92-28.31L574-493.23q18.62 18.61 28.31 42.99Q612-425.87 612-400q0 55-38.5 93.5T480-268ZM320-680q17 0 28.5-11.5T360-720q0-17-11.5-28.5T320-760q-17 0-28.5 11.5T280-720q0 17 11.5 28.5T320-680Zm120 0q17 0 28.5-11.5T480-720q0-17-11.5-28.5T440-760q-17 0-28.5 11.5T400-720q0 17 11.5 28.5T440-680Z"/>',
  spa_fill:
    '<path d="M486.92-100.77q-86.69-10.85-155.69-44.54t-117.8-88.42Q164.62-288.46 138-361.31q-26.61-72.84-28.92-161.61 96.54 6.77 167.54 35.77 71 29 117.73 81.23 46.73 52.23 69.65 128.27 22.92 76.03 22.92 176.88ZM480-389.69q-21.85-35-57.31-69.19t-78.23-59.89q4.46-40.07 16.35-83.15 11.88-43.08 29.58-84.46 17.69-41.39 40.5-79.46 22.8-38.08 49.11-68.77 26.31 30.69 49.11 68.57 22.81 37.89 40.5 79.66 17.7 41.77 29.77 84.84 12.08 43.08 16.16 83.54-44.69 25.46-80.35 59.66-35.65 34.19-55.19 68.65Zm66.92 275.61q-1.23-57.31-9.54-111.04-8.3-53.73-25.92-103.65 43.15-83.31 128.73-137.58 85.58-54.27 210.73-56.57-2.85 153-81.61 258.46Q690.54-159 546.92-114.08Z"/>',
  pool_fill:
    '<path d="M100-141.54v-41.54q31.08-3.84 49.69-20.38Q168.31-220 215.08-220q46.77 0 69.3 20 22.54 20 63.16 20 40.61 0 63.15-20T480-220q46.77 0 70.08 20 23.3 20 63.92 20 40.62 0 62.38-20 21.77-20 68.54-20 46.77 0 65.39 16.54 18.61 16.54 49.69 20.38v41.54q-35.92-4.61-56.73-21.54Q782.46-180 744.92-180q-37.54 0-59.3 20-21.77 20-71.62 20-49.85 0-73.15-20-23.31-20-60.85-20-37.54 0-60.08 20t-72.38 20q-49.85 0-72.39-20-22.53-20-60.07-20t-58.35 16.92q-20.81 16.93-56.73 21.54Zm0-180v-41.54q31.08-3.84 49.69-20.38Q168.31-400 215.08-400q46.77 0 69.42 20t63.04 20q40.61 0 63.15-20T480-400q46.77 0 69.31 20t63.15 20q40.62 0 63.16-20 22.53-20 69.3-20 46.77 0 65.39 16.54 18.61 16.54 49.69 20.38v41.54q-35.92-4.61-56.73-21.54Q782.46-360 744.92-360q-37.54 0-58.96 20T614-320q-50.08 0-73.27-20-23.19-20-60.73-20-38 0-59.96 20t-72.5 20q-50.54 0-72.73-20-22.19-20-59.73-20-37.54 0-58.35 16.92-20.81 16.93-56.73 21.54Zm211.38-191.69 129.16-129.15L379-703.92q-32.23-32.23-65.38-45.31-33.16-13.08-84.08-15.39V-820q62.69 2.31 104 17.65 41.31 15.35 82.15 56.2l232.93 232.92q-7.77 6.38-16.08 9.81-8.31 3.42-19.31 3.42-26.77 0-52.77-20-26-20-80.46-20-54.46 0-80.46 20-26 20-52.77 20-11 0-19.31-3.42-8.31-3.43-16.08-9.81Zm345.85-299.08q35.08 0 59.85 24.89 24.77 24.88 24.77 59.73 0 35.07-24.77 59.84-24.77 24.77-59.85 24.77t-59.85-24.77q-24.76-24.77-24.76-59.84 0-34.85 24.76-59.73 24.77-24.89 59.85-24.89Z"/>',
  more: '<path d="M258.46-440q-16.5 0-28.25-11.75T218.46-480q0-16.5 11.75-28.25T258.46-520q16.5 0 28.25 11.75T298.46-480q0 16.5-11.75 28.25T258.46-440ZM480-440q-16.5 0-28.25-11.75T440-480q0-16.5 11.75-28.25T480-520q16.5 0 28.25 11.75T520-480q0 16.5-11.75 28.25T480-440Zm221.54 0q-16.5 0-28.25-11.75T661.54-480q0-16.5 11.75-28.25T701.54-520q16.5 0 28.25 11.75T741.54-480q0 16.5-11.75 28.25T701.54-440Z"/>',
  pet: '<path d="M180.04-495q-33.58 0-56.81-23.19Q100-541.37 100-574.96q0-33.58 23.19-56.81Q146.37-655 179.96-655q33.58 0 56.81 23.19Q260-608.63 260-575.04q0 33.58-23.19 56.81Q213.63-495 180.04-495Zm180-160q-33.58 0-56.81-23.19Q280-701.37 280-734.96q0-33.58 23.19-56.81Q326.37-815 359.96-815q33.58 0 56.81 23.19Q440-768.63 440-735.04q0 33.58-23.19 56.81Q393.63-655 360.04-655Zm240 0q-33.58 0-56.81-23.19Q520-701.37 520-734.96q0-33.58 23.19-56.81Q566.37-815 599.96-815q33.58 0 56.81 23.19Q680-768.63 680-735.04q0 33.58-23.19 56.81Q633.63-655 600.04-655Zm180 160q-33.58 0-56.81-23.19Q700-541.37 700-574.96q0-33.58 23.19-56.81Q746.37-655 779.96-655q33.58 0 56.81 23.19Q860-608.63 860-575.04q0 33.58-23.19 56.81Q813.63-495 780.04-495ZM266-95q-37.31 0-61.65-28.55Q180-152.1 180-191q0-48.92 34.35-83.69 34.34-34.77 67.03-70.46 29-31.77 50.39-67.89 21.38-36.11 49.61-68.11 19.7-22.16 44.57-38Q450.83-535 480-535q30.02 0 55.63 15.23 25.6 15.23 44.52 38.15 28 32 49.12 68.24 21.11 36.23 49.35 68.23 32.69 35.69 67.03 70.46Q780-239.92 780-191q0 38.9-24.35 67.45Q731.31-95 694-95q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z"/>',
  child_care:
    '<path d="M575.54-502.31q-15.77 0-26.81-10.88-11.04-10.89-11.04-26.66t10.89-26.8q10.89-11.04 26.65-11.04 15.77 0 26.81 10.88 11.04 10.89 11.04 26.66t-10.89 26.8q-10.89 11.04-26.65 11.04Zm-190.77 0q-15.77 0-26.81-10.88-11.04-10.89-11.04-26.66t10.89-26.8q10.89-11.04 26.65-11.04 15.77 0 26.81 10.88 11.04 10.89 11.04 26.66t-10.89 26.8q-10.89 11.04-26.65 11.04ZM480-310.77q-48.46 0-88.88-24.15-40.43-24.16-64.97-65.08h307.7q-24.54 40.92-64.97 65.08-40.42 24.15-88.88 24.15Zm.04 150.77q-66.58 0-124.77-25.04t-101.69-68.54q-43.5-43.5-68.54-101.65Q160-413.37 160-479.96q0-66.58 25.04-124.77t68.54-101.69q43.5-43.5 101.65-68.54Q413.37-800 479.96-800q66.58 0 124.77 25.04t101.69 68.54q43.5 43.5 68.54 101.65Q800-546.63 800-480.04q0 66.58-25.04 124.77t-68.54 101.69q-43.5 43.5-101.65 68.54Q546.63-160 480.04-160Zm-.04-40q116 0 198-82t82-198q0-116-82-198t-198-82q-10.87 0-21.74.77-10.88.77-20.72 2.77-2.92 7.54-3.39 13-.46 5.46-.46 13.46 0 25.62 18.35 43.96 18.34 18.35 44.19 18.35 11.08 0 17.42-3 6.35-3 14.35-3 6.73 0 11.21 5.34t4.48 12.47q0 13.65-15.34 18.61-15.35 4.96-32.35 4.96-40.38 0-69.04-28.65-28.65-28.66-28.65-69.04v-8.17q0-3.63 1.77-9.68-86.85 25.39-143.46 98.31Q200-576.62 200-480q0 116 82 198t198 82Zm0-280Z"/>',
  place: "none",
  local_dining_fill:
    '<path d="M171.92-147.69 143.62-176 549-581.38q-20.31-48.16-8.85-94.62 11.47-46.46 51.62-86.15 39.15-39.16 98-54.31 58.85-15.16 98.31 24.31 39.46 39.46 24.3 98.3-15.15 58.85-54.3 98-39.7 40.16-86.16 51.62-46.46 11.46-94.61-8.85L504.23-480l304 304-28.31 28.31-304-302-304 302Zm122.62-337.39L183.77-595.85Q139-640.62 135.54-701.77q-3.46-61.15 35.92-110.54l224.92 225.39-101.84 101.84Z"/>',
  airport_shuttle:
    '<path d="M254.62-272.31q-36.54 0-62.12-25.57-25.58-25.58-25.58-62.12H90v-320q0-16.5 11.75-28.25T130-720h523.08L870-515.38V-360h-64.62q0 36.54-25.57 62.12-25.58 25.57-62.12 25.57t-62.11-25.57Q630-323.46 630-360H342.31q0 36.15-25.58 61.92-25.58 25.77-62.11 25.77ZM570-536.92h218.46L636.15-680H570v143.08Zm-220 0h180V-680H350v143.08Zm-220 0h180V-680H130v143.08ZM254.62-310q21 0 35.5-14.5t14.5-35.5q0-21-14.5-35.5t-35.5-14.5q-21 0-35.5 14.5t-14.5 35.5q0 21 14.5 35.5t35.5 14.5Zm463.07 0q21 0 35.5-14.5t14.5-35.5q0-21-14.5-35.5t-35.5-14.5q-21 0-35.5 14.5t-14.5 35.5q0 21 14.5 35.5t35.5 14.5ZM130-400h47.38q8.54-18 29.39-32.85 20.85-14.84 47.85-14.84T301.31-434q19.69 13.69 30.54 34h308.61q8.54-18 29.39-32.85 20.84-14.84 47.84-14.84 27 0 46.69 13.69 19.7 13.69 30.54 34H830v-96.92H130V-400Zm700-96.92H130h700Z"/>',
  gym: '<path d="m776-585-27.54-27.54 19.23-20.23q7.69-7.69 7.69-17.31 0-9.61-7.69-17.3L667.38-767.69q-7.69-7.69-17.3-7.69-9.62 0-17.31 7.69l-20.23 19.23L584-777l22.31-23.31q17.61-17.61 43.15-17.11t43.16 18.11l106.69 106.69q17.61 17.62 17.61 42.66 0 25.04-17.61 42.65L776-585ZM353.69-161.69q-17.61 17.61-42.65 17.61t-42.66-17.61L164.77-265.31q-18.39-18.38-18.39-45.73 0-27.34 18.39-45.73L184-376l28.54 28.54-19.46 19.23q-7.7 7.69-7.7 17.31 0 9.61 7.7 17.3l100.54 100.54q7.69 7.7 17.3 7.7 9.62 0 17.31-7.7l19.23-19.46L376-184l-22.31 22.31ZM743-433.85l45.46-45.46q7.69-7.69 7.69-17.69t-7.69-17.69L514.69-788.46q-7.69-7.69-17.69-7.69t-17.69 7.69L433.85-743q-7.7 7.69-7.7 17.31 0 9.61 7.7 17.31l274.53 274.53q7.7 7.7 17.31 7.7 9.62 0 17.31-7.7ZM480.69-171.54l45.46-45.69q7.7-7.69 7.7-17.31 0-9.61-7.7-17.31l-274.3-274.3q-7.7-7.7-17.31-7.7-9.62 0-17.31 7.7l-45.69 45.46q-7.69 7.69-7.69 17.69t7.69 17.69l273.77 273.77q7.69 7.69 17.69 7.69t17.69-7.69Zm-28.31-210.92 125.39-125.16-70.15-70.15-125.16 125.39 69.92 69.92Zm56.08 239.23q-18.38 18.38-45.46 18.38-27.08 0-45.46-18.38L143.23-417.54q-18.38-18.38-18.38-45.46 0-27.08 18.38-45.46l45.46-46.23q18.39-18.39 45.73-18.39 27.35 0 45.73 18.39l73.77 73.77 125.39-125.39-73.77-73.54q-18.39-18.38-18.39-45.84 0-27.46 18.39-45.85l46.23-46.23q18.38-18.38 45.73-18.38 27.35 0 45.73 18.38l274.54 274.54q18.38 18.38 18.38 45.73 0 27.35-18.38 45.73l-46.23 46.23q-18.39 18.39-45.85 18.39t-45.84-18.39l-73.54-73.77-125.39 125.39 73.77 73.77q18.39 18.38 18.39 45.73 0 27.34-18.39 45.73l-46.23 45.46Z"/>',
  spaspa: "none",
  accessibilityv: "none",
  acessibility: "none",
  skillet:
    '<path d="M219.31-535.38q4.77-32.93-3.96-60.16-8.73-27.23-34.04-64.46-26.85-39.23-36.19-70.5-9.35-31.27-6.2-69.5h40.31q-3.38 33.38 4.42 59.69 7.81 26.31 33.12 63.54 28 40.62 37.35 71.89 9.34 31.26 5.5 69.5h-40.31Zm156.92 0q4.77-32.93-3.85-60.16-8.61-27.23-33.92-64.46-26.84-39.23-36.31-70.5-9.46-31.27-6.3-69.5h40.3q-3.38 33.38 4.43 59.69 7.8 26.31 33.11 63.54 28 40.62 37.35 71.89 9.34 31.26 5.5 69.5h-40.31Zm160 0q4.77-32.93-4.23-60.16-9-27.23-34.31-64.46-26.84-39.23-35.92-70.5-9.08-31.27-5.92-69.5h40.3q-3.38 33.38 4.43 59.69 7.8 26.31 33.11 63.54 28 40.62 37.35 71.89 9.34 31.26 5.5 69.5h-40.31ZM200-200q-33.85 0-56.92-23.08Q120-246.15 120-280v-160h520.23q1.15-30.15 18.92-54.12 17.77-23.96 46.7-33.42l168.07-56.61 12.7 37.53L718.54-490q-17.39 5.54-27.96 20.65Q680-454.23 680-435.85V-280q0 33.08-23.08 56.54Q633.85-200 600-200H200Zm0-40h400q17 0 28.5-11.5T640-280v-120H160v120q0 17 11.5 28.5T200-240Zm200-80Z"/>',
  donev: "none",
  lob_flights: "none",
  power_fill:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M16.01 7L16 3h-2v4h-4V3H8v4h-.01C7 6.99 6 7.99 6 8.99v5.49L9.5 18v3h5v-3l3.5-3.51v-5.5c0-1-1-2-1.99-1.99z"/>',
  travel: "none",
  kitchen:
    '<path d="M327.69-640v-104.62h40V-640h-40Zm0 324.62v-173.85h40v173.85h-40ZM264.62-120q-26.85 0-45.74-18.88Q200-157.77 200-184.62v-590.76q0-26.85 18.88-45.74Q237.77-840 264.62-840h430.76q26.85 0 45.74 18.88Q760-802.23 760-775.38v590.76q0 26.85-18.88 45.74Q722.23-120 695.38-120H264.62Zm0-40h430.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-360H240v360q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92ZM240-584.62h480v-190.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H264.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v190.76Z"/>',
  local_convenience_store:
    '<path d="M321.77-244.62h110.77v-30.76h-80v-49.24h80v-110.76H321.77v30.76h80v49.24h-80v110.76Zm286.15 0h30.77v-190.76h-30.77v80h-49.23v-80h-30.77v110.76h80v80Zm212.31-279.53v319.53q0 27.62-18.5 46.12-18.5 18.5-46.11 18.5H204.85q-27.62 0-46.12-18.5-18.5-18.5-18.5-46.12v-321.07q-25.31-18.69-37.04-49t-.5-64.69l38.93-128.31q8-24.46 25.8-38.39Q185.23-820 211.46-820h536q26.23 0 43.92 13.04 17.7 13.04 25.93 37.73l40.46 129.85q11.23 34.38-.5 65.23-11.73 30.84-37.04 50Zm-252-15.85q38.54 0 57.54-21.58 19-21.57 16-44.57L615.15-780H500.23v168q0 29.46 20.15 50.73Q540.54-540 568.23-540Zm-180 0q32.23 0 52.12-21.27 19.88-21.27 19.88-50.73v-168H345.31l-26.62 176.92q-2.46 18.62 16.27 40.85T388.23-540Zm-178 0q26.46 0 44.96-18t23.04-44.92L303.31-780h-91.85q-13.08 0-20.77 5.77-7.69 5.77-11.54 17.31l-36.92 126.3q-9.85 31.54 8.42 61.08T210.23-540Zm540 0q35.92 0 57.39-28 21.46-28 10.61-62.62l-38.92-127.84Q775.46-770 767.77-775q-7.69-5-20.77-5h-89.85l25.08 177.08Q686.77-576 705.27-558t44.96 18ZM204.85-180h550.77q10.76 0 17.69-6.92 6.92-6.93 6.92-17.7v-300.46q-8.08 2.77-15.35 3.93-7.26 1.15-14.65 1.15-27 0-47.5-10.54t-38.96-33.61q-15.69 19.53-38.69 31.84-23 12.31-55.93 12.31-23.92 0-45.5-11.15-21.57-11.16-43.42-33-20.08 21.84-44.5 33Q411.31-500 389.15-500q-25.15 0-49.03-9.62-23.89-9.61-41.89-34.53-29.46 29.46-51.5 36.8-22.04 7.35-36.5 7.35-7.38 0-14.88-1.15-7.5-1.16-15.12-3.93v300.46q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92Zm550.77 0H204.85 755.62Z"/>',
  local_cafe:
    '<path d="M200-160v-40h560v40H200Zm125.38-150.77q-52.15 0-88.76-35.46Q200-381.69 200-432.31V-800h572.31q27.92 0 47.81 18.88Q840-762.23 840-734.62v89.24q0 27.61-19.88 46.5Q800.23-580 772.31-580h-80v147.69q0 50.62-36.62 86.08-36.61 35.46-88.77 35.46H325.38Zm0-40h241.54q35.22 0 60.31-24.04 25.08-24.04 25.08-57.81V-760H240v327.67q0 33.79 25.08 57.68 25.08 23.88 60.3 23.88ZM692.31-620h80q11.54 0 19.61-7.31 8.08-7.31 8.08-18.07v-89.24q0-10.76-8.08-18.07-8.07-7.31-19.61-7.31h-80v140ZM325.38-350.77H240h412.31-326.93Z"/>',
  Entertainment: "none",
  wfi: '<path d="M480-176.92 56.92-600q87.31-78.54 196.43-119.27Q362.46-760 480-760q117.77 0 226.77 40.62 109 40.61 196.31 119.38L480-176.92ZM174-540q67-48 145-74t161-26q83 0 161 26t145 74l58-58q-79-60-172-91t-192-31q-99 0-192 31t-172 91l58 58Z"/>',
  local_laundy_facilities: "none",
  Internet:
    '<<path d="M480-176.92 56.92-600q87.31-78.54 196.43-119.27Q362.46-760 480-760q117.77 0 226.77 40.62 109 40.61 196.31 119.38L480-176.92ZM174-540q67-48 145-74t161-26q83 0 161 26t145 74l58-58q-79-60-172-91t-192-31q-99 0-192 31t-172 91l58 58Z"/>',
  shuttle: "none",
  loation_on: "none",
  local_laundy_services: "none",
  smoking:
    '<path d="M126.15-233.85v-70.77h556.93v70.77H126.15Zm596.93 0v-70.77h35.38v70.77h-35.38Zm75.38 0v-70.77h35.39v70.77h-35.39ZM723.08-360v-52q0-35.92-23-58.73-23-22.81-55-22.81h-62q-45.23 0-77.31-32.73Q473.69-559 473.69-606q0-44.43 32.08-75.37 32.08-30.94 77.31-30.94v35.39q-30 0-52 20.08-22 20.09-22 50.69 0 30.61 22 53.92 22 23.31 52 23.31h62q46.77 0 80.07 33.3 33.31 33.31 33.31 80.39V-360h-35.38Zm75.38 0v-90q0-66-46-107.85-46-41.84-114-41.84v-35.39q30 0 51.23-21.23 21.23-21.23 21.23-51.23 0-31.54-21.23-53.54t-51.23-22v-35.38q45.23 0 76.54 32.84 31.31 32.85 31.31 78.08 0 29-11.77 49.81-11.77 20.81-29.77 33.78 52.15 16.87 90.61 61.64 38.47 44.77 38.47 112.31v90h-35.39Z"/>',
  bedw: "none",
  view: '<path d="M0 0h24v24H0z" fill="none"/><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>',
  icon__bathroom:
    '<g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><circle cx="8" cy="17" r="1"/><circle cx="12" cy="17" r="1"/><circle cx="16" cy="17" r="1"/><path d="M13,5.08V3h-2v2.08C7.61,5.57,5,8.47,5,12v2h14v-2C19,8.47,16.39,5.57,13,5.08z"/><circle cx="8" cy="20" r="1"/><circle cx="12" cy="20" r="1"/><circle cx="16" cy="20" r="1"/></g>',
  icon__bed:
    '<path d="M120-240v-200q0-17.77 10.23-37.85Q140.46-497.92 160-508v-92q0-33.85 23.08-56.92Q206.15-680 240-680h180q20.69 0 35.31 8.5Q469.92-663 480-648q10.08-15 24.69-23.5Q519.31-680 540-680h180q33.85 0 56.92 23.08Q800-633.85 800-600v92q19.54 10.08 29.77 30.15Q840-457.77 840-440v200h-40v-80H160v80h-40Zm380-280h260v-80q0-17-11.5-28.5T720-640H540q-17 0-28.5 11.5T500-600v80Zm-300 0h260v-80q0-17-11.5-28.5T420-640H240q-17 0-28.5 11.5T200-600v80Zm-40 160h640v-80q0-17-11.5-28.5T760-480H200q-17 0-28.5 11.5T160-440v80Zm640 0H160h640Z"/>',
  icon__done:
    '<path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>',
  icon__local_dining:
    '<path d="M171.92-147.69 143.62-176 549-581.38q-20.31-48.16-8.85-94.62 11.47-46.46 51.62-86.15 39.15-39.16 98-54.31 58.85-15.16 98.31 24.31 39.46 39.46 24.3 98.3-15.15 58.85-54.3 98-39.7 40.16-86.16 51.62-46.46 11.46-94.61-8.85L504.23-480l304 304-28.31 28.31-304-302-304 302Zm122.62-337.39L183.77-595.85Q139-640.62 135.54-701.77q-3.46-61.15 35.92-110.54l224.92 225.39-101.84 101.84Z"/>',
  icon__wifi:
    '<path d="M480-176.92 56.92-600q87.31-78.54 196.43-119.27Q362.46-760 480-760q117.77 0 226.77 40.62 109 40.61 196.31 119.38L480-176.92ZM174-540q67-48 145-74t161-26q83 0 161 26t145 74l58-58q-79-60-172-91t-192-31q-99 0-192 31t-172 91l58 58Z"/>',
  icon__accessible: "none",
  icon__patio: "none",
  free_breakfast:
    '<path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"/>',
  ski: '<path d="M742.31-60q-23.69 0-46.66-3.62-22.96-3.61-45.19-11.61L101.54-275.62l11.54-33.15L392.15-207 465-387.31 303.54-542.46q-21.62-19.54-17.27-48.81 4.35-29.27 30.65-44.65L479-732.08q12.38-7.69 25.65-8.8 13.27-1.12 25.66 4.34 12.38 4.46 21.81 14.39 9.42 9.92 14.65 23.3l13 43q16.85 55.31 47.5 90.62T712-512.15l21.77-65.54 33.15 11.07-34.23 101.08q-75.54-17.38-119.84-56.84-44.31-39.47-69-114.39L393.62-548l121 144.92-84.39 210 154 55.77 87.85-260.07q7.84 4.23 15.69 6.3 7.85 2.08 17.46 4.31l-88.08 262 41 14.85q21.85 7.54 41.73 11.04 19.89 3.5 42.43 3.5 24.46 0 46.81-3.85 22.34-3.85 43.57-12.31l25.77 27.31q-27.38 13.15-56.23 18.69Q773.38-60 742.31-60Zm-95.39-652.31q-27.61 0-47.65-20.04-20.04-20.03-20.04-47.65 0-27.62 20.04-47.65 20.04-20.04 47.65-20.04 27.62 0 47.66 20.04 20.04 20.03 20.04 47.65 0 27.62-20.04 47.65-20.04 20.04-47.66 20.04Z"/>',
  restaurantr:
    '<path d="M280-80v-366q-51-14-85.5-56T160-600v-280h80v280h40v-280h80v280h40v-280h80v280q0 56-34.5 98T360-446v366h-80Zm400 0v-320H560v-280q0-83 58.5-141.5T760-880v800h-80Z"/>',
  bath: '<path d="M284.62-557.69q-25.31 0-42.66-17.4-17.34-17.4-17.34-41.83 0-25.07 17.34-42.92 17.35-17.85 42.66-17.85 25.3 0 42.65 17.74 17.35 17.74 17.35 42.65 0 24.92-17.35 42.26-17.35 17.35-42.65 17.35Zm-60 437.69q-17 0-28.5-12.27t-11.5-29.27q-27.62 0-46.12-19.43Q120-200.4 120-227.69v-178.46h104.62v-30.77q0-31.08 21.76-53.62 21.77-22.54 52.85-22.54 18.46 0 34.69 8.39 16.23 8.38 28.7 22.38l40.61 47.39q8 8.22 15.5 15.41 7.5 7.19 16.5 13.36H720v-338.31q0-22.46-15-39T668.31-800q-10.35 0-19.83 4.42-9.48 4.43-16.79 11.73l-50 51.16q5 17.39 2 34.27-3 16.88-12 31.65l-82.31-84.31q14-9.24 30-11.8 16-2.57 32 3.59l50-51.33q13.39-13.82 30.54-21.6 17.15-7.78 36.39-7.78 38.61 0 65.15 28Q760-784 760-744.46v338.31h80v178.46q0 27.29-18.5 46.72t-46.12 19.43q0 17-11.5 29.27T735.38-120H224.62Zm-40-81.54h590.76q10.77 0 17.7-7.69 6.92-7.69 6.92-18.46v-138.46H160v138.46q0 10.77 6.92 18.46 6.93 7.69 17.7 7.69Zm0 0H160h640-615.38Z"/>',
  icon__family_friendly:
    '<path d="M720-727.69q-27.92 0-47.81-19.89-19.88-19.88-19.88-47.8 0-27.93 19.88-47.81 19.89-19.89 47.81-19.89t47.81 19.89q19.88 19.88 19.88 47.81 0 27.92-19.88 47.8-19.89 19.89-47.81 19.89ZM669.23-100v-310.77q0-34.97-15.88-62.95-15.89-27.97-47.12-44.43l41.92-109.93q8-21.92 27.97-35.38 19.96-13.46 43.88-13.46t43.88 13.07q19.97 13.08 27.97 35.77L900-344.62H793.85V-100H669.23ZM499.91-503.08q-21.83 0-37.03-15.28-15.19-15.28-15.19-37.12 0-21.83 15.29-37.02 15.28-15.19 37.11-15.19t37.03 15.28q15.19 15.28 15.19 37.12 0 21.83-15.29 37.02-15.28 15.19-37.11 15.19ZM220-727.69q-27.92 0-47.81-19.89-19.88-19.88-19.88-47.8 0-27.93 19.88-47.81 19.89-19.89 47.81-19.89t47.81 19.89q19.88 19.88 19.88 47.81 0 27.92-19.88 47.8-19.89 19.89-47.81 19.89ZM149.23-100v-263.08H83.08v-235.38q0-26.66 18.98-45.64t45.63-18.98h144.62q26.65 0 45.63 18.98 18.98 18.98 18.98 45.64v235.38h-66.15V-100H149.23Zm301.54 0v-140h-40v-170.77q0-20.51 14.36-34.87Q439.49-460 460-460h80q20.51 0 34.87 14.36 14.36 14.36 14.36 34.87V-240h-40v140h-98.46Z"/>',
  local_restaurant:
    '<path d="M300-100v-361.38q-46.38-7.08-78.58-42.54-32.19-35.46-32.19-86.85V-860h40v269.23H300V-860h40v269.23h70.77V-860h40v269.23q0 51.39-32.19 86.85-32.2 35.46-78.58 42.54V-100h-40Zm369.23 0v-320h-98.46v-260q0-66.85 37.73-118.42 37.73-51.58 100.73-60.04V-100h-40Z"/>',
  doneing: '<path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>',
  temperature:
    '<path d="M480-120q-66.85 0-113.42-46.58Q320-213.15 320-280q0-43.38 21.38-79.88 21.39-36.5 58.62-58.58V-760q0-33.85 23.08-56.92Q446.15-840 480-840t56.92 23.08Q560-793.85 560-760v341.54q37.23 22.08 58.62 58.58Q640-323.38 640-280q0 66.85-46.58 113.42Q546.85-120 480-120Zm-40-384.62h80v-60h-40v-30.76h40v-89.24h-40v-30.76h40V-760q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v255.38Z"/>',
  child: "none",
  wv: "none",
  iwifi:
    '<path d="M480-176.92 56.92-600q87.31-78.54 196.43-119.27Q362.46-760 480-760q117.77 0 226.77 40.62 109 40.61 196.31 119.38L480-176.92ZM174-540q67-48 145-74t161-26q83 0 161 26t145 74l58-58q-79-60-172-91t-192-31q-99 0-192 31t-172 91l58 58Z"/>',
  Restaurant:
    '<path d="M300-100v-361.38q-46.38-7.08-78.58-42.54-32.19-35.46-32.19-86.85V-860h40v269.23H300V-860h40v269.23h70.77V-860h40v269.23q0 51.39-32.19 86.85-32.2 35.46-78.58 42.54V-100h-40Zm369.23 0v-320h-98.46v-260q0-66.85 37.73-118.42 37.73-51.58 100.73-60.04V-100h-40Z"/>',
  directions_on: "none",
  local_laundry_facilities: "none",
  restaurantt:
    '<path d="M300-100v-361.38q-46.38-7.08-78.58-42.54-32.19-35.46-32.19-86.85V-860h40v269.23H300V-860h40v269.23h70.77V-860h40v269.23q0 51.39-32.19 86.85-32.2 35.46-78.58 42.54V-100h-40Zm369.23 0v-320h-98.46v-260q0-66.85 37.73-118.42 37.73-51.58 100.73-60.04V-100h-40Z"/>',
  menu: '<path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>',
  expand_more:
    '<path d="M480-357.85 253.85-584 296-626.15l184 184 184-184L706.15-584 480-357.85Z"/>',
  expand_less:
    '<path d="m480-541.85-184 184L253.85-400 480-626.15 706.15-400 664-357.85l-184-184Z"/>',
  keyboard_arrow_down:
    '<path d="M480-357.85 253.85-584 296-626.15l184 184 184-184L706.15-584 480-357.85Z"/>',
  keyboard_arrow_up:
    '<path d="m480-541.85-184 184L253.85-400 480-626.15 706.15-400 664-357.85l-184-184Z"/>',
  keyboard_arrow_right:
    '<path d="m517.85-480-184-184L376-706.15 602.15-480 376-253.85 333.85-296l184-184Z"/>',
  keyboard_arrow_left:
    '<path d="M560-253.85 333.85-480 560-706.15 602.15-664l-184 184 184 184L560-253.85Z"/>',
  arrow_downward:
    '<path d="M450-780v485.08L222.15-522.77 180-480l300 300 300-300-42.15-42.77L510-294.92V-780h-60Z"/>',
  north_east:
    '<path d="M222.15-180 180-222.15 637.85-680H360v-60h380v380h-60v-277.85L222.15-180Z"/>',
  search:
    '<path d="M779.38-153.85 528.92-404.31q-30 25.54-69 39.54t-78.38 14q-96.1 0-162.67-66.53-66.56-66.53-66.56-162.57 0-96.05 66.53-162.71 66.53-66.65 162.57-66.65 96.05 0 162.71 66.56Q610.77-676.1 610.77-580q0 41.69-14.77 80.69t-38.77 66.69l250.46 250.47-28.31 28.3ZM381.54-390.77q79.61 0 134.42-54.81 54.81-54.8 54.81-134.42 0-79.62-54.81-134.42-54.81-54.81-134.42-54.81-79.62 0-134.42 54.81-54.81 54.8-54.81 134.42 0 79.62 54.81 134.42 54.8 54.81 134.42 54.81Z"/>',
  person:
    '<path d="M480-504.62q-49.5 0-84.75-35.25T360-624.62q0-49.5 35.25-84.75T480-744.62q49.5 0 84.75 35.25T600-624.62q0 49.5-35.25 84.75T480-504.62ZM200-215.38v-65.85q0-24.77 14.42-46.35 14.43-21.57 38.81-33.5 56.62-27.15 113.31-40.73 56.69-13.57 113.46-13.57 56.77 0 113.46 13.57 56.69 13.58 113.31 40.73 24.38 11.93 38.81 33.5Q760-306 760-281.23v65.85H200Z"/>',
  person_fill:
    '<path d="M480-504.62q-49.5 0-84.75-35.25T360-624.62q0-49.5 35.25-84.75T480-744.62q49.5 0 84.75 35.25T600-624.62q0 49.5-35.25 84.75T480-504.62ZM200-215.38v-65.85q0-24.77 14.42-46.35 14.43-21.57 38.81-33.5 56.62-27.15 113.31-40.73 56.69-13.57 113.46-13.57 56.77 0 113.46 13.57 56.69 13.58 113.31 40.73 24.38 11.93 38.81 33.5Q760-306 760-281.23v65.85H200Z"/>',
  favorite:
    '<path d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z"/>',
  favorite_fill:
    '<path d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Z"/>',
  ios_share:
    '<path d="M264.62-80Q237-80 218.5-98.5 200-117 200-144.62v-390.76q0-27.62 18.5-46.12Q237-600 264.62-600h84.61v40h-84.61q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v390.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h430.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-390.76q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69h-84.61v-40h84.61q27.62 0 46.12 18.5Q760-563 760-535.38v390.76q0 27.62-18.5 46.12Q723-80 695.38-80H264.62ZM460-340v-435.46l-84 84L347.69-720 480-852.31 612.31-720 584-691.46l-84-84V-340h-40Z"/>',
  mark_as_unread:
    '<path d="M160-287.69q-24.92-3.08-42.46-22.35Q100-329.31 100-355.38v-310.16q0-12.69 6.96-24.31 6.96-11.61 19.66-18.46L430-860l297.39 151.69q10.46 4.85 17.23 15.93 6.76 11.07 9.38 23.15h-81.23L430-792.31l-270 134v370.62ZM287.69-140q-30.3 0-51.3-21-21-21-21-51.31v-329.23q0-30.3 21-51.3 21-21 51.3-21h500q30.31 0 51.31 21 21 21 21 51.3v329.23Q860-182 839-161q-21 21-51.31 21h-500Zm250-218.31-262.31-134v280q0 5.39 3.47 8.85 3.46 3.46 8.84 3.46h500q5.39 0 8.85-3.46t3.46-8.85v-280l-262.31 134Zm0-61.54 257.69-131.69q-1.92-1.15-3.65-1.73-1.73-.58-4.04-.58h-500q-1.92 0-3.84.58-1.93.58-3.85 1.73l257.69 131.69Zm262.31-134H275.38 800Z"/>',
  mark_as_unread_fill:
    '<path d="M160-295.38q-16.85-6.16-28.42-21.2Q120-331.62 120-350.77v-306.31q0-10.38 5.42-19.11 5.43-8.73 15.81-14.43L440-840l292.77 149.38q8.92 3.7 13.96 12.85 5.04 9.15 7.27 19.31h-45.46L440-794.62l-280 139v360.24ZM295.38-160q-26.84 0-45.73-18.88-18.88-18.89-18.88-45.74v-298.46q0-26.84 18.88-45.73 18.89-18.88 45.73-18.88h480q26.85 0 45.74 18.88Q840-549.92 840-523.08v298.46q0 26.85-18.88 45.74Q802.23-160 775.38-160h-480Zm240-210.62 264.62-134v-43.07l-264.62 134-264.61-134v43.07l264.61 134Z"/>',
  chevron_right:
    '<path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/>',
  chevron_left:
    '<path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/>',
  star: '<path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143Zm-91 125.46 57.31-246.77-191.46-165.92 252.61-21.92L480-828.84l98.54 232.69 252.61 21.92-191.46 165.92L697-161.54 480-292.46 263-161.54ZM480-470Z"/>',
  star_fill:
    '<path d="m293-203.08 49.62-212.54-164.93-142.84 217.23-18.85L480-777.69l85.08 200.38 217.23 18.85-164.93 142.84L667-203.08 480-315.92 293-203.08Z"/>',
  arrow_right_alt:
    '<path d="m553.85-253.85-42.16-43.38L664.46-450H180v-60h484.46L511.69-662.77l42.16-43.38L780-480 553.85-253.85Z"/>',
  arrow_left_alt:
    '<path d="M398.08-253.85 171.92-480l226.16-226.15 42.15 43.38L287.46-510h500.62v60H287.46l152.77 152.77-42.15 43.38Z"/>',
  arrow_outward:
    '<path d="m256-240-56-56 384-384H240v-80h480v480h-80v-344L256-240Z"/>',
  arrow_outward_fill:
    '<path d="m256-240-56-56 384-384H240v-80h480v480h-80v-344L256-240Z"/>',
  calendar_today:
    '<path d="M212.31-100Q182-100 161-121q-21-21-21-51.31v-535.38Q140-738 161-759q21-21 51.31-21h55.38v-84.61h61.54V-780h303.08v-84.61h60V-780h55.38Q778-780 799-759q21 21 21 51.31v535.38Q820-142 799-121q-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-375.38H200v375.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Z"/>',
  calendar_month:
    '<path d="M212.31-100Q182-100 161-121q-21-21-21-51.31v-535.38Q140-738 161-759q21-21 51.31-21h55.38v-84.61h61.54V-780h303.08v-84.61h60V-780h55.38Q778-780 799-759q21 21 21 51.31v535.38Q820-142 799-121q-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-375.38H200v375.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM200-607.69h560v-100q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v100Zm0 0V-720v112.31Zm280 210.77q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35Zm-160 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35Zm320 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35ZM480-240q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q494.69-240 480-240Zm-160 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q334.69-240 320-240Zm320 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q654.69-240 640-240Z"/>',
  group:
    '<path d="M71.93-187.69v-88.93q0-30.92 15.96-55.19 15.96-24.27 42.63-37.76 57.02-27.89 114.67-43.01 57.66-15.11 126.73-15.11 69.08 0 126.73 15.11 57.66 15.12 114.68 43.01 26.67 13.49 42.63 37.76 15.96 24.27 15.96 55.19v88.93H71.93Zm679.99 0v-93.85q0-39.38-19.28-75.07-19.29-35.68-54.72-61.23 40.23 6 76.39 18.57 36.15 12.58 69 29.73 31 16.54 47.88 38.99 16.88 22.44 16.88 49.01v93.85H751.92Zm-380-304.62q-57.75 0-98.87-41.12-41.12-41.13-41.12-98.88 0-57.75 41.12-98.87 41.12-41.13 98.87-41.13 57.75 0 98.88 41.13 41.12 41.12 41.12 98.87 0 57.75-41.12 98.88-41.13 41.12-98.88 41.12Zm345.38-140q0 57.75-41.12 98.88-41.12 41.12-98.87 41.12-6.77 0-17.23-1.54-10.47-1.54-17.23-3.38 23.66-28.45 36.37-63.12 12.7-34.67 12.7-72 0-37.34-12.96-71.73-12.96-34.38-36.11-63.3 8.61-3.08 17.23-4 8.61-.93 17.23-.93 57.75 0 98.87 41.13 41.12 41.12 41.12 98.87ZM131.92-247.69h480v-28.93q0-12.53-6.27-22.3-6.26-9.77-19.88-17.08-49.38-25.46-101.69-38.58-52.31-13.11-112.16-13.11-59.84 0-112.15 13.11-52.31 13.12-101.69 38.58-13.62 7.31-19.89 17.08-6.27 9.77-6.27 22.3v28.93Zm240-304.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0 304.62Zm0-384.62Z"/>',
  group_fill:
    '<path d="M71.93-187.69v-88.93q0-30.92 15.96-55.19 15.96-24.27 42.63-37.76 57.02-27.89 114.67-43.01 57.66-15.11 126.73-15.11 69.08 0 126.73 15.11 57.66 15.12 114.68 43.01 26.67 13.49 42.63 37.76 15.96 24.27 15.96 55.19v88.93H71.93Zm679.99 0v-93.85q0-39.38-19.28-75.07-19.29-35.68-54.72-61.23 40.23 6 76.39 18.57 36.15 12.58 69 29.73 31 16.54 47.88 38.99 16.88 22.44 16.88 49.01v93.85H751.92Zm-380-304.62q-57.75 0-98.87-41.12-41.12-41.13-41.12-98.88 0-57.75 41.12-98.87 41.12-41.13 98.87-41.13 57.75 0 98.88 41.13 41.12 41.12 41.12 98.87 0 57.75-41.12 98.88-41.13 41.12-98.88 41.12Zm345.38-140q0 57.75-41.12 98.88-41.12 41.12-98.87 41.12-6.77 0-17.23-1.54-10.47-1.54-17.23-3.38 23.66-28.45 36.37-63.12 12.7-34.67 12.7-72 0-37.34-12.96-71.73-12.96-34.38-36.11-63.3 8.61-3.08 17.23-4 8.61-.93 17.23-.93 57.75 0 98.87 41.13 41.12 41.12 41.12 98.87Z"/>',
  coffee:
    '<path d="M445-255.39q-110.08 0-187.54-75.34Q180-406.08 180-515.38V-760q0-24.54 17.73-42.27Q215.46-820 240-820h495q51.78 0 88.39 35.81T860-696.92q0 52.58-36.39 89.75Q787.23-570 735-570h-28.85v54.62q0 108.64-76.11 184.32-76.12 75.67-185.04 75.67ZM240-630h406.15v-130H240v130Zm205 314.62q83.77 0 142.46-58.31 58.69-58.31 58.69-141.69V-570H240v54.62q0 83.77 60.23 141.88 60.23 58.12 144.77 58.12ZM706.15-630H735q27.31 0 46.15-19.52Q800-669.04 800-696.92q0-26.54-19.23-44.81Q761.54-760 735-760h-28.85v130ZM180-140v-60h600v60H180Zm263.08-430Z"/>',
  coffee_fill:
    '<path d="M445-255.39q-110.08 0-187.54-75.34Q180-406.08 180-515.38V-760q0-24.54 17.73-42.27Q215.46-820 240-820h495q51.85 0 88.42 35.81Q860-748.38 860-696.92q0 52.61-36.39 89.77Q787.23-570 735-570h-28.85v54.62q0 108.53-76.11 184.26-76.12 75.73-185.04 75.73ZM240-630h406.15v-130H240v130Zm466.15 0H735q27.31 0 46.15-19.61Q800-669.23 800-696.92q0-26.54-19.23-44.81Q761.54-760 735-760h-28.85v130ZM180-140v-60h600v60H180Z"/>',
  no_meals:
    '<path d="m831.69-43.39-97.08-97.08V-90h-60v-110.46L51.23-823.85 94-866.61 874.46-86.15l-42.77 42.76Zm-97.08-295.84-61.84-61.85v-7.69h-7.69l-99.69-99.69V-680q0-74.92 48.11-132.08 48.11-57.15 121.11-57.15v530ZM465.38-608.46l-59.99-60V-870h59.99v261.54ZM350-723.85l-60-60V-870h60v146.15ZM234.61-839.23 203.85-870h30.76v30.77ZM290-90v-363.69q-48.69-10.54-82.04-49.27-33.34-38.73-33.34-92.42V-786l59.99 60v130.62H290v-75.23l60.61 59.99V-596h14.62l77.31 77.92q-15.23 24.54-39.31 41.43-24.08 16.88-53.23 22.96V-90h-60Z"/>',
  no_meals_fill:
    '<path d="m831.69-43.39-97.08-97.08V-90h-60v-110.46L51.23-823.85 94-866.61 874.46-86.15l-42.77 42.76Zm-97.08-295.84-61.84-61.85v-7.69h-7.69l-99.69-99.69V-680q0-74.92 48.11-132.08 48.11-57.15 121.11-57.15v530ZM465.38-608.46l-59.99-60V-870h59.99v261.54ZM350-723.85l-60-60V-870h60v146.15ZM234.61-839.23 203.85-870h30.76v30.77ZM290-90v-363.69q-48.69-10.54-82.04-49.27-33.34-38.73-33.34-92.42V-786l59.99 60v130.62H290v-75.23l60.61 59.99V-596h14.62l77.31 77.92q-15.23 24.54-39.31 41.43-24.08 16.88-53.23 22.96V-90h-60Z"/>',
  diversity_3:
    '<path d="M60.39-172.31v-150q0-29 19.65-49.11 19.65-20.12 47.65-20.89H251q18.08 0 34.35 8.85 16.26 8.85 26.5 24.69 29.76 40.92 73.8 63.69 44.04 22.77 94.35 22.77 50.92 0 95.15-22.77 44.24-22.77 73.39-63.69 11.46-15.84 27.42-24.69 15.96-8.85 33.04-8.85h123.31q28.61.77 48.15 20.89Q900-351.31 900-322.31v150H640v-94.08q-34.23 26.16-74.92 40.12-40.7 13.96-85.08 13.96-43.38 0-84.19-14.08-40.81-14.07-75.43-40.23v94.31H60.39ZM480-327.69q-36.08 0-68.35-16.73-32.26-16.73-54.11-46.2-15.46-22.3-37.69-36.42-22.23-14.11-48.39-17.19 25.46-31.23 88.96-48.5Q423.92-510 480-510t119.58 17.27q63.5 17.27 89.34 48.5-25.54 3.08-48.07 17.19-22.54 14.12-38 36.42-20.85 30.47-53.31 46.7-32.46 16.23-69.54 16.23Zm-308.46-120q-45.38 0-77.69-32.31-32.31-32.31-32.31-77.69 0-46.39 32.31-78.19 32.31-31.81 77.69-31.81 46.38 0 78.19 31.81 31.81 31.8 31.81 78.19 0 45.38-31.81 77.69-31.81 32.31-78.19 32.31Zm616.92 0q-45.38 0-77.69-32.31-32.31-32.31-32.31-77.69 0-46.39 32.31-78.19 32.31-31.81 77.69-31.81 46.38 0 78.19 31.81 31.81 31.8 31.81 78.19 0 45.38-31.81 77.69-31.81 32.31-78.19 32.31ZM480-567.69q-45.38 0-77.69-32.31Q370-632.31 370-677.69q0-46.39 32.31-78.19 32.31-31.81 77.69-31.81 46.38 0 78.19 31.81Q590-724.08 590-677.69q0 45.38-31.81 77.69-31.81 32.31-78.19 32.31Z"/>',
  family_restroom:
    '<path d="M720-723.85q-30.46 0-52.15-21.69-21.7-21.69-21.7-52.15 0-30.46 21.7-52.16 21.69-21.69 52.15-21.69 30.46 0 52.15 21.69 21.7 21.7 21.7 52.16 0 30.46-21.7 52.15-21.69 21.69-52.15 21.69ZM674.61-90v-315.39q0-37.48-18.19-67.47-18.19-29.99-49.8-47.22l38.46-106.46q8-23.46 28.73-37.69 20.73-14.23 46.19-14.23 25.46 0 46.19 14.04 20.73 14.04 28.73 37.88L900-332.31H796.92V-90H674.61ZM499.95-501.54q-23.41 0-39.76-16.39-16.34-16.39-16.34-39.81 0-23.41 16.39-39.76 16.39-16.35 39.81-16.35 23.41 0 39.76 16.4 16.34 16.39 16.34 39.8 0 23.42-16.39 39.77-16.39 16.34-39.81 16.34ZM220-723.85q-30.46 0-52.15-21.69-21.7-21.69-21.7-52.15 0-30.46 21.7-52.16 21.69-21.69 52.15-21.69 30.46 0 52.15 21.69 21.7 21.7 21.7 52.16 0 30.46-21.7 52.15-21.69 21.69-52.15 21.69ZM144.62-90v-271.54H81.54v-237.69q0-29.83 21.24-51.07 21.24-21.24 51.07-21.24h132.3q29.83 0 51.07 21.24 21.24 21.24 21.24 51.07v237.69h-63.08V-90H144.62Zm300.77 0v-150h-40v-165.39q0-22.75 15.92-38.68Q437.24-460 460-460h80q22.76 0 38.69 15.93 15.92 15.93 15.92 38.68V-240h-40v150H445.39Z"/>',
  people:
    '<path d="M20-248.46v-48.77q0-39.92 41.69-65.58 41.7-25.65 108.7-25.65 11.07 0 22.3.69t22.62 2.69q-11.69 18.7-17.35 38.81-5.65 20.12-5.65 41.27v56.54H20Zm240 0v-55q0-28.09 15.77-51.35 15.77-23.27 45.46-40.57 29.69-17.31 70.16-25.96 40.46-8.66 88.46-8.66 48.92 0 89.38 8.66 40.46 8.65 70.15 25.96 29.7 17.3 45.16 40.57Q700-331.55 700-303.46v55H260Zm507.69 0v-56.42q0-22.57-5.34-42.54-5.35-19.96-16.04-37.66 11.77-2 22.5-2.69t21.19-.69q67 0 108.5 25.35 41.5 25.34 41.5 65.88v48.77H767.69ZM170.28-427.31q-28.28 0-48.32-20.11t-20.04-48.35q0-28.61 20.12-48.34 20.11-19.73 48.35-19.73 28.61 0 48.53 19.73 19.93 19.73 19.93 48.46 0 27.88-19.72 48.11-19.71 20.23-48.85 20.23Zm619.72 0q-28 0-48.23-20.23-20.23-20.23-20.23-48.11 0-28.73 20.23-48.46t48.3-19.73q28.93 0 48.66 19.73 19.73 19.73 19.73 48.34 0 28.24-19.68 48.35-19.68 20.11-48.78 20.11ZM480.14-460q-43.22 0-73.6-30.29-30.38-30.28-30.38-73.55 0-44.14 30.28-73.99 30.29-29.86 73.56-29.86 44.13 0 73.99 29.82 29.85 29.81 29.85 73.89 0 43.21-29.81 73.6Q524.21-460 480.14-460Z"/>',
  people_fill:
    '<path d="M20-248.46v-48.77q0-39.92 41.69-65.58 41.7-25.65 108.7-25.65 11.07 0 22.3.69t22.62 2.69q-11.69 18.7-17.35 38.81-5.65 20.12-5.65 41.27v56.54H20Zm240 0v-55q0-28.09 15.77-51.35 15.77-23.27 45.46-40.57 29.69-17.31 70.16-25.96 40.46-8.66 88.46-8.66 48.92 0 89.38 8.66 40.46 8.65 70.15 25.96 29.7 17.3 45.16 40.57Q700-331.55 700-303.46v55H260Zm507.69 0v-56.42q0-22.57-5.34-42.54-5.35-19.96-16.04-37.66 11.77-2 22.5-2.69t21.19-.69q67 0 108.5 25.35 41.5 25.34 41.5 65.88v48.77H767.69ZM170.28-427.31q-28.28 0-48.32-20.11t-20.04-48.35q0-28.61 20.12-48.34 20.11-19.73 48.35-19.73 28.61 0 48.53 19.73 19.93 19.73 19.93 48.46 0 27.88-19.72 48.11-19.71 20.23-48.85 20.23Zm619.72 0q-28 0-48.23-20.23-20.23-20.23-20.23-48.11 0-28.73 20.23-48.46t48.3-19.73q28.93 0 48.66 19.73 19.73 19.73 19.73 48.34 0 28.24-19.68 48.35-19.68 20.11-48.78 20.11ZM480.14-460q-43.22 0-73.6-30.29-30.38-30.28-30.38-73.55 0-44.14 30.28-73.99 30.29-29.86 73.56-29.86 44.13 0 73.99 29.82 29.85 29.81 29.85 73.89 0 43.21-29.81 73.6Q524.21-460 480.14-460Z"/>',
  dark_mode:
    '<path d="M481.15-140q-141.66 0-240.83-99.17-99.16-99.16-99.16-240.83 0-135.77 92.11-232.88 92.11-97.12 225.57-105.2 8.62 0 16.93.62 8.3.62 16.3 1.85-30.61 28.61-48.76 69.15-18.16 40.54-18.16 86.46 0 98.33 68.84 167.17Q562.82-424 661.15-424q46.54 0 86.77-18.15 40.23-18.16 68.46-48.77 1.23 8 1.85 16.31.61 8.3.61 16.92-7.69 133.46-104.8 225.57Q616.92-140 481.15-140Z"/>',
  dark_mode_fill:
    '<path d="M481.15-140q-141.66 0-240.83-99.17-99.16-99.16-99.16-240.83 0-135.77 92.11-232.88 92.11-97.12 225.57-105.2 8.62 0 16.93.62 8.3.62 16.3 1.85-30.61 28.61-48.76 69.15-18.16 40.54-18.16 86.46 0 98.33 68.84 167.17Q562.82-424 661.15-424q46.54 0 86.77-18.15 40.23-18.16 68.46-48.77 1.23 8 1.85 16.31.61 8.3.61 16.92-7.69 133.46-104.8 225.57Q616.92-140 481.15-140Z"/>',
  crop_fill:
    '<path d="M680-40v-160H280q-33 0-56.5-23.5T200-280v-400H40v-80h160v-160h80v640h640v80H760v160h-80Zm0-320v-320H360v-80h320q33 0 56.5 23.5T760-680v320h-80Z"/>',
  restaurant_menu_fill:
    '<path d="m175-120-56-56 410-410q-18-42-5-95t57-95q53-53 118-62t106 32q41 41 32 106t-62 118q-42 44-95 57t-95-5l-50 50 304 304-56 56-304-302-304 302Zm118-342L173-582q-54-54-54-129t54-129l248 250-128 128Z"/>',
  circle:
    '<path d="M480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>',
  circle_fill:
    '<path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>',
  check_circle:
    '<path d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Z"/>',
  check_circle_fill:
    '<path d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Z"/>',
  check:
    '<path d="M382-253.85 168.62-467.23 211.38-510 382-339.38 748.62-706l42.76 42.77L382-253.85Z"/>',
  alarm:
    '<path d="M480-100q-70.77 0-132.61-26.77-61.85-26.77-107.85-72.77-46-46-72.77-107.85Q140-369.23 140-440q0-70.77 26.77-132.61 26.77-61.85 72.77-107.85 46-46 107.85-72.77Q409.23-780 480-780q70.77 0 132.61 26.77 61.85 26.77 107.85 72.77 46 46 72.77 107.85Q820-510.77 820-440q0 70.77-26.77 132.61-26.77 61.85-72.77 107.85-46 46-107.85 72.77Q550.77-100 480-100Zm0-340Zm118.92 161.08 42.16-42.16L510-452.15V-640h-60v212.15l148.92 148.93ZM230.15-844.46l42.16 42.15-154.62 154.62-42.15-42.16 154.61-154.61Zm499.7 0 154.61 154.61-42.15 42.16-154.62-154.62 42.16-42.15ZM480-160q116.62 0 198.31-81.69T760-440q0-116.62-81.69-198.31T480-720q-116.62 0-198.31 81.69T200-440q0 116.62 81.69 198.31T480-160Z"/>',
  alarm_fill:
    '<path d="M480-100q-70.77 0-132.61-26.77-61.85-26.77-107.85-72.77-46-46-72.77-107.85Q140-369.23 140-440q0-70.77 26.77-132.61 26.77-61.85 72.77-107.85 46-46 107.85-72.77Q409.23-780 480-780q70.77 0 132.61 26.77 61.85 26.77 107.85 72.77 46 46 72.77 107.85Q820-510.77 820-440q0 70.77-26.77 132.61-26.77 61.85-72.77 107.85-46 46-107.85 72.77Q550.77-100 480-100Zm118.92-178.92 42.16-42.16L510-452.15V-640h-60v212.15l148.92 148.93ZM230.15-844.46l42.16 42.15-154.62 154.62-42.15-42.16 154.61-154.61Zm499.7 0 154.61 154.61-42.15 42.16-154.62-154.62 42.16-42.15Z"/>',
  event:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>',
  event_fill:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>',
  close:
    '<path d="M256-213.85 213.85-256l224-224-224-224L256-746.15l224 224 224-224L746.15-704l-224 224 224 224L704-213.85l-224-224-224 224Z"/>',
  account_balance:
    '<path d="M230-280v-300h60v300h-60Zm220 0v-300h60v300h-60ZM110.77-140v-60h738.46v60H110.77ZM670-280v-300h60v300h-60ZM110.77-660v-56.92L480-897.69l369.23 180.77V-660H110.77Zm141.84-60h454.78-454.78Zm0 0h454.78L480-830 252.61-720Z"/>',
  account_balance_fill:
    '<path d="M230-280v-300h60v300h-60Zm220 0v-300h60v300h-60ZM110.77-140v-60h738.46v60H110.77ZM670-280v-300h60v300h-60ZM110.77-660v-56.92L480-897.69l369.23 180.77V-660H110.77Z"/>',
  credit_score:
    '<path d="M160-623.84h640v-83.85q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v83.85Zm-60-83.85Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v211.53H160v243.85q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h175.15v60H172.31Q142-180 121-201q-21-21-21-51.31v-455.38Zm491.85 598.46L435.69-265.39l42.16-42.15 114 112.77 226.76-226.77 42.16 43.38-268.92 268.93ZM160-707.69v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85H160V-421.15v113.38V-720h12.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46Z"/>',
  credit_score_fill:
    '<path d="M397.31-180h-225Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v211.53H769.46L591.85-318.54l-114-112.77-165.93 165.92L397.31-180Zm194.54 70.77L435.69-265.39l42.16-42.15 114 112.77 226.76-226.77 42.16 43.38-268.92 268.93ZM160-623.84v127.68h640v-127.68H160Z"/>',
  progress_activity:
    '<path d="M479.88-100q-78.03 0-147.33-29.9-69.29-29.9-121.02-81.63-51.73-51.73-81.63-121.02-29.9-69.3-29.9-147.33 0-78.98 29.96-147.97 29.96-69 81.58-120.61 51.61-51.62 121.04-81.58T480-860q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q492.75-800 480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-12.77 8.63-21.38 8.63-8.62 21.38-8.62 12.76 0 21.37 8.63Q860-492.75 860-480q0 77.99-29.96 147.42-29.96 69.43-81.58 121.04-51.61 51.62-120.61 81.58Q558.86-100 479.88-100Z"/>',
  arrow_circle_down:
    '<path d="m480.38-332.31 145.77-145.77-41.77-41.76-74 74v-182.24H450v182.24l-74-74-41.77 41.76 146.15 145.77ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>',
  arrow_back:
    '<path d="M640-93.85 253.85-480 640-866.15l56.77 56.77L367.38-480l329.39 329.38L640-93.85Z"/>',
  arrow_forward:
    '<path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/>',
  cancel:
    '<path d="m336-293.85 144-144 144 144L666.15-336l-144-144 144-144L624-666.15l-144 144-144-144L293.85-624l144 144-144 144L336-293.85ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Z"/>',
  list: '<path d="M293.08-597.69v-60H820v60H293.08Zm0 147.69v-60H820v60H293.08Zm0 147.69v-60H820v60H293.08ZM172.31-595.38q-13.73 0-23.02-9.4t-9.29-23.3q0-13.56 9.29-22.74 9.29-9.18 23.02-9.18t23.02 9.18q9.29 9.18 9.29 22.74 0 13.9-9.29 23.3t-23.02 9.4Zm0 147.3q-13.73 0-23.02-9.18Q140-466.43 140-480q0-14.31 9.29-23.5t23.02-9.19q13.73 0 23.02 9.19t9.29 23.5q0 13.57-9.29 22.74-9.29 9.18-23.02 9.18Zm0 148.08q-13.73 0-23.02-9.4T140-332.69q0-13.57 9.29-22.75t23.02-9.18q13.73 0 23.02 9.18t9.29 22.75q0 13.89-9.29 23.29-9.29 9.4-23.02 9.4Z"/>',
  content_copy:
    '<path d="M362.31-260Q332-260 311-281q-21-21-21-51.31v-455.38Q290-818 311-839q21-21 51.31-21h335.38Q728-860 749-839q21 21 21 51.31v455.38Q770-302 749-281q-21 21-51.31 21H362.31Zm0-60h335.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H362.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm-140 200Q192-120 171-141q-21-21-21-51.31v-515.38h60v515.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h395.38v60H222.31ZM350-320v-480 480Z"/>',
  visibility:
    '<path d="M480.09-336.92q67.99 0 115.49-47.59t47.5-115.58q0-67.99-47.59-115.49t-115.58-47.5q-67.99 0-115.49 47.59t-47.5 115.58q0 67.99 47.59 115.49t115.58 47.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm.05 172q-137.97 0-251.43-76.12Q115.16-372.23 61.54-500q53.62-127.77 167.02-203.88Q341.97-780 479.95-780q137.97 0 251.43 76.12Q844.84-627.77 898.46-500q-53.62 127.77-167.02 203.88Q618.03-220 480.05-220ZM480-500Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/>',
  visibility_off:
    '<path d="M630.92-441.08 586-486q9-49.69-28.35-89.35Q520.31-615 466-606l-44.92-44.92q13.54-6.08 27.77-9.12 14.23-3.04 31.15-3.04 68.08 0 115.58 47.5T643.08-500q0 16.92-3.04 31.54-3.04 14.61-9.12 27.38Zm127.23 124.46L714-358q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-46.61-46.61q37.92-15.08 77.46-22.23Q438.39-780 480-780q140.61 0 253.61 77.54T898.46-500q-22.23 53.61-57.42 100.08-35.2 46.46-82.89 83.3Zm32.31 231.39L628.62-245.85q-30.77 11.39-68.2 18.62Q523-220 480-220q-141 0-253.61-77.54Q113.77-375.08 61.54-500q22.15-53 57.23-98.88 35.08-45.89 77.23-79.58l-110.77-112 42.16-42.15 705.22 705.22-42.15 42.16Zm-552.3-551.08q-31.7 25.23-61.66 60.66Q146.54-540.23 128-500q50 101 143.5 160.5T480-280q27.31 0 54.39-4.62 27.07-4.61 45.92-9.53L529.69-346q-10.23 4.15-23.69 6.61-13.46 2.47-26 2.47-68.08 0-115.58-47.5T316.92-500q0-12.15 2.47-25.42 2.46-13.27 6.61-24.27l-87.84-86.62ZM541-531Zm-131.77 65.77Z"/>',
  pin_drop:
    '<path d="M480-215Q350.15-315.54 285.08-411.42 220-507.31 220-596.69q0-115.39 72.62-189.35Q365.23-860 480-860t187.38 73.96Q740-712.08 740-596.69q0 89.38-65.08 185.07Q609.85-315.92 480-215Zm.07-312.69q29.85 0 51.04-21.26 21.2-21.26 21.2-51.12 0-29.85-21.26-51.04-21.26-21.2-51.12-21.2-29.85 0-51.04 21.26-21.2 21.26-21.2 51.12 0 29.85 21.26 51.04 21.26 21.2 51.12 21.2ZM220-100v-60h520v60H220Z"/>',
  map: '<path d="m600-141.54-240-84-172.15 66.61q-17.69 6.85-32.77-3.92Q140-173.62 140-192.31v-524.61q0-11.85 6.35-21.27 6.34-9.42 17.81-13.65L360-818.46l240 84 172.15-66.61q17.69-6.85 32.77 3.34Q820-787.54 820-769.23v527.69q0 12.23-6.92 21.46-6.93 9.23-18.77 13.46L600-141.54Zm-30-73.38v-468l-180-62.93v468l180 62.93Z"/>',
  verified:
    '<path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM438-351.85 650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z"/>',
  brightness_3:
    '<path d="M485.46-186.38q90-36.62 144.19-116.47 54.2-79.84 54.2-177.15t-54.2-177.15Q575.46-737 485.46-773.62q51.92 61.62 79.19 137 27.27 75.39 27.27 156.62t-27.27 156.62q-27.27 75.38-79.19 137ZM363.85-100q-20.62 0-40.23-2.77-19.62-2.77-39-8.31 112.31-46.38 179.8-146.77 67.5-100.38 67.5-222.15 0-121.77-67.5-222.15-67.49-100.39-179.8-146.77 19.38-5.54 39-8.31 19.61-2.77 40.23-2.77 78.85 0 148.2 29.92t120.65 81.21q51.3 51.29 81.22 120.63t29.92 148.17q0 78.84-29.92 148.21T632.7-211.18q-51.3 51.31-120.65 81.25Q442.7-100 363.85-100Zm228.07-380Z"/>',
  brightness_3_fill:
    '<path d="M363.85-100q-21.05 0-40.45-2.77-19.4-2.77-38.78-8.31 112.92-46.38 180.11-146.77Q531.92-358.23 531.92-480q0-121.77-67.19-222.15-67.19-100.39-180.11-146.77 19.38-5.54 38.78-8.31 19.4-2.77 40.45-2.77 78.85 0 148.2 29.92t120.65 81.21q51.3 51.29 81.22 120.63t29.92 148.17q0 78.84-29.92 148.21T632.7-211.18q-51.3 51.31-120.65 81.25Q442.7-100 363.85-100Z"/>',
  brightness_alert:
    '<path d="M480-290.77q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm-30-146.15h60v-240h-60v240Zm30 381.23L354.38-180H180v-174.38L55.69-480 180-605.62V-780h174.38L480-904.31 605.62-780H780v174.38L904.31-480 780-354.38V-180H605.62L480-55.69Zm0-84.31 100-100h140v-140l100-100-100-100v-140H580L480-820 380-720H240v140L140-480l100 100v140h140l100 100Zm0-340Z"/>',
  brightness_alert_fill:
    '<path d="M480-290.77q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm-30-146.15h60v-240h-60v240Zm30 381.23L354.38-180H180v-174.38L55.69-480 180-605.62V-780h174.38L480-904.31 605.62-780H780v174.38L904.31-480 780-354.38V-180H605.62L480-55.69Z"/>',
  delete:
    '<path d="M292.31-140Q262-140 241-161q-21-21-21-51.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31Zm83.85-140h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360Z"/>',
  settings:
    '<path d="m387.69-100-15.23-121.85q-16.07-5.38-32.96-15.07-16.88-9.7-30.19-20.77L196.46-210l-92.3-160 97.61-73.77q-1.38-8.92-1.96-17.92-.58-9-.58-17.93 0-8.53.58-17.34t1.96-19.27L104.16-590l92.3-159.23 112.46 47.31q14.47-11.46 30.89-20.96t32.27-15.27L387.69-860h184.62l15.23 122.23q18 6.54 32.57 15.27 14.58 8.73 29.43 20.58l114-47.31L855.84-590l-99.15 74.92q2.15 9.69 2.35 18.12.19 8.42.19 16.96 0 8.15-.39 16.58-.38 8.42-2.76 19.27L854.46-370l-92.31 160-112.61-48.08q-14.85 11.85-30.31 20.96-15.46 9.12-31.69 14.89L572.31-100H387.69Zm92.77-260q49.92 0 84.96-35.04 35.04-35.04 35.04-84.96 0-49.92-35.04-84.96Q530.38-600 480.46-600q-50.54 0-85.27 35.04T360.46-480q0 49.92 34.73 84.96Q429.92-360 480.46-360Z"/>',
};
