import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../assets/TakyonDesignSystem/components/Select/Select";
import { useTranslation } from "react-i18next";
import { PHONE_CODES } from "../../../../config/PhoneCodes";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/userSlice";
import UserController from "../../../../assets/Controllers/UserController";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import { closeModal, openModal } from "../../../../assets/Utils/modal";

function DeleteTakyonAccount() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteAccount = async () => {
    setIsLoading(true);
    try {
      await UserController.deleteAccount();
      UserController.logout();
      closeModal();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-column p-4">
      <span className="bodytext medium">
        {t("wallet.settings.deleteaccount")}
      </span>
      <div className="height-16"></div>
      <span>{t("wallet.settings.deleteaccountconfirmation")}</span>
      <div className="height-16"></div>

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => deleteAccount()}
          text={t("wallet.settings.deleteaccount")}
        />
      </div>
    </div>
  );
}

export default function Account() {
  const { t } = useTranslation();
  const user = useSelector(selectUser)!;
  const [isLoading, setIsLoading] = useState(false);

  // validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    firstName: Yup.string().required("Required"),
    secondName: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    phonePrefix: Yup.string().required("Required"),
    lang: Yup.string(),
  });

  const storeAccountData = async (formValues: any) => {
    setIsLoading(true);
    try {
      await UserController.patchUser(formValues);
      await UserController.refreshUserInfo();

      // closeWalletPages("AccountSettingsPage");
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  if (!user || isLoading) {
    // Se initialValues non è ancora pronto, mostra un indicatore di caricamento
    return <div>Loading user info...</div>;
  }

  return (
    <div id="account_section">
      <Formik
        validationSchema={validationSchema}
        onSubmit={storeAccountData}
        initialValues={{
          email: user?.email,
          firstName: user?.firstName,
          secondName: user?.secondName,
          phone: user?.phone,
          phonePrefix: user?.phonePrefix,
          lang: user?.lang,
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="accountEmail">
              <Form.Label>{t("profile_page.email")}</Form.Label>
              <Form.Control
                disabled={values.email !== undefined}
                name="email"
                value={values.email}
                onChange={handleChange}
                type="email"
                placeholder="Enter email"
                isInvalid={
                  errors.email !== undefined && touched.email !== undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="accountFirstName">
              <Form.Label>{t("profile_page.name")}</Form.Label>
              <Form.Control
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                type="text"
                placeholder="Enter name"
                isInvalid={
                  errors.firstName !== undefined &&
                  touched.firstName !== undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName && touched.firstName
                  ? errors.firstName
                  : null}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="accountSecondName">
              <Form.Label>{t("profile_page.surname")}</Form.Label>
              <Form.Control
                name="secondName"
                type="text"
                value={values.secondName}
                onChange={handleChange}
                placeholder="Enter second name"
                isInvalid={
                  errors.secondName !== undefined &&
                  touched.secondName !== undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.secondName && touched.secondName
                  ? errors.secondName
                  : null}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex gap-2">
              <Form.Group controlId="accountPhonePrefix">
                <Form.Label>{t("auth.prefix")}*</Form.Label>
                <Form.Select
                  name="phonePrefix"
                  value={values.phonePrefix}
                  onChange={handleChange}
                  isInvalid={
                    errors.phonePrefix !== undefined &&
                    touched.phonePrefix !== undefined
                  }
                >
                  {[
                    { value: "", text: "+00" },
                    ...PHONE_CODES.map((e) => {
                      return {
                        value: e.code,
                        text: `${e.country} ${e.code}`,
                      };
                    }),
                  ].map((e) => (
                    <option key={`${e.value}_${e.text}`} value={e.value}>
                      {e.text}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.phonePrefix && touched.phonePrefix
                    ? errors.phonePrefix
                    : null}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="accountPhone" className="flex-grow-1">
                <Form.Label>{t("auth.phone")}</Form.Label>
                <Form.Control
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter phone number"
                  isInvalid={
                    errors.phone !== undefined && touched.phone !== undefined
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone && touched.phone ? errors.phone : null}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form.Group controlId="accountLanguage">
              <Form.Label>{t("profile_page.language")}</Form.Label>
              <Form.Select
                name="lang"
                value={values.lang}
                onChange={handleChange}
                isInvalid={
                  errors.lang !== undefined && touched.lang !== undefined
                }
              >
                <option value="en">{t("profile_page.en")}</option>
                <option value="it">{t("profile_page.it")}</option>
                <option value="es">{t("profile_page.es")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.lang && touched.lang ? errors.lang : null}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-between">
              <div></div>
              {/* <span
                onClick={() =>
                  openModal({
                    content: <DeleteTakyonAccount />,
                  })
                }
                className="cursor-pointer danger-color"
              >
                {t("wallet.settings.delete")}
              </span> */}

              <Button size="sm" type="submit" text={t("forms.savechanges")} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
