import axios from "axios";
import { iNft, payloadExperienceType, payloadHotelType } from "../models/iNft";
import { COLLECTION_TYPE } from "../models/iCollection";

export function getCityFromLocation(location: any) {
  try {
    let city = location?.label;
    const addressComponents = location?.address_components as any;

    let found = false;
    Object.keys(addressComponents).forEach((key: any) => {
      const component = addressComponents[key];
      if (component.types?.includes("locality")) {
        city = component.long_name;
        found = true;
      }
    });

    if (!found) {
      Object.keys(addressComponents).forEach((key: any) => {
        const component = addressComponents[key];
        if (component.types?.includes("administrative_area_level_3")) {
          city = component.long_name;
        }
      });
    }

    return city;
  } catch (error) {
    return "";
  }
}

// export function getCity(nft: iNft): string {
//   let city = nft.payload?.location?.label ?? "";
//   const addressComponents = nft.payload?.location?.address_components as any;

//   if (!addressComponents) return city;

//   let found = false;
//   Object.keys(addressComponents).forEach((key: any) => {
//     const component = addressComponents[key];
//     if (component.types?.includes("locality")) {
//       city = component.long_name;
//       found = true;
//     }
//   });

//   if (!found) {
//     Object.keys(addressComponents).forEach((key: any) => {
//       const component = addressComponents[key];
//       if (component.types?.includes("administrative_area_level_3")) {
//         city = component.long_name;
//       }
//     });
//   }

//   return city;
// }

// export function getCountry(nft: iNft): string {
//   let country = "";
//   const addressComponents = nft.payload?.location?.address_components as any;

//   if (!addressComponents) return country;

//   Object.keys(addressComponents).forEach((key: any) => {
//     const component = addressComponents[key];
//     if (component.types?.includes("country")) {
//       country = component.long_name;
//     }
//   });

//   return country;
// }

export function getCountryFromLocation(location: any): string {
  try {
    let country = "";
    const addressComponents = location?.address_components as any;

    if (!addressComponents) return country;

    Object.keys(addressComponents).forEach((key: any) => {
      const component = addressComponents[key];
      if (component.types?.includes("country")) {
        country = component.long_name;
      }
    });

    return country;
  } catch (error) {
    return "";
  }
}

// export function getCityShort(nft: iNft): string {
//   let city = "";
//   const addressComponents = nft.payload?.location?.address_components as any;

//   if (!addressComponents) return city;

//   Object.keys(addressComponents).forEach((key: any) => {
//     const component = addressComponents[key];
//     if (
//       component.types?.includes("political") &&
//       component.types?.includes("administrative_area_level_2")
//     ) {
//       city = component.short_name;
//     }
//   });

//   return city;
// }

export function getCityShortFromLocation(location: any): string {
  try {
    let city = "";
    const addressComponents = location?.address_components as any;

    if (!addressComponents) return city;

    Object.keys(addressComponents).forEach((key: any) => {
      const component = addressComponents[key];
      if (
        component.types?.includes("political") &&
        component.types?.includes("administrative_area_level_2")
      ) {
        city = component.short_name;
      }
    });

    return city;
  } catch (error) {
    return "";
  }
}

export function getRegionFromLocation(location: any): string {
  try {
    let city = "";
    const addressComponents = location?.address_components as any;

    if (!addressComponents) return city;

    Object.keys(addressComponents).forEach((key: any) => {
      const component = addressComponents[key];
      if (
        component.types?.includes("political") &&
        component.types?.includes("administrative_area_level_1")
      ) {
        city = component.short_name;
      }
    });

    return city;
  } catch (error) {
    return "";
  }
}

export function getProvinceShortNameFromLocation(location: any): string {
  try {
    let city = "";
    const addressComponents = location?.address_components as any;

    if (!addressComponents) return city;

    Object.keys(addressComponents).forEach((key: any) => {
      const component = addressComponents[key];
      if (
        component.types?.includes("political") &&
        component.types?.includes("administrative_area_level_2")
      ) {
        city = component.short_name;
      }
    });

    return city;
  } catch (error) {
    return "";
  }
}

export async function getPlaceCoordinates(placeId: string) {
  const response = await axios.get("/google/coordinates/" + placeId);
  return response.data;
}

export function getAmountOfGuests(nft: iNft): {
  adults: number;
  childs: number;
} {
  if (nft.payload.type === COLLECTION_TYPE.hotel || !nft.payload.type) {
    const adults =
      (nft.payload as payloadHotelType)?.rooms?.reduce((total, room) => {
        return total + (room.guestsAdults ?? 0);
      }, 0) ?? 0;
    const childs =
      (nft.payload as payloadHotelType)?.rooms?.reduce((total, room) => {
        return total + (room.guestsKids ?? 0);
      }, 0) ?? 0;

    return { adults, childs };
  }

  if (nft.payload.type === COLLECTION_TYPE.experience) {
    return {
      adults: (nft.payload as payloadExperienceType).participants ?? 0,
      childs: 0,
    };
  }

  return { adults: 0, childs: 0 };
}
