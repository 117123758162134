import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { iPagination, iPaginationProps } from "../models/iPagination";
import { Guest, iNft } from "../models/iNft";
import { SearchCriteria } from "../../_Web/components/Nav/SearchBox/SearchBoxStay";

export default class NftController {
  private static cancelTokenSource: CancelTokenSource | null = null;
  static async getPaginated(
    params: iPaginationProps,
    cache?: boolean
  ): Promise<iPagination<iNft>> {
    const response = await axios.get("/nft/paginate", {
      params: {
        ...params,
        cache,
      },
    });
    return response.data;
  }

  static async getNft(id: string): Promise<iNft> {
    const response = await axios.get("/nft/" + id);
    return response.data;
  }

  static async getAmountOfNfts(): Promise<number> {
    const response = await axios.get("/nft/amount");
    return response.data;
  }

  static async retrieveFromEmailHash(payload: { hash: string }): Promise<iNft> {
    const response = await axios.post("/nft/retrieve-from-hash", payload);
    return response.data;
  }

  static async putNftForSale(payload: {
    nftId: string;
    price: number;
  }): Promise<iNft> {
    const response = await axios.post("/nft/sale", payload);
    return response.data;
  }

  static async changeNftPrice(payload: {
    nftId: string;
    price: number;
  }): Promise<iNft> {
    const response = await axios.post("/nft/change-price", payload);
    return response.data;
  }

  static async removeNftFromSale(payload: { nftId: string }): Promise<iNft> {
    const response = await axios.delete("/nft/sale", { data: payload });
    return response.data;
  }

  static async changeGuestsNft(payload: {
    nftId: string;
    guests: Guest[];
  }): Promise<iNft> {
    const response = await axios.post("/nft/change-guests", payload);
    return response.data;
  }

  static async transferNft(payload: { nftId: string; email: string }) {
    const response = await axios.post("/nft/transfer", payload);
    return response.data;
  }

  /*static async searchNft(payload: SearchCriteria): Promise<any> {
    // If there's a pending request, cancel it
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Operation canceled due to new request.");
    }

    // Create a new CancelToken source
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const response: AxiosResponse<any> = await axios.post(
        "/search",
        payload,
        {
          cancelToken: this.cancelTokenSource.token,
        }
      );

      // Clear the cancel token after the request completes
      this.cancelTokenSource = null;

      return response.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        // Handle error
        console.error("Error making request:", error);
        throw error; // Re-throw the error if it's not a cancel error
      }
    }
  }*/
  //ToDO: da vedere se si riesce ad abortire le chiamate in pending prima di effettuarne un'altra
  static async searchNft(payload: SearchCriteria) {
    const response = await axios.post("/search", payload);
    return response.data;
  }

  static async getGaranteed(payload: { nftId: string }) {
    const response = await axios.post("/nft/guaranteed", payload);
    return response.data;
  }

  static async getBids(nftId: string) {
    const response = await axios.get(`/nft/${nftId}/bids`);
    return response.data;
  }
}
