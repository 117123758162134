import { useEffect, useState } from "react";
import { secondsToFormat } from "../../../Utils/generic";

interface Props {
  startTime?: Date;
  style?: String;
  onFinishedCountdown?: () => void;
}

export default function Countdown(props: Props) {
  const [countdown, setCountdown] = useState<number>();

  useEffect(() => {
    const t = props.startTime
      ? (props.startTime.getTime() - new Date().getTime()) / 1000
      : 0;
    setCountdown(t);
  }, []);

  const tid = setInterval(() => {
    setCountdown((countdown) => {
      const t = props.startTime
        ? (props.startTime.getTime() - new Date().getTime()) / 1000
        : 0;
      countdown = t;
      if (t <= 0) {
        clearInterval(tid);
        if (props.onFinishedCountdown) {
          props.onFinishedCountdown();
        }
      }
      return countdown;
    });
  }, 1000);

  if (!props.startTime) {
    return null;
  }

  return (
    <span className={props.style ? `countdown ${props.style}` : ""}>
      {countdown && secondsToFormat(countdown)}
    </span>
  );
}
