import { useMemo } from "react";
import { BID_MODE, BID_STATUS, iBid } from "../../../models/iBid";
import { datetimeToString } from "../../../Utils/generic";
import { useTranslation } from "react-i18next";
import TakBidActions from "./TakBidActions";
import TakBidStatus from "./TakBidStatus";
import helpers from "../../../../_Web/helpers/helpers";

export default function TakBid({
  offer,
  isMostRecent,
}: {
  offer: iBid;
  isMostRecent: boolean;
}) {
  const { t } = useTranslation();

  const offerDate = useMemo(() => {
    const offerDate = offer._updatedAt ?? offer._createdAt;
    return datetimeToString(offerDate, undefined, undefined, true);
  }, [offer._createdAt]);

  const actionsVisible = useMemo(() => {
    if (
      offer.status === BID_STATUS.PENDING ||
      offer.status === BID_STATUS.BUYER_COUNTEROFFER ||
      offer.status === BID_STATUS.SELLER_COUNTEROFFER
    ) {
      return true;
    } else {
      return isMostRecent;
    }
  }, []);

  const offerFailed = useMemo(() => {
    return (
      offer.status === BID_STATUS.DECLINED ||
      offer.status === BID_STATUS.DELETED
    );
  }, [offer.status]);

  const offerActivityLog = useMemo(() => {
    if (offer._side === "seller") {
      if (offer.status === BID_STATUS.ACCEPTED) {
        return `${t("wallet.offers.youaccepted")} ${helpers.price(
          offer.amount.toString() // TOFIX
        )}`;
      } else if (
        offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
        offer.counterOfferAmount
      ) {
        return `${t("wallet.offers.receivedofcouter")} ${helpers.price(
          offer.counterOfferAmount.toString()
        )}`;
      } else if (
        offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
        offer.counterOfferAmount
      ) {
        return `${t("wallet.offers.sentofcounter")} ${helpers.price(
          offer.counterOfferAmount.toString()
        )}`;
      } else {
        return `${t("wallet.offers.receivedof")} ${helpers.price(
          offer.amount.toString()
        )}`;
      }
    }
    if (offer._side === "buyer") {
      if (offer.status === BID_STATUS.ACCEPTED) {
        return `${t("wallet.offers.buyeraction_accepted", {
          ownerShort: offer._buyerName,
        })}`;
      } else if (offer.status === BID_STATUS.BUYER_COUNTEROFFER) {
        return `${t("wallet.offers.sentofcounter")} ${helpers.price(
          offer.counterOfferAmount?.toString() ?? ""
        )}`;
      } else if (offer.status === BID_STATUS.DECLINED) {
        return `${t("wallet.offers.buyeraction_declined", {
          ownerShort: offer._buyerName,
        })}`;
      } else if (
        offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
        offer.counterOfferAmount
      ) {
        return `${t("wallet.offers.receivedofcouter")} ${helpers.price(
          offer.counterOfferAmount.toString()
        )}`;
      } else {
        return `${t("wallet.offers.sentof")} ${helpers.price(
          offer.amount.toString()
        )}`;
      }
    }
  }, [offer.status]);

  return (
    <div className="d-flex justify-content-between py-3 px-4 flex-column flex-xxl-row">
      <div className="d-flex flex-column">
        {/* sfondo opacity */}
        <div>
          <p className="bodytext-sm light">
            {offerDate}
            {/* - {offer.status} */}
          </p>
          <p className={`bodytext ${offerFailed ? "text-dark-grey" : ""}`}>
            {offerActivityLog}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <TakBidStatus offer={offer} />
        {/* only newest action can have actions */}
        {actionsVisible && <TakBidActions offer={offer} />}
      </div>
    </div>
  );
}
