import { Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Skeleton from "react-loading-skeleton";

import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import { iPartner } from "../../../assets/models/iPartner";
import { apiErrorToast } from "../../../assets/Utils/errors";
import PartnerController from "../../../assets/Controllers/PartnerController";
import PartnerCard from "../../../assets/TakyonDesignSystem/components/PartnerCard/PartnerCard";

import {
  getCityFromLocation,
  getPlaceCoordinates,
} from "../../../assets/Utils/location";
import { cloudinaryToJpg } from "../../../assets/Utils/generic";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";

import markerIcon from "../../../assets/TakyonDesignSystem/images/markerIcon.png";
import circle from "../../../assets/TakyonDesignSystem/images/circle.png";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { useTranslation } from "react-i18next";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useMediaQuery } from "react-responsive";
import CollectionController from "../../../assets/Controllers/CollectionController";

const defaultCenter = { lat: 42, lng: 10 };
const defaultZoom = 5.5;
const LIBRARIES = ["places"];

export const mapStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        // color: "#BADABF",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.stroke",
    stylers: [
      {
        weight: "1",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#CCD6E9",
      },
      {
        visibility: "on",
      },
    ],
  },
];

function Map() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [allPartners, setAllPartners] = useState<iPartner[]>([]);
  const [filteredPartners, setFilteredPartners] = useState<iPartner[]>([]);

  const [map, setMap] = useState<google.maps.Map>();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(true);

  const [scroll, setScroll] = useState(false);

  const [infoWindows, setInfoWindows] = useState<
    {
      id: string;
      window: google.maps.InfoWindow;
    }[]
  >([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_API_KEY),
    libraries: LIBRARIES as any,
  });

  const loadAllPartners = async () => {
    setIsLoading(true);

    try {
      const data = await PartnerController.getPaginated(
        {
          page: 1,
          size: 10000,
        },
        true
      );

      setFilteredPartners(data.data);
      setAllPartners(data.data);
    } catch (error) {
      apiErrorToast(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadAllPartners();
  }, []);

  const centerOnPartner = async (partner: iPartner, force = true) => {
    const bounds = map?.getBounds()?.toJSON();
    const coords = partner?.location?.coordinates as any;
    const zoom = Number(map?.getZoom());

    const bool =
      (bounds &&
        coords?.lat > bounds.north &&
        coords?.lat < bounds.south &&
        coords?.lng > bounds.east &&
        coords?.lng < bounds.west) ||
      zoom < 14 ||
      force;

    if (bool) {
      map?.panTo(coords);
      map?.setZoom(14);
    }
  };

  const searchCoords = (bounds: any) => {
    const filtered = allPartners.filter((p) => {
      const coords = p.location?.coordinates as {
        lng: number;
        lat: number;
      };

      return (
        coords?.lat < bounds.north &&
        coords?.lat > bounds.south &&
        coords?.lng < bounds.east &&
        coords?.lng > bounds.west
      );
    });

    if (filtered?.length) {
      setFilteredPartners(filtered);
    } else {
      setFilteredPartners([]);
    }
  };

  const updateCoords = (map: any) => {
    const bounds = map.getBounds()?.toJSON();
    setIsOpen(false);

    if (!bounds) return;

    searchCoords(bounds);
  };

  const getAddressComp = async (e: any) => {
    let coords: any = {};

    try {
      coords = await getPlaceCoordinates(e.value.place_id);
      map?.panTo(coords);
      map?.setZoom(14);
      updateCoords(map);

      allPartners?.forEach((p) => {
        try {
          const hotelCoord = p.location?.coordinates as {
            lng?: number;
            lat?: number;
          };

          if (!hotelCoord) return;

          if (
            hotelCoord.lat?.toFixed(3) === coords.lat.toFixed(3) &&
            hotelCoord.lng?.toFixed(3) === coords.lng.toFixed(3)
          ) {
            centerOnPartner(p);
          }
        } catch (error) {
          console.log("warning");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openInfoWindow = (partner: iPartner) => {
    closeAllInfoWindows();

    const infoWindow = getInfoWindow(partner);
    infoWindow?.window.open(map);

    setInfoWindows([...infoWindows, infoWindow]);
  };

  const closeAllInfoWindows = () => {
    infoWindows.forEach((i) => i.window.close());
  };

  const getInfoWindow = (p: iPartner) => {
    const numberOfStars = p.stars?.substring(0, 1);
    let stars = ``;
    if (!isNaN(Number(numberOfStars))) {
      for (let i = 0; i < 5; i++) {
        if (i < Number(numberOfStars))
          stars += `<span class="material-symbols-outlined star">star</span>`;
      }
    }

    return {
      id: String(p._id),
      window: new google.maps.InfoWindow({
        content: `<div class="map-info-container">
          <div class="img-container">
            <img class="logo" src=${cloudinaryToJpg(
              String(p.logo)
            )} alt="logo" />
            <div class="height-12"></div>
            <div class="hotel-name">
              <span>${p.name}</span>
            </div>
            <div class="stars">
              ${stars}
            </div>
          </div>
          <br/>

          <div class="map-info-text">
            <p class="dark-grey-color">${p.location?.label}</p>
            <a href="${
              p.website
            }" class="website" target="_blank">Visit Website</a>
            <div class="height-25"></div>

          </div>

        </div>` as any,
        ariaLabel: p?.name,
        position: p?.location?.coordinates as any,
      }),
    };
  };

  return (
    <>
      {isLoaded && !isLoading ? (
        <section className="partners-page-container">
          <div className="partner-overlay">
            <div className="search">
              {inputValue ? (
                <GooglePlacesAutocomplete
                  selectProps={{
                    placeholder: String(t("partners.search_location")),
                    onInputChange: () => setIsOpen(false),
                    onChange: (e: any) => {
                      setInputValue(e);
                      getAddressComp(e);
                    },
                  }}
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["es", "it", "fr"] },
                  }}
                />
              ) : null}
              <div
                onClick={() => {
                  setIsOpen(false);
                  setFilteredPartners(allPartners ?? []);

                  setInputValue(false);
                  setTimeout(() => setInputValue(true), 1);

                  map?.setCenter(defaultCenter);
                  map?.setZoom(defaultZoom);
                }}
                className="close"
              >
                x
              </div>
            </div>

            {filteredPartners.length === 0 ? (
              <small className="text-center">There is not result to show</small>
            ) : null}
            <div
              className={`partner-scroll-container ${
                scroll ? "scrolling" : ""
              }`}
            >
              <div
                onScroll={() => setScroll(true)}
                className={`partner-scroll ${isOpen ? "open" : "closed"} `}
              >
                {filteredPartners?.map((p, key) => {
                  if (!p) return <></>;

                  const city = getCityFromLocation(p.location);

                  return (
                    <div
                      onClick={() => {
                        centerOnPartner(p, true);
                        openInfoWindow(p);
                      }}
                      className={`partner-box`}
                      key={"partner_" + key}
                    >
                      <div className="image-container bodytext-xs">
                        <img
                          className="logo"
                          src={cloudinaryToJpg(String(p.image))}
                          alt={`takyon ${p.name} partner img`}
                        />
                      </div>

                      <div className="right">
                        <div>
                          <p className="partner-location bodytext-sm dark-grey-color">
                            {city}
                          </p>

                          <p className="partner-name bodytext-sm medium">
                            {p.name}
                          </p>

                          <p>
                            <small>
                              {p.stars ? <Stars stars={p?.stars} /> : null}
                            </small>
                          </p>
                        </div>

                        <div className="d-flex align-items-center justify-content-between w100">
                          <div className="location dark-grey-color bodytext-xs">
                            {p.location?.label}
                          </div>

                          <a
                            className="link primary-background black-color"
                            href={p.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              CollectionController.clickOnCollection(
                                String(p.collectionId ?? p._id),
                                { source: "partner_page" }
                              );

                              fireTagManagerEvent("link_partner", {
                                name: p.name,
                                index: 1,
                              });
                              e.stopPropagation();
                            }}
                          >
                            <span className="material-symbols-outlined">
                              north_east
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <GoogleMap
            onClick={() => {
              closeAllInfoWindows();
              setIsOpen(false);
            }}
            mapContainerClassName="map-container"
            center={defaultCenter}
            zoom={defaultZoom}
            options={{
              styles: mapStyle as any,
              zoomControl: true,
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
            }}
            onLoad={(map) => {
              setMap(map);

              map.addListener("dragend", () => updateCoords(map));
              map.addListener("zoom_changed", () => updateCoords(map));
            }}
          >
            <MarkerClusterer
              options={{
                maxZoom: 9,
                styles: [
                  {
                    url: circle,
                    height: 35,
                    width: 35,
                    textColor: "#000",
                    textSize: 15,
                  },
                ],
              }}
            >
              {(clusterer) =>
                allPartners?.map((partner, key) => (
                  <Marker
                    onClick={() => {
                      openInfoWindow(partner);
                      centerOnPartner(partner, false);

                      CollectionController.clickOnCollection(
                        String(partner.collectionId ?? partner._id),
                        { source: "partner_page" }
                      );
                    }}
                    key={"partner_" + key}
                    position={partner?.location?.coordinates as any}
                    icon={{
                      url: markerIcon,
                      scaledSize: new window.google.maps.Size(45, 55),
                    }}
                    clusterer={clusterer}
                  />
                )) as any
              }
            </MarkerClusterer>
          </GoogleMap>
        </section>
      ) : null}
    </>
  );
}

function PartnerList() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPartners, setTotalPartners] = useState(0);
  const [paginatedPartners, setPaginatedPartners] = useState<iPartner[]>([]);

  const [filter, setFilter] = useState("");

  const isMediumScreen = useMediaQuery({ query: "(max-width: 1200px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const loadPaginatedPartners = async (filter?: string, page?: number) => {
    setFilter(filter || "");
    setIsLoading(true);

    try {
      const data = await PartnerController.getPaginated(
        {
          page: page || 1,
          size: 10000,
          query: JSON.stringify({
            $or: [
              { name: { $regex: filter, $options: "i" } },
              { "location.label": { $regex: filter, $options: "i" } },
            ],
          }),
        },
        true
      );

      const total = data.total;
      setTotalPartners(total);

      setPaginatedPartners(data.data);
    } catch (error) {
      apiErrorToast(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadPaginatedPartners(filter, currentPage);
  }, [filter, currentPage]);

  const searchCallbackDebounced = useRef(
    debounce((value: string) => loadPaginatedPartners(value, currentPage), 500)
  );

  // const handlePaginationClick = (page: number) => {
  //   setCurrentPage(page);
  // };

  return (
    <section className="container h100 d-flex flex-column">
      <div className="content h100 d-flex flex-column">
        <div className="height-100"></div>

        <div className="h100 d-flex flex-column">
          <Form.Group
            style={{
              padding: "0px 26px",
              width: "100%",
            }}
          >
            <div
              style={{
                boxShadow: "4px 3px 10px 1px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Input
                onChange={(e) => {
                  setIsLoading(true);
                  searchCallbackDebounced.current(e.target.value);
                }}
                icon="search"
                type="text"
                placeholder={t("partners.search")}
              />
            </div>
          </Form.Group>

          <div className="height-25"></div>

          {isLoading && (
            <div>
              <div className="d-flex justify-content-center gap-4">
                <Skeleton height={150} width={150} />
                <Skeleton height={150} width={150} />
                <Skeleton height={150} width={150} />
                <Skeleton height={150} width={150} />
                <Skeleton height={150} width={150} />
                <Skeleton height={150} width={150} />
              </div>
            </div>
          )}

          {!isLoading ? (
            <div
              className="overflow-scroll h100 "
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="h100"
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  width: "max-content",
                }}
              >
                {paginatedPartners.length === 0 && !isLoading ? (
                  <p>
                    {t("partners.noresults")} "{filter}"
                  </p>
                ) : null}

                {paginatedPartners.map((partner, key) => {
                  return (
                    <div
                      style={{
                        width: `${
                          isSmallScreen ? "50" : isMediumScreen ? "25" : "16.6"
                        }%`,
                      }}
                      className="d-flex justify-content-center"
                    >
                      <PartnerCard key={key} partner={partner} partnerPage />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className="height-100"></div>
        </div>
      </div>

      {/* <Pagination
        page={currentPage}
        setPage={handlePaginationClick}
        itemsPerPage={DEFAULT_PAGINATION_SIZE}
        totalItems={totalPartners}
      /> */}
    </section>
  );
}

export default function Partners() {
  const [mode, setMode] = useState<"map" | "list">("map");

  const { t } = useTranslation();

  return (
    <main id="web-page" className="position-relative overflow-hidden">
      <div className="overflow-hidden" style={{ height: "100dvh" }}>
        {mode === "list" ? <PartnerList /> : null}
        {mode === "map" ? <Map /> : null}
      </div>

      <div
        style={{ bottom: "25px", left: 0, zIndex: 500 }}
        className="w100 position-absolute d-flex align-items-center justify-content-center"
      >
        {mode === "map" ? (
          <Button
            onClick={() => setMode("list")}
            icon="list"
            className="shadow"
            text={t("partners.view_list")}
          />
        ) : null}
        {mode === "list" ? (
          <Button
            className="shadow"
            onClick={() => setMode("map")}
            icon="map"
            text={t("partners.view_map")}
          />
        ) : null}
      </div>
    </main>
  );
}
